import { Asset } from '@gain/rpc/app-model'
import { useSplitList, useVisibleListItems } from '@gain/utils/list'
import { useVisibleColumns } from '@gain/utils/table'
import React, { useEffect, useRef } from 'react'

import ButtonMore from '../../../../common/button-more'
import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import Table from '../../../../common/table'
import { useAssetCompetitors } from '../../use-asset-competitors'
import { assetCompetitorsColumns, columnVisiblitity } from './card-asset-competitors.hooks'

export interface CardAssetManagementProps extends CardProps {
  asset: Asset
  callback?: () => void
}

export default function CardAssetCompetitors({
  asset,
  callback,
  ...cardProps
}: CardAssetManagementProps) {
  const tableRef = useRef<HTMLDivElement>(null)

  const swrCompetitors = useAssetCompetitors(asset.competitors)

  const [initialAssets, additionalAssets] = useSplitList(swrCompetitors.data.items, 8)
  const [rows, showAll, handleShowAll] = useVisibleListItems(initialAssets, additionalAssets)

  // if competitor data is loaded or the more button is pressed a callback method called
  useEffect(() => {
    callback && callback()
  }, [callback, showAll, swrCompetitors.data.items])

  const visibleColumns = useVisibleColumns(tableRef, assetCompetitorsColumns, columnVisiblitity)

  return (
    <Card {...cardProps}>
      <CardHeader
        explainer={'This list has been hand-picked by our research team.'}
        title={'Curated competitor list'}
      />

      <Table
        ref={tableRef}
        columns={visibleColumns}
        rows={rows}
        disablePaddingBottomOnLastRow
      />

      {!showAll && (
        <ButtonMore
          amount={additionalAssets.length}
          onClick={handleShowAll}
        />
      )}
    </Card>
  )
}
