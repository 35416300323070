import { parseYearMonth } from '@gain/utils/date'
import compareDesc from 'date-fns/compareDesc'

import { Source } from './source-model'

export function compareSourceDateDesc(a: Source, b: Source) {
  // Keep sources without a date on top
  if (a.publicationYear === null && b.publicationYear !== null) {
    return -1
  } else if (b.publicationYear === null && a.publicationYear !== null) {
    return 1
  }

  return compareDesc(
    parseYearMonth(a.publicationYear, a.publicationMonth),
    parseYearMonth(b.publicationYear, b.publicationMonth)
  )
}
