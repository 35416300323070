import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import * as echarts from 'echarts/core'
import { ElementEvent } from 'echarts/types/dist/shared'
import { useCallback, useMemo, useRef, useState } from 'react'

import {
  EChartsMouseZrEventHandler,
  EChartsZrEventHandler,
} from '../../../../common/echart/use-e-charts-zr-event-handler'
import {
  BrokerRecommendation,
  DIMENSION_BUY,
  DIMENSION_HOLD,
  DIMENSION_SELL,
  DIMENSION_TOTAL,
  MarketDataChartData,
  SharePriceDatapoint,
} from './market-chart-types'

/**
 * useMarketDataInteraction adds the interaction logic to the market data chart.
 * It listens to mouse movements, determines which point in the share price and
 * broker recommendation charts should be highlighted, given the mouse position.
 *
 * Note that we use Zr mouse move listeners instead of ECharts' listeners. Even
 * though the axisPointer works well while moving the mouse over the chart and
 * snaps to the x axis, this behaviour does not apply to the mouse over events.
 * The ECharts mouse over events will only fire when your mouse hovers a symbol
 * or the line exactly. That's too limited for our use case. The Zr mouse move
 * triggers everywhere in the chart and the ECharts API allows to translate mouse
 * positions into coordinates in our grids.
 */
export default function useMarketDataInteraction(
  data: MarketDataChartData
): [
  highlightedSharePrice: SharePriceDatapoint | null,
  highlightedBrokerRecommendation: BrokerRecommendation | null,
  handleMouseMoveZr: EChartsZrEventHandler,
  handleMouseOutZr: EChartsMouseZrEventHandler
] {
  // Keep reference of the last broker recommendation index to prevent firing
  // too many highlight events
  const brokerRecommendationX = useRef<number | null>(null)

  // Keep track of which data points should be highlighted given the mouse position
  const [highlightedSharePrice, setHighlightedSharePrice] = useState<SharePriceDatapoint | null>(
    null
  )
  const [highlightedBrokerRecommendation, setHighlightedBrokerRecommendation] =
    useState<BrokerRecommendation | null>(null)

  // Build a lookup for each share price data point to the right broker index
  const brokerIndexLookup = useMemo(
    () =>
      data.sharePrice.map((marketValue, index) => {
        // For the last data point, always assign the last broker recommendation
        if (index === data.sharePrice.length - 1) {
          return data.brokerRecommendations.length - 1
        }

        // Find first index where the share price date is before the broker
        // recommendation's end date
        return data.brokerRecommendations.findIndex((brokerValue) =>
          isBefore(startOfDay(marketValue[0]), startOfDay(brokerValue[1]))
        )
      }),
    [data]
  )

  // Handle the mouse move event. It determines which value on the share price
  // series is currently hovered upon. Subsequently, it determines which broker
  // recommendation belongs to that data point and manages highlighting the
  // appropriate broker recommendation bar.
  const handleMouseMove = useCallback(
    (event: ElementEvent, eChartsInstance: echarts.ECharts) => {
      // Hard cursor override, to account for ECharts bug:
      //  - https://github.com/apache/echarts/issues/18024
      eChartsInstance.getZr().setCursorStyle('default')

      // Get the coordinate of the mouse pointer, find in which chart it is
      const coord = [event.offsetX, event.offsetY]
      const coordInSharePriceChart = eChartsInstance.containPixel(
        { gridId: ['share-price-grid'] },
        coord
      )
      const coordInAnyChart = eChartsInstance.containPixel(
        { gridId: ['share-price-grid', 'broker-recommendations-grid'] },
        coord
      )

      // Determine the X value on the share price chart
      const pointInGrid = eChartsInstance.convertFromPixel('grid', coord)
      const xValue = pointInGrid[0]

      // Set the highlighted share price if the mouse is in the share price chart
      setHighlightedSharePrice(
        coordInSharePriceChart
          ? {
              date: data.sharePrice[xValue][0],
              sharePrice: data.sharePrice[xValue][1] ?? null,
            }
          : null
      )

      // If not in any chart, undo all highlighting
      if (!coordInAnyChart || data.brokerRecommendations.length === 0) {
        setHighlightedBrokerRecommendation(null)
        eChartsInstance.dispatchAction({
          type: 'downplay',
          seriesId: ['buy', 'hold', 'sell'],
        })
        brokerRecommendationX.current = null
        return
      }

      // Coordinate is in either the share price or broker chart. Now, determine
      // which broker bar belongs to this X value, then first undo the highlight
      // (downplay), then highlight the correct one
      const brokerIndex = brokerIndexLookup[xValue]
      eChartsInstance.dispatchAction({
        type: 'downplay',
        seriesId: ['buy', 'hold', 'sell'],
      })
      eChartsInstance.dispatchAction({
        type: 'highlight',
        seriesId: ['buy', 'hold', 'sell', 'border-radius'],
        dataIndex: brokerIndex,
      })

      // Determine the X coordinates of the highlighted broker recommendation
      // bar, which is the difference in business days between the start date
      // of this broker recommendation and the first date of the share price
      const startX = differenceInBusinessDays(
        data.brokerRecommendations[brokerIndex][0],
        data.sharePrice[0][0]
      )
      const endX = differenceInBusinessDays(
        data.brokerRecommendations[brokerIndex][1],
        data.sharePrice[0][0]
      )
      const startXBrokerCoord = eChartsInstance.convertToPixel(
        { xAxisId: 'broker-recommendations-x-axis' },
        startX
      )
      const endXBrokerCoord = eChartsInstance.convertToPixel(
        { xAxisId: 'broker-recommendations-x-axis' },
        endX
      )

      // The highlighted broker recommendation should only be updated when the
      // mouse hovers over a different bar. React's default re-render logic uses
      // `Object.is` to determine whether a `setState()` call should cause a
      // re-render, which is not enough in our case here.
      if (brokerRecommendationX.current !== startXBrokerCoord) {
        setHighlightedBrokerRecommendation({
          startX: startXBrokerCoord ?? 0,
          endX: endXBrokerCoord ?? 0,
          buy: data.brokerRecommendations[brokerIndex][DIMENSION_BUY],
          hold: data.brokerRecommendations[brokerIndex][DIMENSION_HOLD],
          sell: data.brokerRecommendations[brokerIndex][DIMENSION_SELL],
          total: data.brokerRecommendations[brokerIndex][DIMENSION_TOTAL],
        })

        // Update our reference
        brokerRecommendationX.current = startXBrokerCoord
      }
    },
    [brokerIndexLookup, data.brokerRecommendations, data.sharePrice]
  )

  // Make sure to hide all interaction when we move out of the chart completely
  const handleMouseOut = useCallback((event: MouseEvent, eChartsInstance: echarts.ECharts) => {
    // Undo highlighting of the broker chart
    brokerRecommendationX.current = null
    eChartsInstance.dispatchAction({
      type: 'downplay',
      seriesId: ['buy', 'hold', 'sell'],
    })

    // Hide both tooltips
    setHighlightedBrokerRecommendation(null)
    setHighlightedSharePrice(null)
  }, [])

  return [highlightedSharePrice, highlightedBrokerRecommendation, handleMouseMove, handleMouseOut]
}
