import { LinkedInIcon } from '@gain/components/icons'
import OverflowContainer from '@gain/components/overflow-container'
import { PersonListItem } from '@gain/rpc/app-model'
import { fixUrl } from '@gain/utils/common'
import { useSplitList } from '@gain/utils/list'
import IconButton from '@mui/material/IconButton'
import React, { useMemo } from 'react'

import ButtonMore from '../../../../common/button-more'
import Card, { CardContentEmpty, CardHeader } from '../../../../common/card/card'
import Table, { createTableColumns } from '../../../../common/table'
import AssetContentLink from '../../../../features/asset/asset-content-link'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_REPRESENTATIVES_PATH,
  useInvestorPageParams,
} from '../../../utils'

const columns = createTableColumns<PersonListItem>(
  {
    field: 'fullName',
    headerName: 'Name',
  },
  {
    field: 'assets',
    headerName: 'Portfolio',
    width: 130,
    renderCell: ({ row }) => (
      <OverflowContainer
        lineHeight={24}
        maxLines={1}
        spacing={1}>
        {row.assets.map((asset) => (
          <AssetContentLink
            key={asset.id}
            id={asset.id}
            logoFileUrl={asset.logoFileUrl}
            name={asset.name}
            variant={'logo'}
          />
        ))}
      </OverflowContainer>
    ),
  },
  {
    field: 'linkedInUrl',
    headerName: '',
    width: 54,
    renderCell: ({ value }) =>
      value && (
        <IconButton
          href={fixUrl(value)}
          rel={'noopener noreferrer'}
          size={'small'}
          sx={{ fontSize: 16 }}
          target={'_blank'}>
          <LinkedInIcon fontSize={'inherit'} />
        </IconButton>
      ),
  }
)

export interface InvestorRepresentativesCardProps {
  representatives: PersonListItem[]
}

export default function InvestorRepresentativesCard({
  representatives,
}: InvestorRepresentativesCardProps) {
  const rows = useMemo(() => {
    return representatives.slice().sort((a, b) => {
      if (a.assets.length !== b.assets.length) {
        return b.assets.length - a.assets.length
      }

      return a.fullName.localeCompare(b.fullName)
    })
  }, [representatives])
  const params = useInvestorPageParams()
  const [initial, rest] = useSplitList(rows, 4)

  return (
    <Card
      href={
        initial.length > 0 && generateInvestorPagePath(params, INVESTOR_PAGE_REPRESENTATIVES_PATH)
      }
      fullHeight>
      <CardHeader title={'Representatives'} />
      {initial.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <Table
          columns={columns}
          rows={initial}
          dense
          disablePaddingBottomOnLastRow
        />
      )}

      <ButtonMore
        amount={rest.length}
        href={generateInvestorPagePath(params, INVESTOR_PAGE_REPRESENTATIVES_PATH)}
      />
    </Card>
  )
}
