import { AssetListItem } from '@gain/rpc/app-model'
import isAfter from 'date-fns/isAfter'
import parseJSON from 'date-fns/parseJSON'
import { Location } from 'history'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

export const LAST_VIEWED_AT_QUERY_PARAM_KEY = 'lastViewedAt'

/**
 * Returns a callback used to determine whether an asset should show an
 * updates badge using the `?lastViewedAt=...` query param.
 *
 * Removes the `lastViewedAt` query param if the url changes.
 */
export default function useLastViewedAtQueryParamUpdates() {
  const location = useLocation<{ from?: Location }>()
  const history = useHistory()

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  // If the url contains `?lastViewedAt=<datetime>`, highlight
  // any assets that have financials beyond that timestamp.
  const lastViewedAtParam = searchParams.get(LAST_VIEWED_AT_QUERY_PARAM_KEY)
  const prevLastViewedAtParam = usePrevious(lastViewedAtParam)

  // Whenever the url changes, remove the 'lastViewedAt' search parameter
  // if it was not just recently set.
  useEffect(() => {
    if (lastViewedAtParam && Boolean(prevLastViewedAtParam)) {
      searchParams.delete(LAST_VIEWED_AT_QUERY_PARAM_KEY)
      history.replace({
        search: searchParams.toString(),
      })
    }
  }, [history, lastViewedAtParam, prevLastViewedAtParam, searchParams])

  // This callback is run for each asset item in the table and
  // returns true if there are new financials.
  const hasUpdates = useCallback(
    (asset: AssetListItem) => {
      if (!lastViewedAtParam) {
        return false
      }

      const lastViewedAtDate = parseJSON(lastViewedAtParam)
      if (asset.financialsAt === null || isNaN(lastViewedAtDate.getTime())) {
        return false
      }

      return isAfter(parseJSON(asset.financialsAt), lastViewedAtDate)
    },
    [lastViewedAtParam]
  )

  return hasUpdates
}
