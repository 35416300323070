import { Asset, GlobalUltimateOwner } from '@gain/rpc/app-model'
import { formatAssetManager, useAssetManagingCeo } from '@gain/utils/asset'
import { formatMonthYear } from '@gain/utils/date'
import { formatManagerPosition } from '@gain/utils/manager'
import React from 'react'

import { KeyValueList, KeyValueListItem } from '../../../../common/key-value/key-value-list'
import AssetOwnershipTypeExplainer from '../../../../features/asset/asset-ownership'
import AssetOwnershipUnverifiedChip from '../../../../features/asset/asset-ownership/asset-ownership-unverified-chip'
import { getSubsidiaryParentId } from '../../asset-header/asset-header-subtitle'
import SubsidiaryParentAlert from './subsidiary-parent-alert'
import UltimateOwner from './ultimate-owner'

export interface AssetOwnershipDefinitionProps {
  asset: Asset
  globalUltimateOwner?: GlobalUltimateOwner
}

export default function AssetOwnershipDefinition({
  asset,
  globalUltimateOwner,
}: AssetOwnershipDefinitionProps) {
  const ceo = useAssetManagingCeo(asset.managers)
  const parentId = getSubsidiaryParentId(asset.subsidiaryPath)

  const isVerified = asset.generalInfo?.ownershipIsVerified
  const ownershipType = asset.generalInfo?.ownership

  return (
    <KeyValueList>
      {parentId !== null && <SubsidiaryParentAlert parentId={parentId} />}

      <KeyValueListItem
        label={
          <>
            Type
            {!isVerified && ownershipType && (
              <AssetOwnershipUnverifiedChip ownershipType={ownershipType} />
            )}
          </>
        }
        value={
          <AssetOwnershipTypeExplainer
            disableExplainer={!isVerified}
            ownershipType={ownershipType}
          />
        }
      />

      <KeyValueListItem
        label={'Last platform deal'}
        value={formatMonthYear(asset.generalInfo?.lastDealMonth, asset.generalInfo?.lastDealYear)}
      />

      {globalUltimateOwner && (
        <KeyValueListItem
          label={'Ultimate owner'}
          value={<UltimateOwner owner={globalUltimateOwner} />}
          valueTypographyProps={{
            minHeight: 26,
            mb: '-1px',
          }}
        />
      )}

      {ceo && (
        <KeyValueListItem
          label={formatManagerPosition(ceo?.position)}
          value={formatAssetManager(ceo)}
        />
      )}
    </KeyValueList>
  )
}
