import { RefObject, useCallback, useLayoutEffect } from 'react'

import { useResizeObserver } from './use-resize-observer.hook'

export function useElementWidthEffect<Element extends HTMLElement>(
  ref: RefObject<Element | null>,
  callback: (newWidth: number) => void
) {
  const handleWidthChange = useCallback(() => {
    const currentWidth = ref.current?.getBoundingClientRect().width || 0
    callback(currentWidth)
  }, [callback, ref])

  // Use layout effect synchronously measures the width of the element.
  // This prevents a flicker when the width is measured after the first render.
  useLayoutEffect(() => {
    if (ref.current) {
      handleWidthChange()
    }
  }, [handleWidthChange, ref])

  useResizeObserver(ref, handleWidthChange)
}
