import { useResizeObserver } from '@gain/utils/dom'
import { RefObject, useCallback, useState } from 'react'

import { useMenuContext } from './menu-provider'

export default function useHasMenuOverflow<T extends HTMLElement>(ref: RefObject<T>) {
  const [isOverflow, setIsOverflow] = useState(false)
  const [showMenu] = useMenuContext()

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    const hasOverflow = ref.current.scrollHeight > ref.current.clientHeight
    setIsOverflow(hasOverflow && showMenu)
  }, [ref, showMenu])

  useResizeObserver(ref, handleResize)

  return isOverflow
}
