import { useUserProfileIgnoreAuthError } from '@gain/modules/user'
import { isExp } from '@gain/utils/environment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ZendeskApi } from './zendesk-context'
import { ZendeskSettings } from './zendesk-model'
import { zendeskCommand } from './zendesk-util'

declare global {
  interface Window {
    zESettings?: ZendeskSettings
  }
}

const defaultSettings: ZendeskSettings = {
  webWidget: {
    chat: {
      suppress: false,
    },
    contactForm: {
      suppress: false,
    },
    helpCenter: {
      suppress: false,
    },
    talk: {
      suppress: false,
    },
    answerBot: {
      suppress: false,
    },
  },
}

enum ZendeskKey {
  Default = '4595d1b7-685c-4784-84de-a64b70fbf053',
  Test = '99b9d077-9a01-4194-b81f-ec55fefd229d',
}

function useZendeskKey() {
  return useMemo(() => {
    if (isExp()) {
      return ZendeskKey.Test
    }

    return ZendeskKey.Default
  }, [])
}

export async function initializeZendesk(key: ZendeskKey) {
  return new Promise((resolve) => {
    window.zESettings = defaultSettings

    // Prevent third party script loading from going into browser extension source code
    if (process.env['NX_PUBLIC_TASK_TARGET'] !== 'browser-extension') {
      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
      script.async = true
      script.onload = () => {
        resolve(void 0)
      }

      document.body.appendChild(script)
    }
  })
}

export const useZendeskApi = (): ZendeskApi => {
  const userProfile = useUserProfileIgnoreAuthError()
  const [isLoading, setIsLoading] = useState(false)
  const isInitializedRef = useRef(false)
  const key = useZendeskKey()

  // Disable login on exp because we use a test chat bot there that doesn't support login
  const loginDisabled = useMemo(() => key === ZendeskKey.Test, [key])

  // Keep track of commands that need to be executed after the Zendesk script has been initialized
  const initialCommandsRef = useRef<Array<() => Promise<void>>>([])

  // Loads the Zendesk script and executes commands scheduled for after initialization
  const initialize = useCallback(async () => {
    if (isInitializedRef.current) {
      return Promise.resolve()
    }

    setIsLoading(true)
    await initializeZendesk(key)
    for (const command of initialCommandsRef.current) {
      await command()
    }
    isInitializedRef.current = true
    setIsLoading(false)
  }, [key])

  const scheduleCommand = useCallback((command: () => Promise<void>) => {
    // When not initialized, schedule command to be executed after initialization
    if (!isInitializedRef.current) {
      initialCommandsRef.current.push(() => command())
      return
    }

    // Otherwise execute the command immediately
    return command()
  }, [])

  const enableChat = useCallback(async () => {
    await initialize()

    zendeskCommand('messenger', 'open')
  }, [initialize])

  const login = useCallback(
    (token: string) => {
      if (loginDisabled) {
        return
      }
      scheduleCommand(() => {
        return new Promise((resolve) => {
          zendeskCommand('messenger', 'loginUser', (callback: (t: string) => void) => {
            callback(token)
            resolve()
          })
        })
      })
    },
    [scheduleCommand, loginDisabled]
  )

  const logout = useCallback(() => {
    scheduleCommand(() => {
      zendeskCommand('messenger', 'logoutUser')
      return Promise.resolve()
    })
  }, [scheduleCommand])

  // Effect to login if we have a Zendesk JWT or logout if we don't
  useEffect(() => {
    if (userProfile.loading) {
      return
    }

    if (userProfile.data?.zendeskJwt) {
      login(userProfile.data.zendeskJwt)
    } else {
      logout()
    }
  }, [login, logout, userProfile.data?.zendeskJwt, userProfile.loading])

  return {
    isLoading,
    enableChat,
  }
}
