import { AssetListItem } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { compareNumberDesc } from '@gain/utils/common'
import { formatCountry } from '@gain/utils/countries'
import { formatSubsector } from '@gain/utils/sector'

import { ChartGroup, ChartGroupByConfig } from '../../../common/chart/chart-groups'
import { formatAssetOwnershipType } from '../../asset/asset-ownership'

export enum AssetChartGroupType {
  Ownership = 'ownership',
  Sector = 'sector',
  Region = 'region',
}

function totalRevenue(assets: AssetListItem[]) {
  return assets.reduce((acc, asset) => (asset.revenueEur || 0) + acc, 0)
}

export function compareByRevenueDesc(a: ChartGroup<AssetListItem>, b: ChartGroup<AssetListItem>) {
  return compareNumberDesc(totalRevenue(a.items), totalRevenue(b.items))
}

export const ASSET_CHART_GROUPS = [
  {
    id: AssetChartGroupType.Ownership,
    label: 'Ownership',
    getValue: (asset) => asset.ownership,
    getLabel: (value) => formatAssetOwnershipType(value),
    compare: compareByRevenueDesc,
  } as ChartGroupByConfig<AssetListItem, AssetOwnershipType | null>,
  {
    id: AssetChartGroupType.Sector,
    label: 'Sector',
    getValue: (asset) => asset.subsector || '',
    getLabel: (value) => (value && formatSubsector(value)) || 'Unknown',
    compare: compareByRevenueDesc,
  } as ChartGroupByConfig<AssetListItem, string>,
  {
    id: AssetChartGroupType.Region,
    label: 'Region',
    getValue: (asset) => asset.region || '',
    getLabel: (value) => (value ? formatCountry(value) : 'Unknown'),
    compare: compareByRevenueDesc,
  } as ChartGroupByConfig<AssetListItem, string>,
] as ChartGroupByConfig<AssetListItem>[]
