import { AssetListItem, Investor } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listFilters } from '@gain/rpc/utils'

// Only show curated companies in portfolio on the app
// Hide automated companies linked to the investor through dealroom deal import
export function createInvestorPortfolioFilters(investor: Investor, assetIds?: number[]) {
  return listFilters<AssetListItem>(
    listFilter('ownerIds', '=', investor.id),
    assetIds && assetIds.length !== 0 && listFilter('id', '=', assetIds),

    // Only show curated companies in portfolio on the app,
    // hide automated companies linked to the investor through
    // dealroom deal
    investor.onlyIncludeCurated && listFilter('profileType', '!=', AssetProfileType.Automated)
  )
}
