import { GlobeIcon, HashIcon, InfoIcon, TagOutlinedIcon } from '@gain/components/icons'
import { SearchResultItem as SearchResultItemType } from '@gain/rpc/app-model'
import Chip, { chipClasses } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'

const StyledChip = styled(Chip)(({ theme }) => ({
  // Makes sure the link below it will be clicked
  pointerEvents: 'none',
  color: theme.palette.text.primary,

  [`& .${chipClasses.icon}`]: {
    color: theme.palette.grey[600],
  },
}))

type MatchField =
  | 'title'
  | 'name'
  | 'short_name'
  | 'aliases'
  | 'previous_names'
  | 'external_id'
  | 'description'
  | 'tags'
  | 'web_url'

export function useCorrectMatchReason(item?: SearchResultItemType | null) {
  return useMemo(() => {
    if (!item) {
      return
    }

    let MatchIcon
    let matchTermPrefix = ''
    switch (item.matchedField as MatchField) {
      case 'aliases':
        MatchIcon = InfoIcon
        matchTermPrefix = 'alias: '
        break

      case 'tags':
        MatchIcon = TagOutlinedIcon
        break

      case 'web_url':
        MatchIcon = GlobeIcon
        break

      case 'external_id':
        MatchIcon = HashIcon
        matchTermPrefix = 'entity nr: '
        break
    }

    if (MatchIcon) {
      return (
        <StyledChip
          icon={<MatchIcon />}
          label={`${matchTermPrefix}${item.matchedTerm}`}
          size={'medium'}
          variant={'outlined'}
        />
      )
    }

    return null
  }, [item])
}
