import { ArrowUpIcon } from '@gain/components/icons'
import { formatPercentage } from '@gain/utils/number'
import { styled } from '@mui/material/styles'

const StyledGrowthIndicatorContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'body2' | 'overline' | 'subtitle2' }>(({ theme, variant }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.text.primary,
  ...theme.typography[variant],

  '& svg': {
    fontSize: 16,

    ...(variant === 'overline' && {
      fontSize: 14,
    }),
  },
}))

const StyledArrowIcon = styled(ArrowUpIcon, {
  shouldForwardProp: (prop) => prop !== 'direction',
})<{ direction: 'up' | 'down' }>(({ direction, theme }) => ({
  color: theme.palette.success.main,

  ...(direction === 'down' && {
    transform: 'rotate(180deg)',
    color: theme.palette.error.main,
  }),
}))

export interface GrowthIndicatorProps {
  growthPct: number
  iconPosition?: 'start' | 'end'
  variant?: 'body2' | 'overline'
}

export function GrowthIndicator({
  growthPct,
  iconPosition = 'end',
  variant = 'body2',
}: GrowthIndicatorProps) {
  return (
    <StyledGrowthIndicatorContainer variant={variant}>
      {iconPosition === 'start' && growthPct !== 0 && (
        <StyledArrowIcon direction={growthPct > 0 ? 'up' : 'down'} />
      )}
      {formatPercentage(growthPct, { round: variant === 'overline' ? 0 : 'auto' })}
      {iconPosition === 'end' && growthPct !== 0 && (
        <StyledArrowIcon direction={growthPct > 0 ? 'up' : 'down'} />
      )}
    </StyledGrowthIndicatorContainer>
  )
}
