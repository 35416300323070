import { isTruthy } from '@gain/utils/common'
import { formatManagerPosition } from '@gain/utils/manager'

import InvestorContentLink from '../../../../features/investor/investor-content-link'
import { Manager } from './asset-management-util'

interface TableCellManagerRoleInvestorTable {
  manager: Manager
}

export default function TableCellManagerRoleInvestor({
  manager,
}: TableCellManagerRoleInvestorTable) {
  if (manager.investor) {
    return (
      <InvestorContentLink
        id={manager.investor.id}
        logoFileUrl={manager.investor.logoFileUrl}
        name={manager.investor.name}
      />
    )
  } else if (manager.position) {
    const formattedPosition = [
      formatManagerPosition(manager.position, { disableEnumCheck: true }),
      manager.joinYear,
    ]
      .filter(isTruthy)
      .join(' since ')

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{formattedPosition}</>
  }

  return <>-</>
}
