import { ChevronDownIcon } from '@gain/components/icons'
import { inputBaseClasses } from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import { useCallback, useMemo } from 'react'

const StyledRoot = styled('div')(({ theme }) => ({
  flexWrap: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
}))

const StyledLabel = styled('label')(({ theme }) => ({
  ...theme.typography.button,
  color: theme.palette.text.secondary,
  whiteSpace: 'nowrap',
}))

const StyledChevronDown = styled(ChevronDownIcon)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
  fontSize: 16,
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.button,
    padding: 0,
    color: theme.palette.primary.main,
    paddingRight: '20px !important',
  },
  [`&.${inputBaseClasses.focused}`]: {
    [`& .${inputBaseClasses.input}`]: {
      backgroundColor: 'transparent',
    },
  },
  [`& .${selectClasses.select}`]: {
    minHeight: 20,
    maxHeight: 20,
  },
}))

export interface ChartSelectProps<Data> {
  label: string
  options: Data[]
  value: Data
  onChange: (value: Data) => void
  getLabel: (option: Data) => string
}

export default function ChartSelect<Data>({
  label,
  options,
  value,
  onChange,
  getLabel,
}: ChartSelectProps<Data>) {
  const selectedIndex = useMemo(
    () => options.findIndex((option) => getLabel(option) === getLabel(value)),
    [getLabel, options, value]
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      onChange(options[parseInt(event.target.value as string)])
    },
    [onChange, options]
  )

  if (selectedIndex === -1) {
    return null
  }

  return (
    <StyledRoot>
      <StyledLabel>{label}:</StyledLabel>
      <StyledSelect
        IconComponent={StyledChevronDown}
        onChange={handleChange}
        size={'small'}
        value={selectedIndex.toString(10)}
        variant={'standard'}
        disableUnderline>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={index.toString(10)}>
            {getLabel(option)}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledRoot>
  )
}
