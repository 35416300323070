import {
  BarChart,
  BarSeriesOption,
  CustomChart,
  CustomSeriesOption,
  LineChart,
  LineSeriesOption,
  ScatterChart,
  ScatterSeriesOption,
} from 'echarts/charts'
import type {
  DatasetComponentOption,
  GridComponentOption,
  TitleComponentOption,
} from 'echarts/components'
import {
  DatasetComponent,
  GridComponent,
  MarkPointComponent,
  TitleComponent,
  TransformComponent,
} from 'echarts/components'
import type { ComposeOption } from 'echarts/core'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { CSSProperties, useEffect, useRef } from 'react'

import {
  EChartsEventHandler,
  EChartsMouseZrEventHandler,
  EChartsZrEventHandler,
  useEChartsEventHandler,
  useEChartsMouseEventHandler,
  useEChartsZrEventHandler,
} from './use-e-charts-zr-event-handler'
import useEChartsTheme from './use-echarts-theme'

// ECOption is the data type that ECharts accepts for building a chart. Please
// extend this type as you need more of the ECharts library, e.g., when you want
// to add support for a bar chart.
export type ECOption = ComposeOption<
  | CustomSeriesOption
  | BarSeriesOption
  | LineSeriesOption
  | ScatterSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | DatasetComponentOption
>

// Register all ECharts components that we use. Please extend this if you need
// more of the ECharts library, e.g. when you want to add bar chart support.
echarts.use([
  TitleComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  CustomChart,
  LineChart,
  BarChart,
  ScatterChart,
  CanvasRenderer,
  MarkPointComponent,
])

interface EChartProps {
  option: ECOption
  style?: CSSProperties
  className?: string
  onInit?: (eChartsInstance: echarts.ECharts) => void
  onClick?: EChartsEventHandler
  onMouseMove?: EChartsEventHandler
  onMouseOut?: EChartsEventHandler
  onMouseMoveZr?: EChartsZrEventHandler
  onMouseOutZr?: EChartsMouseZrEventHandler
  clearOnChange?: boolean
}

/**
 * EChart is a React wrapper for ECharts. By providing `option`, you can define
 * what charts should be rendered. If you want to interact with the ECharts API
 * directly, use `onInit()` to get hold of the instance. This callback will fire
 * each time a new ECharts instance is initialized (roughly on render). In the
 * event handlers like `onMouseMove`, you get the most recent ECharts instance
 * passed in as argument.
 *
 * Note: To prevent issues wrt re-rendering, make sure to wrap your `option` in
 * `useMemo` and only re-render it when it makes sense for your use case.
 *
 * Note: Your callbacks (`onInit`, `onMouseMove`, etc.) should be wrapped in a
 * `useCallback` to prevent unexpected behaviour / re-renders.
 *
 * Note: Use `clearOnChange` to prevent issues with multiple series being
 *  rendered at the same time. This should only be needed when the number of
 *  series change within the same ECharts instance. (see:
 *  https://github.com/apache/echarts/issues/6202)
 */
export default function EChart({
  option,
  style,
  className,
  onInit,
  onClick,
  onMouseMove,
  onMouseOut,
  onMouseMoveZr,
  onMouseOutZr,
  clearOnChange = false,
}: EChartProps) {
  const theme = useEChartsTheme()

  // Keep a ref to the HTML div that ECharts attaches to, and the ECharts instance
  const eChartsDivRef = useRef<HTMLDivElement>(null)
  const eChartsInstanceRef = useRef<echarts.ECharts | null>(null)

  // Initialize ECharts instance on mount
  useEffect(() => {
    // Create a new ECharts instance
    const eChartsInstance = echarts.init(eChartsDivRef.current, theme)
    if (onInit) {
      onInit(eChartsInstance)
    }

    // Keep a reference of this instance
    eChartsInstanceRef.current = eChartsInstance

    // Make sure to dispose the instance completely
    return () => {
      if (eChartsInstanceRef.current != null) {
        eChartsInstanceRef.current.dispose()
      }
    }
  }, [theme, onInit])

  // Update ECharts whenever the provided ECharts option changes
  useEffect(() => {
    if (eChartsInstanceRef.current != null) {
      if (clearOnChange) {
        eChartsInstanceRef.current.clear()
      }

      eChartsInstanceRef.current.setOption(option)
    }
  }, [option, clearOnChange])

  // Register ECharts specific event handlers
  useEChartsEventHandler('click', eChartsInstanceRef, onClick)
  useEChartsEventHandler('mousemove', eChartsInstanceRef, onMouseMove)
  useEChartsEventHandler('mouseout', eChartsInstanceRef, onMouseOut)
  useEChartsZrEventHandler('mousemove', eChartsInstanceRef, onMouseMoveZr)
  // ... add more `useEChartsEventHandler` calls when more events are needed

  // Register DOM event handlers, unfortunately we can't rely on the echarts mouse
  // out event, as this fires too often and there's no way to distinguish between
  // a mouseout event on an element in the chart (e.g. area under the line) or
  // a mouseout event on the whole chart.
  useEChartsMouseEventHandler('mouseout', eChartsDivRef, eChartsInstanceRef, onMouseOutZr)

  return (
    <div
      ref={eChartsDivRef}
      className={className}
      style={style}
    />
  )
}
