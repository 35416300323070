import { useUpdatePassword } from '@gain/api/app/hooks'
import { useUserProfileContext } from '@gain/modules/user'
import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { FORM_ERROR, FormApi, SubmissionErrors } from 'final-form'
import React, { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'

import { useAlertDialog } from '../../../common/dialog'
import { isRequired, passwordMustBeTheSame } from '../../../common/form/form-utils'
import { formatFieldError, showFieldError } from '../../../common/form/print-field-error'

export default function UpdatePasswordForm() {
  const showAlertDialog = useAlertDialog()
  const { email } = useUserProfileContext()

  const [mode, setMode] = useState<'edit' | 'view'>('view')
  const updatePassword = useUpdatePassword()

  const handleUpdatePassword = useCallback(
    async (body, form: FormApi, callback?: (errors?: SubmissionErrors) => void) => {
      try {
        await updatePassword(body)
        setMode('view')
        showAlertDialog({
          buttonText: 'Ok',
          message:
            'Your password has been changed. Use your new password the next time you sign in.',
          title: 'Password changed',
        })
        callback?.()
        form.reset()
      } catch (error) {
        if (isJsonRpcError(error)) {
          if (error.code === RpcErrorCode.ValidationFailed) {
            callback?.({ [FORM_ERROR]: error.data[0]?.message || error.message })
          } else {
            callback?.({ [FORM_ERROR]: error.message })
          }
        } else {
          callback?.({ [FORM_ERROR]: 'An unexpected error occurred, please try again.' })
        }
      }
    },
    [showAlertDialog, updatePassword]
  )

  return (
    <Paper variant={'outlined'}>
      <Box padding={2}>
        <Form
          onSubmit={({ newPassword, oldPassword }, form, callback) => {
            handleUpdatePassword({ newPassword, oldPassword }, form, callback)
          }}
          validate={(values) => passwordMustBeTheSame({ password: values.newPassword, ...values })}>
          {({ submitError, handleSubmit, submitting }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: 'inline' }}>
              <input
                autoComplete={'username'}
                style={{ display: 'none' }}
                type={'text'}
                value={email}
                readOnly
              />
              <Grid
                direction={'column'}
                spacing={2}
                container>
                <Grid
                  alignItems={'center'}
                  direction={'row'}
                  justifyContent={'space-between'}
                  spacing={2}
                  container
                  item>
                  <Grid item>
                    <Typography variant={'subtitle1'}>Password</Typography>
                  </Grid>
                  <Grid item>
                    {mode === 'view' && (
                      <Button
                        color={'primary'}
                        onClick={() => setMode('edit')}
                        type={'button'}
                        variant={'contained'}>
                        Edit
                      </Button>
                    )}
                    {mode === 'edit' && (
                      <Button
                        color={'primary'}
                        disabled={submitting}
                        startIcon={submitting && <CircularProgress size={16} />}
                        type={'submit'}
                        variant={'contained'}>
                        Save
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {mode === 'edit' && (
                  <>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Field
                        name={'oldPassword'}
                        type={'password'}
                        validate={isRequired}>
                        {({ input, meta }) => (
                          <TextField
                            autoComplete={'current-password'}
                            error={showFieldError(meta)}
                            helperText={formatFieldError(meta)}
                            inputProps={input}
                            label={'Current password'}
                            placeholder={'Current password'}
                            variant={'outlined'}
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid
                      direction={'column'}
                      spacing={1}
                      container
                      item>
                      <Grid item>
                        <Field
                          name={'newPassword'}
                          type={'password'}
                          validate={isRequired}>
                          {({ input, meta }) => (
                            <TextField
                              autoComplete={'new-password'}
                              error={showFieldError(meta)}
                              helperText={formatFieldError(meta)}
                              inputProps={input}
                              label={'New password'}
                              placeholder={'New password'}
                              variant={'outlined'}
                              fullWidth
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <Field
                          name={'repeatPassword'}
                          type={'password'}
                          validate={isRequired}>
                          {({ input, meta }) => (
                            <TextField
                              autoComplete={'repeat-password'}
                              error={showFieldError(meta)}
                              helperText={formatFieldError(meta)}
                              inputProps={input}
                              placeholder={'Repeat new password'}
                              variant={'outlined'}
                              fullWidth
                            />
                          )}
                        </Field>
                      </Grid>
                      {submitError && (
                        <Grid item>
                          <Alert severity={'error'}>{submitError}</Alert>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    </Paper>
  )
}
