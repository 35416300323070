import { BellIcon } from '@gain/components/icons'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import React from 'react'

import { BEAMER_BUTTON_ID, useBeamerCount } from '../../features/beamer/beamer-provider'
import HelpMenu from './help-menu'
import MenuListItem from './menu-list-item'
import { MenuListItemSecondaryActionBadge } from './menu-list-item-secondary-action-badge'
import { useMenuContext } from './menu-provider'

export function BottomMenu() {
  const beamerCount = useBeamerCount()
  const [showMenu] = useMenuContext()

  return (
    <div>
      <Divider sx={{ mb: 1.5 }} />
      <List
        sx={{ overflow: 'auto', pb: 1.5, px: 2 }}
        dense
        disablePadding>
        <HelpMenu />

        <MenuListItem
          classNameButton={BEAMER_BUTTON_ID}
          icon={<BellIcon fontSize={'inherit'} />}
          iconBadge={!showMenu && beamerCount}
          label={'New on Gain.pro'}
          secondaryAction={
            showMenu && (
              <MenuListItemSecondaryActionBadge
                badgeContent={beamerCount}
                color={'error'}
              />
            )
          }
        />
      </List>
    </div>
  )
}
