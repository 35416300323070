import { UIEvent, useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useVirtualScrollPositionApi = (api) => {
  const lastScrollPositionRef = useRef(0)
  const { key } = useLocation()

  const storageKey = useMemo(() => `${key}-scroll-position`, [key])

  const handleScroll = useCallback((event: UIEvent<HTMLDivElement>) => {
    lastScrollPositionRef.current = event?.currentTarget?.scrollTop ?? lastScrollPositionRef.current
  }, [])

  const handleResetScroll = useCallback(() => {
    sessionStorage.removeItem(storageKey)

    if (api.containerRef.current && lastScrollPositionRef.current !== 0) {
      api.containerRef.current.scroll({ top: 0 })
    }
  }, [storageKey])

  useEffect(() => {
    const lastKnownScrollPosition = sessionStorage.getItem(storageKey)

    // Set back the last know scroll position for this route
    if (lastKnownScrollPosition && api.containerRef.current) {
      api.containerRef.current?.scroll({
        top: parseInt(lastKnownScrollPosition, 10),
        behavior: 'smooth',
      })
    }

    return () => {
      // Store the last scroll position
      sessionStorage.setItem(storageKey, `${lastScrollPositionRef.current}`)
    }
  }, [api.containerRef, storageKey])

  return {
    onScroll: handleScroll,
    resetScroll: handleResetScroll,
  }
}
