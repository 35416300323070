import {
  ListedSecurityBrokerRecommendation,
  ListedSecurityChartData,
  ListedSecurityPrice,
} from '@gain/rpc/app-model'
import { useConvertCurrencyCallback } from '@gain/utils/currency'
import { formatDate } from '@gain/utils/date'
import addBusinessDays from 'date-fns/addBusinessDays'
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'
import getDate from 'date-fns/getDate'
import getMonth from 'date-fns/getMonth'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth'
import isFuture from 'date-fns/isFuture'
import isMonday from 'date-fns/isMonday'
import isToday from 'date-fns/isToday'
import isWeekend from 'date-fns/isWeekend'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'
import { CustomSeriesRenderItemAPI, CustomSeriesRenderItemReturn } from 'echarts/types/dist/shared'
import { useMemo } from 'react'

import { MarketDataChartData, PriceSeriesData } from './market-chart-types'

const DIMMED_CHART_OPACITY = 0.2
const Y_AXIS_LABEL_CHARACTER_WIDTH = 10.5

export function isFirstBusinessDayOfTheMonth(date: number | Date): boolean {
  return (
    // It's the first day of the month and not weekend
    (isFirstDayOfMonth(date) && !isWeekend(date)) ||
    // It's the first monday after the weekend where the month changed
    (isMonday(date) && [2, 3].includes(getDate(date)))
  )
}

/**
 * useConvertMarketData takes market data and makes sure the prices are correctly
 * converted into the desired currency.
 */
export function useConvertSharePrices(
  data: MarketDataChartData,
  listedSecurityCurrency: string,
  toCurrency: string
): MarketDataChartData {
  const convertCurrency = useConvertCurrencyCallback()

  return useMemo(() => {
    return {
      ...data,
      sharePrice: data.sharePrice.map(([date, value]) => {
        let newValue: number | null = null
        if (value) {
          newValue = convertCurrency(value, listedSecurityCurrency, toCurrency)
        }

        return [date, newValue]
      }),
    }
  }, [data, listedSecurityCurrency, toCurrency, convertCurrency])
}

/**
 * prepareChartData takes the backend market data and prepares it to be ready for
 * display in the chart. It makes sure that all missing data points are handled
 * and the broker recommendations are converted to percentages and align with the
 * share price.
 */
export function usePrepareChartData(data?: ListedSecurityChartData): MarketDataChartData {
  return useMemo(() => {
    // Bail out if we don't have any data
    if (!data || data.sharePrice.length === 0) {
      return {
        sharePrice: [],
        brokerRecommendations: [],
      }
    }

    // Prepare data for the chart
    const sharePrice = injectMissingDataPoints(data.sharePrice)
    const brokerRecommendations = prepareBrokerRecommendations(
      data.brokerRecommendation,
      sharePrice[0][0],
      sharePrice[sharePrice.length - 1][0]
    )

    return { sharePrice, brokerRecommendations }
  }, [data])
}

/**
 * injectMissingDataPoints takes a multidimensional number array with ECharts
 * series data, walks over all data points and injects `null` values if business
 * days are missing. The first index of the data array should be a timestamp.
 */
export function injectMissingDataPoints(data: ListedSecurityPrice[]): PriceSeriesData {
  const newData: PriceSeriesData = []

  // Start at the given start date, add 1 business day until we're past today
  let date = startOfDay(parseISO(data[0].date))
  let dataIndex = 0
  while (!isToday(date) && !isFuture(date)) {
    // If we miss any data points at the end, add nulls
    if (dataIndex >= data.length) {
      newData.push([date.getTime(), null])
    } else {
      // If this date is before the next in the chart data, add nulls
      const value = data[dataIndex]
      if (isBefore(date, startOfDay(parseISO(value.date)))) {
        newData.push([date.getTime(), null])
      } else {
        newData.push([date.getTime(), value.price])
        dataIndex += 1
      }
    }

    // Add one business day, repeat
    date = addBusinessDays(date, 1)
  }

  return newData
}

/**
 * prepareBrokerRecommendations takes the broker recommendations from the backend,
 * converts the counts for buy/hold/sell to percentages and makes sure the dates
 * align with the share price chart.
 */
export function prepareBrokerRecommendations(
  data: ListedSecurityBrokerRecommendation[],
  startDate: number,
  endDate: number
): number[][] {
  const newBrokerRecommendations: number[][] = []

  let date = startDate
  for (let i = 0; i < data.length; i += 1) {
    const brokerRecommendation = data[i]
    let bStartDate = parseISO(brokerRecommendation.startDate).getTime()
    let bEndDate = parseISO(brokerRecommendation.endDate).getTime()

    // Skip this broker recommendation completely when it's before the start date
    if (isBefore(bEndDate, startDate)) {
      continue
    }

    // Detect & fill gaps in broker recommendations
    if (differenceInBusinessDays(bStartDate, date) > 0) {
      newBrokerRecommendations.push([date, bStartDate, 0, 0, 0, 0])
    }
    date = bEndDate

    // The broker recommendations have a start and end date that comprise a full
    // time frame (e.g. a full week, month, quarter). Correct the first and last
    // broker recommendation to align perfectly with the start and end date of
    // the share price data, in case there isn't room for a full time frame.
    if (newBrokerRecommendations.length === 0 && isAfter(startDate, bStartDate)) {
      bStartDate = startDate
    }
    if (i === data.length - 1 && isBefore(endDate, bEndDate)) {
      bEndDate = endDate
    }

    // Sum up all counts
    const total =
      brokerRecommendation.buyCount +
      brokerRecommendation.holdCount +
      brokerRecommendation.sellCount

    // Calculate percentages
    const buy = Math.floor((brokerRecommendation.buyCount / total) * 100)
    const hold = Math.floor((brokerRecommendation.holdCount / total) * 100)
    const sell = 100 - buy - hold

    // Add this broker recommendation
    newBrokerRecommendations.push([bStartDate, bEndDate, buy, hold, sell, total])

    // Check if this is the last one and there's a gap at the end
    if (i === data.length - 1 && differenceInBusinessDays(endDate, bEndDate) > 0) {
      newBrokerRecommendations.push([bEndDate, endDate, 0, 0, 0, 0])
    }
  }

  return newBrokerRecommendations
}

/**
 * isTick returns whether a given date should be a tick. The oldest data point
 * determines what actual time range we're looking at and determines which dates
 * should become a tick.
 */
export function isTick(firstSharePriceDate: number, date: number): boolean {
  // For the bigger time ranges, show a tick on the first business day of the year
  if (differenceInYears(new Date(), firstSharePriceDate) >= 2) {
    return getMonth(date) === 0 && isFirstBusinessDayOfTheMonth(date)
  }

  // For all other ranges, show a tick per first business day of the month
  return isFirstBusinessDayOfTheMonth(date)
}

/**
 * formatTick formats a given date for display as a tick on the X-axis. The oldest
 * data point determines what actual time range we're looking at and determines
 * how ticks should be formatted.
 */
export function formatTick(
  firstSharePriceDate: number,
  date: number,
  isFirstTick: boolean
): string {
  const now = new Date()

  // For the bigger time ranges (3Y / 5Y), display ticks as years
  if (differenceInYears(now, firstSharePriceDate) > 1) {
    return formatDate(date, { format: 'year' })
  }

  // Display the month, and add the abbreviated year when it's the first tick
  // or when a new year starts (1Y)
  if (differenceInMonths(now, firstSharePriceDate) > 6) {
    if (isFirstTick || getMonth(date) === 0) {
      return formatDate(date, { format: 'monthYearAbbreviated' })
    }
    return formatDate(date, { format: 'month' })
  }

  // For all other time ranges, show the month and only the abbreviated year
  // when a new year starts at this tick.
  if (getMonth(date) === 0) {
    return formatDate(date, { format: 'monthYearAbbreviated' })
  }
  return formatDate(date, { format: 'month' })
}

/**
 * calculateYAxisLabelWidth calculates the width for the labels for the Y-axes.
 * Unfortunately, when aligning two charts with ECharts, it does not allow you to
 * align the start point of the Y-axis. The only way to fix this, is by setting a
 * hard width for all the Y-axis labels that is the same for both charts.
 * This function looks at the maximum data point in the graph, then decides what
 * the maximum value on the Y-axis would be and approach the width of the text
 * that will be on this max label.
 * WARNING: This will break if we change font size for the Y axis label.
 */
export function calculateYAxisLabelWidth(maxPricePoint: number): number {
  // Calculate the label width
  const digits = maxPricePoint.toFixed(0).length
  let yAxisLabelWidth = 36 // fits the text '100%'
  if (digits > 3) {
    // Count the number of commas, count them as half a character
    const commas = Math.floor(digits / 3) * 0.5

    // If the most significant number of the maximum price point is 8 or higher,
    // the Y-axis will show the next order of magnitude as highest tick. E.g.,
    // when the max point is 8.500, the highest Y-axis label will be 10.000.
    const firstDigit = parseInt(`${maxPricePoint}`[0], 10)
    const orderOfMagnitudeCompensation = firstDigit >= 8 ? 1 : 0

    // Calculate the width
    const numberOfCharacters = digits + commas + orderOfMagnitudeCompensation
    yAxisLabelWidth = numberOfCharacters * Y_AXIS_LABEL_CHARACTER_WIDTH
  }

  return yAxisLabelWidth
}

/**
 * calculateBrokerRecommendationBarDimensions calculates the dimensions of a bar
 * for the broker recommendations.
 */
export function calculateBrokerRecommendationBarDimensions(
  data: MarketDataChartData,
  api: CustomSeriesRenderItemAPI,
  dataIndex: number,
  startY: number,
  endY: number
) {
  // Calculate X-axis start and end point
  const startDate = data.brokerRecommendations[dataIndex][0]
  const endDate = data.brokerRecommendations[dataIndex][1]
  const startX = differenceInBusinessDays(startDate, data.sharePrice[0][0])
  const endX = differenceInBusinessDays(endDate, data.sharePrice[0][0])

  // Calculate actual coordinates from the start/end positions for X and Y
  const startCoordinate = api.coord([startX, startY])
  const endCoordinate = api.coord([endX, endY])

  // To create a 2px gap between the bars, start all bars on x+1, except for
  // the first data point
  const startCorrection = dataIndex === 0 ? 0 : 1

  // Except for the last bar, subtract 1 from width for a 2px gap between bars
  const widthCorrection = dataIndex === data.brokerRecommendations.length - 1 ? 0 : 1

  // Calculate width and height
  const x = startCoordinate[0] + startCorrection
  const y = endCoordinate[1]
  const width = endCoordinate[0] - x - widthCorrection
  const height = startCoordinate[1] - endCoordinate[1]
  const originY = startCoordinate[1]

  return { x, y, width, height, originY }
}

/**
 * renderBrokerRecommendationBarPart draws a part of the broker recommendation
 * bars using the custom chart API. The drawing logic for all bar parts are almost
 * the same. They differ in color and since they are stacked on top of each other,
 * the Y-position calculation is a bit different for each bar.
 *
 * Note that there's no bar radius on the individual parts, there's a custom serie
 * that draws white border radius borders to mimic the radius.
 */
export function renderBrokerRecommendationBarPart(
  data: MarketDataChartData,
  api: CustomSeriesRenderItemAPI,
  dataIndex: number,
  startY: number,
  endY: number,
  color: string
): CustomSeriesRenderItemReturn {
  const { x, y, width, height, originY } = calculateBrokerRecommendationBarDimensions(
    data,
    api,
    dataIndex,
    startY,
    endY
  )

  return {
    type: 'rect',
    shape: {
      x,
      y,
      width,
      height,
    },

    // On highlight, set the opacity to 100%
    emphasis: {
      style: {
        opacity: 1,
      },
    },

    // Give the bar the right color and dim it by default
    style: {
      fill: color,
      opacity: DIMMED_CHART_OPACITY,
    },

    // Animate this bar as growing from the bottom
    originY,
    enterAnimation: {
      duration: 400,
    },
    enterFrom: {
      scaleY: 0,
      shape: {}, // Need this empty definition, otherwise it fails
    },
  }
}
