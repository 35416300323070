import Head from '@gain/modules/head'
import { Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import React from 'react'

import Card, { CardHeader } from '../../../common/card/card'
import InvestorStrategiesTable from './investor-strategies-table'

export interface RouteStrategiesProps {
  investor: Investor
  strategies: InvestorProfileStrategy[]
}

export default function RouteStrategies({ investor, strategies }: RouteStrategiesProps) {
  return (
    <>
      <Head>
        <title>{investor.name} - Strategies</title>
      </Head>
      <Card>
        <CardHeader title={'Strategies'} />
        <InvestorStrategiesTable
          investor={investor}
          rows={strategies}
        />
      </Card>
    </>
  )
}
