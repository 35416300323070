import { ChevronRightIcon, InfoIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  minWidth: 0,
})

const StyledMetricValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexWrap: 'wrap',
  minWidth: 0,
}))

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  minWidth: 0,
  alignItems: 'center',
  color: theme.palette.text.primary,
  ...theme.typography.h5,
}))

const StyledValue = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.h5,
}))

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 18,
}))

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

export interface MetricProps {
  label: string
  value: string | number
  href?: string | false
  onInfoClick?: () => void
  suffixComponent?: ReactElement
}

export default function Metric({ label, value, href, onInfoClick, suffixComponent }: MetricProps) {
  return (
    <StyledRoot>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}
        minWidth={0}>
        <Typography
          color={'text.secondary'}
          variant={'body2'}
          noWrap>
          {label}
        </Typography>
        {onInfoClick && <StyledInfoIcon onClick={onInfoClick} />}
      </Stack>
      <StyledMetricValueContainer>
        {!!href && (
          <StyledLink href={href}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              {value}
            </Typography>
            <StyledChevronRightIcon />
          </StyledLink>
        )}
        {!href && <StyledValue>{value}</StyledValue>}
        {suffixComponent}
      </StyledMetricValueContainer>
    </StyledRoot>
  )
}
