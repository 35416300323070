import {
  CompanyIcon,
  FlagIcon,
  GlobeIcon,
  LeafIcon,
  MoneyBagIcon,
  StarOutlinedIcon,
} from '@gain/components/icons'
import { FilterableInvestorListItem } from '@gain/rpc/app-model'
import { ratingEsgMap, ratingInvestmentCriteriaMap } from '@gain/utils/investment-criteria'

import { dropdownMenuGroup, DropdownMenuOptions } from '../../../common/dropdown-menu'
import {
  advisor,
  createFilterMap,
  FilterModel,
  filterValueGroup,
  filterValueItem,
  rangeCurrency,
  rangeNumber,
  rangeYear,
  rating,
  region,
  sector,
  tag,
} from '../../filter/filter-bar'
import { FilterConfig } from '../../filter/filter-bar/filter-config/filter-config-model'

export const INVESTOR_FILTER_FIELDS = [
  'operationalHqCountryCode',

  'assetsFiltered',
  'assetRegions',
  'assetSubsectors',
  'advisorIds',
  'asset.tagIds',

  'asset.ebitdaEur',

  'asset.ratingGrowth',
  'asset.ratingOrganicGrowth',
  'asset.ratingGrossMargin',
  'asset.ratingEbitda',
  'asset.ratingConversion',
  'asset.ratingNonCyclical',
  'asset.ratingContracted',
  'asset.ratingLeader',
  'asset.ratingMultinational',
  'asset.ratingBuyAndBuild',

  'flagshipFundSizeEur',
  'flagshipFundVintageYear',

  'assetsMedianMarketSegmentRatingOverall',
  'assetsMedianMarketSegmentRatingEnvironmental',
  'assetsMedianMarketSegmentRatingSocial',
] as const

export type InvestorFilterField = (typeof INVESTOR_FILTER_FIELDS)[number]

export const INVESTOR_FILTER_MAP = createFilterMap<
  FilterableInvestorListItem,
  typeof INVESTOR_FILTER_FIELDS
>(
  region('operationalHqCountryCode', 'Investor HQ'),
  rangeNumber('assetsFiltered', 'Number of companies', {
    icon: CompanyIcon,
    hint: 'The number of companies of the investor portfolio that must match the region, sector and EBITDA range filter.',
  }),
  region('assetRegions', 'Active in region', { icon: GlobeIcon }),
  sector('assetSubsectors', 'Active in sector'),
  advisor('advisorIds', 'Advisors'),

  tag('asset.tagIds', 'Tag'),

  rangeCurrency('asset.ebitdaEur', 'EBITDA range', { icon: MoneyBagIcon }),

  // Investment criteria
  rating('asset.ratingGrowth', ratingInvestmentCriteriaMap.ratingGrowth),
  rating('asset.ratingOrganicGrowth', ratingInvestmentCriteriaMap.ratingOrganicGrowth),
  rating('asset.ratingGrossMargin', ratingInvestmentCriteriaMap.ratingGrossMargin),
  rating('asset.ratingEbitda', ratingInvestmentCriteriaMap.ratingEbitda),
  rating('asset.ratingConversion', ratingInvestmentCriteriaMap.ratingConversion),
  rating('asset.ratingNonCyclical', ratingInvestmentCriteriaMap.ratingNonCyclical),
  rating('asset.ratingContracted', ratingInvestmentCriteriaMap.ratingContracted),
  rating('asset.ratingLeader', ratingInvestmentCriteriaMap.ratingLeader),
  rating('asset.ratingMultinational', ratingInvestmentCriteriaMap.ratingMultinational),
  rating('asset.ratingBuyAndBuild', ratingInvestmentCriteriaMap.ratingBuyAndBuild),

  rangeCurrency('flagshipFundSizeEur', 'Flagship fund size'),
  rangeYear('flagshipFundVintageYear', 'Flagship fund vintage date'),

  rating('assetsMedianMarketSegmentRatingOverall', ratingEsgMap.ratingOverall),
  rating('assetsMedianMarketSegmentRatingEnvironmental', ratingEsgMap.ratingEnvironmental),
  rating('assetsMedianMarketSegmentRatingSocial', ratingEsgMap.ratingSocial)
)

/**
 * The list of filters in the "+ Add filter" dropdown menu.
 */
export const INVESTOR_FILTER_MENU: DropdownMenuOptions<
  FilterConfig<FilterableInvestorListItem, InvestorFilterField>
> = [
  INVESTOR_FILTER_MAP.operationalHqCountryCode,
  INVESTOR_FILTER_MAP['asset.tagIds'],
  INVESTOR_FILTER_MAP.assetsFiltered,
  INVESTOR_FILTER_MAP['asset.ebitdaEur'],
  dropdownMenuGroup('flagshipFund', 'Flagship fund', {
    icon: FlagIcon,
    children: [
      INVESTOR_FILTER_MAP.flagshipFundSizeEur,
      INVESTOR_FILTER_MAP.flagshipFundVintageYear,
    ],
  }),
  INVESTOR_FILTER_MAP.assetRegions,
  INVESTOR_FILTER_MAP.assetSubsectors,
  INVESTOR_FILTER_MAP.advisorIds,
  dropdownMenuGroup('assessment', 'Assessment', {
    icon: StarOutlinedIcon,
    children: [
      INVESTOR_FILTER_MAP['asset.ratingGrowth'],
      INVESTOR_FILTER_MAP['asset.ratingOrganicGrowth'],
      INVESTOR_FILTER_MAP['asset.ratingGrossMargin'],
      INVESTOR_FILTER_MAP['asset.ratingEbitda'],
      INVESTOR_FILTER_MAP['asset.ratingConversion'],
      INVESTOR_FILTER_MAP['asset.ratingNonCyclical'],
      INVESTOR_FILTER_MAP['asset.ratingContracted'],
      INVESTOR_FILTER_MAP['asset.ratingLeader'],
      INVESTOR_FILTER_MAP['asset.ratingMultinational'],
      INVESTOR_FILTER_MAP['asset.ratingBuyAndBuild'],
    ],
  }),
  dropdownMenuGroup('esg', 'ESG', {
    icon: LeafIcon,
    children: [
      INVESTOR_FILTER_MAP['assetsMedianMarketSegmentRatingOverall'],
      INVESTOR_FILTER_MAP['assetsMedianMarketSegmentRatingEnvironmental'],
      INVESTOR_FILTER_MAP['assetsMedianMarketSegmentRatingSocial'],
    ],
  }),
]

export const INVESTOR_DEFAULT_FILTERS: FilterModel<
  FilterableInvestorListItem,
  InvestorFilterField
> = [
  filterValueGroup(filterValueItem('asset.tagIds')),
  filterValueGroup(filterValueItem('operationalHqCountryCode')),
  filterValueGroup(filterValueItem('assetRegions')),
  filterValueGroup(filterValueItem('assetSubsectors')),
  filterValueGroup(filterValueItem('asset.ebitdaEur')),
  filterValueGroup(filterValueItem('assetsFiltered')),
]
