import { useCallback } from 'react'

import ChartSelect from './chart-select'

export interface ChartSizeTypeConfig<D> {
  id: string
  label: string
  getValue: (d: D) => number | null
  formatter: (value: number | null, item: D) => string
}

interface ChartSizeTypeSelectProps<Data> {
  options: ChartSizeTypeConfig<Data>[]
  value: ChartSizeTypeConfig<Data>
  onChange: (value: ChartSizeTypeConfig<Data>) => void
}

/**
 * Changes which sizing-type to use for a chart. This setting impacts the area
 * items take up in the chart.
 */
export default function ChartSizeTypeSelect<Data>({
  options,
  value,
  onChange,
}: ChartSizeTypeSelectProps<Data>) {
  const handleGetLabel = useCallback((option: ChartSizeTypeConfig<Data>) => {
    return option.label
  }, [])

  return (
    <ChartSelect
      getLabel={handleGetLabel}
      label={'Size'}
      onChange={onChange}
      options={options}
      value={value}
    />
  )
}
