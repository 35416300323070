import Tooltip from '@gain/components/tooltip'
import Link from '@mui/material/Link'
import React from 'react'

import BetaChip from '../../../common/beta'

function LinkMailTo({ mailto }) {
  return (
    <Link
      href={`mailto:${mailto}`}
      underline={'none'}>
      {mailto}
    </Link>
  )
}

export default function FinancialsBetaChip() {
  return (
    <Tooltip
      placement={'top'}
      title={
        <>
          We have recently added public company financials from a third-party source to our
          platform. Please reach out to <LinkMailTo mailto={'info@gain.pro'} /> if you have any
          comments about these financials. The financials of all non-public companies are
          unaffected.
        </>
      }
      variant={'preview'}>
      <div>
        <BetaChip showInfoIcon />
      </div>
    </Tooltip>
  )
}
