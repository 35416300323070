import { isBooleanListFilter, ListFilter, ListItemKey } from '@gain/rpc/list-model'
import { uniq } from 'lodash'
import { stringify } from 'query-string'
import { useCallback, useRef } from 'react'
import { encodeQueryParams, QueryParamConfig, UrlUpdateType, useQueryParam } from 'use-query-params'

import { FilterConfigMap } from './filter-config/filter-config-model'
import { FilterModel } from './filter-model'
import { filterValueGroup, filterValueItem } from './filter-value-builders'
import { FilterValueGroup } from './filter-value-model'
import { fromFilterModel } from './from-filter-model'

function encodeFilterGroups(filterGroups: FilterValueGroup[]) {
  return filterGroups.map((group) => group.value.map((item) => [item.filterId, item.value]))
}

function decodeFilterGroups(stringFilter: string, isRetry = false) {
  try {
    const groups = JSON.parse(stringFilter) as [string, any][]

    return groups.map((group) =>
      filterValueGroup(...group.map((item) => filterValueItem<any>(item[0], item[1] || null)))
    )
  } catch (err) {
    // Old cases could be that the filter was double encoded, so if we are not retrying than decode it once more
    if (!isRetry) {
      return decodeFilterGroups(decodeURIComponent(stringFilter), true)
    }

    return null
  }
}

export const FilterModelParam: QueryParamConfig<FilterValueGroup[]> = {
  encode: (value) => JSON.stringify(encodeFilterGroups(value)),
  decode: (value) => {
    if (typeof value === 'string') {
      try {
        return decodeFilterGroups(value) as any
      } catch (e) {
        // silently fallback to null if we could not parse the filter groups
      }
    }

    return null
  },
}

export type FilterModelQueryParam<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> = QueryParamConfig<FilterValueGroup<Item, FilterField>[], FilterValueGroup<Item, FilterField>[]>

export const FILTER_QUERY_PARAM_KEY = 'filter'

type SetFilterModelFn<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> = (nextFilterModel: FilterModel<Item, FilterField>, updateType?: UrlUpdateType) => void

export function useFilterModelQueryParam<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>() {
  const timeoutIdRef = useRef<number | undefined>()
  const [filterModel, setFilterModel] = useQueryParam(
    FILTER_QUERY_PARAM_KEY,
    FilterModelParam as unknown as QueryParamConfig<FilterValueGroup<Item, FilterField>[]>
  )

  const debouncedSetFilterModel = useCallback(
    (nextFilterModel: FilterModel<Item, FilterField>, updateType: UrlUpdateType = 'replaceIn') => {
      clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = window.setTimeout(() => {
        if (JSON.stringify(nextFilterModel) !== JSON.stringify(filterModel)) {
          setFilterModel(nextFilterModel, updateType)
        }
      }, 250)
    },
    [filterModel, setFilterModel]
  )

  return [filterModel, debouncedSetFilterModel] as [
    FilterValueGroup<Item, FilterField>[] | null,
    SetFilterModelFn<Item, FilterField>
  ]
}

/**
 * Removes all boolean filters ('and', 'or', 'not') and returns a flat list of filters.
 */
export function flattenFilters<Item extends object>(
  filters: ListFilter<Item>[]
): Array<keyof Item> {
  return filters.reduce((acc, current) => {
    if (isBooleanListFilter(current)) {
      return acc.concat(flattenFilters(current.value))
    }

    return acc.concat(current.field as keyof Item)
  }, new Array<keyof Item>())
}

/**
 * Returns true if any of the filters in the filter model have the given prefix.
 */
export function useHasActiveFilterPrefix<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(prefix: string, filterConfigMap: FilterConfigMap<Item, FilterField>): boolean {
  const [filterModel] = useFilterModelQueryParam<Item, FilterField>()
  const filters = fromFilterModel(filterModel, filterConfigMap)
  const filterKeys = uniq(flattenFilters(filters))

  return filterKeys.some((field) => typeof field === 'string' && field.startsWith(prefix))
}

export function filterModelToQueryString<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(value: FilterModel<Item, FilterField>) {
  const encodedQuery = encodeQueryParams(
    {
      [FILTER_QUERY_PARAM_KEY]: FilterModelParam as unknown as FilterModelQueryParam<
        Item,
        FilterField
      >,
    },
    {
      [FILTER_QUERY_PARAM_KEY]: value,
    }
  )
  return stringify(encodedQuery)
}

export function createFilterModelQueryRoute<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(pagePath: string, values: FilterModel<Item, FilterField>) {
  return [pagePath, filterModelToQueryString<Item, FilterField>(values)].join('?')
}
