import { useAssetListItem } from '@gain/api/app/hooks'
import { ChevronRightIcon, InfoIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { Asset } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'

import { AlertDialog } from '../../../common/dialog'
import TooltipAsset from '../../../features/asset/asset-tooltip'
import { generateAssetPagePath } from '../../utils'

const StyledContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(0.5),
}))

export function getSubsidiaryParentId(subsidiaryPath?: number[] | null) {
  if (!subsidiaryPath || subsidiaryPath.length < 2) {
    return null
  }

  return subsidiaryPath[subsidiaryPath.length - 2]
}

const noLongerMaintainedOwnerships = [
  AssetOwnershipType.ExitedToStrategic,
  AssetOwnershipType.Bankrupt,
]

export function isNoLongerMaintained(asset?: Asset) {
  if (!asset || !asset.generalInfo) {
    return false
  }

  return noLongerMaintainedOwnerships.includes(asset.generalInfo.ownership)
}

export function useHasSubtitle(asset?: Asset) {
  return useMemo(() => {
    if (!asset) {
      return false
    } else if (getSubsidiaryParentId(asset?.subsidiaryPath)) {
      return true
    } else if (isNoLongerMaintained(asset)) {
      return true
    } else if (asset.description?.short) {
      return true
    }

    return false
  }, [asset])
}

export interface AssetHeaderSubtitleProps {
  asset: Asset
}

export default function AssetHeaderSubtitle({ asset }: AssetHeaderSubtitleProps) {
  const parentId = getSubsidiaryParentId(asset.subsidiaryPath)
  const notMaintained = isNoLongerMaintained(asset)
  const [isOpen, open, close] = useDialogState()
  const parent = useAssetListItem(parentId)
  const isBrowserExtension = useIsBrowserExtension()

  if ((!isBrowserExtension && parent.data) || notMaintained) {
    return (
      <>
        <StyledContainer>
          <InfoIcon
            color={'error'}
            fontSize={'inherit'}
          />
          <Typography
            color={'error'}
            sx={{ display: 'flex' }}
            variant={'body2'}>
            {notMaintained && (
              <span
                onClick={open}
                style={{ cursor: 'pointer' }}>
                No longer maintained.
              </span>
            )}
            {notMaintained && parent.data && <>&nbsp;</>}
            {!isBrowserExtension && parent.data && (
              <>
                This is a subsidiary of
                <TooltipAsset id={parentId}>
                  <Button
                    component={Link}
                    endIcon={<ChevronRightIcon />}
                    href={generateAssetPagePath({
                      id: parent.data.id,
                      name: parent.data.name,
                    })}
                    sx={{ ml: 0.5 }}
                    variant={'inline'}>
                    {parent.data.name}
                  </Button>
                </TooltipAsset>
              </>
            )}
          </Typography>
        </StyledContainer>
        <AlertDialog
          message={
            <span>
              The profile of
              <Typography
                component={'span'}
                fontSize={'inherit'}
                variant={'subtitle2'}>
                &nbsp;{asset.generalInfo?.name}&nbsp;
              </Typography>
              is no longer maintained because of its ownership type “
              {asset.generalInfo?.ownership === AssetOwnershipType.Bankrupt
                ? 'bankrupt'
                : 'strategic exit'}
              ”.
              <br />
              <br />
              Please look at the linked legal entities for the latest financials. Alternatively,
              check out the profile of the buyer.
            </span>
          }
          onClose={close}
          open={isOpen}
          title={'Profile no longer maintained'}
        />
      </>
    )
  }

  if (asset.description?.short) {
    return (
      <Typography
        color={'text.secondary'}
        variant={'body2'}
        noWrap>
        {asset.description.short}
      </Typography>
    )
  }

  return null
}
