import Flag from '@gain/components/flag'
import { CompetitorListItem } from '@gain/rpc/app-model'
import { isTruthy } from '@gain/utils/common'
import React, { useMemo } from 'react'

import Table, { ColumnConfig } from '../../../../common/table'
import AssetContentLink from '../../../../features/asset/asset-content-link'
import FinancialValue from '../../../../features/financial/financial-value'

export interface AssetMarketTableProps {
  rows: CompetitorListItem[]
  disableHqColumn: boolean
}

export default function AssetMarketTable({ rows, disableHqColumn }: AssetMarketTableProps) {
  const columns = useMemo(
    () =>
      [
        {
          headerName: 'Competitor',
          field: 'name',
          sortable: false,
          flex: 1,
          renderCell: ({ row }) => (
            <AssetContentLink
              asset={row.linkedAsset}
              id={row.linkedAssetId}
              logoFileUrl={row.linkedAssetLogoFileUrl}
              name={row.name}
            />
          ),
        },
        !disableHqColumn && {
          field: 'headquarters',
          headerName: 'HQ',
          align: 'center',
          width: 48,
          sortable: false,
          renderCell: ({ value }) => (value ? <Flag code={value} /> : <>-</>),
        },
        {
          field: 'revenueEur',
          headerName: 'Revenue',
          align: 'right',
          width: 110,
          sortable: false,
          renderCell: ({ value }) => <FinancialValue amount={value} />,
        },
      ].filter(isTruthy) as ColumnConfig<CompetitorListItem>[],
    [disableHqColumn]
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      dense
      disablePaddingBottomOnLastRow
    />
  )
}
