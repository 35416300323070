export enum AdvisoryActivity {
  Tax = 'tax',
  Debt = 'debt',
  Operational = 'operational',
  Hr = 'hr',
  ExecutiveSearch = 'executiveSearch',
  Ecm = 'ecm',
  Forensics = 'forensics',
  Ma = 'ma',
  Financial = 'financial',
  ItTechnology = 'itTechnology',
  FairnessOpinion = 'fairnessOpinion',
  Commercial = 'commercial',
  Legal = 'legal',
  Pr = 'pr',
  EnvironmentalSocial = 'environmentalSocial',
  Insurance = 'insurance',
  Other = 'other',
}

export enum ArticleCategory {
  PlatformDeal = 'platformDeal',
  AddOn = 'addOn',
  StrategicExit = 'strategicExit',
  VcRound = 'vcRound',
  Other = 'other',
}

export enum ArticleType {
  NewAssets = 'newAssets',
  NewFinancials = 'newFinancials',
  InTheNews = 'inTheNews',
  PeerReview = 'peerReview',
  IndustryResearch = 'industryResearch',
}

export enum AssetBusinessActivityType {
  Manufacturing = 'manufacturing',
  Distribution = 'distribution',
  ResearchAndDevelopment = 'researchAndDevelopment',
  Services = 'services',
  Engineering = 'engineering',
  Design = 'design',
  Operator = 'operator',
  Retail = 'retail',
  Other = 'other',
}

export enum AssetCustomerBaseType {
  BusinessToBusiness = 'businessToBusiness',
  BusinessToConsumer = 'businessToConsumer',
  BusinessToGovernment = 'businessToGovernment',
}

export enum AssetOwnershipType {
  Private = 'private',
  Listed = 'listed',
  Regular = 'regular',
  Other = 'other',
  Minority = 'minority',
  ExitedToStrategic = 'exitedToStrategic',
  Bankrupt = 'bankrupt',
  VentureCapital = 'ventureCapital',
  Government = 'government',
}

export enum AssetPricePositioningType {
  Discount = 'discount',
  Premium = 'premium',
}

export enum AssetProfileType {
  Minimal = 'minimal',
  Limited = 'limited',
  Full = 'full',
  Automated = 'automated',
}

export enum AssetSalesChannelType {
  Online = 'online',
  BrickAndMortar = 'brickAndMortar',
  IndirectSales = 'indirectSales',
  DirectSales = 'directSales',
}

export enum AssetShareholderShare {
  Majority = 'majority',
  Minority = 'minority',
}

export enum CurrencyDisplayType {
  Code = 'code',
  Symbol = 'symbol',
}

export enum DealAdvisorAdvised {
  Unknown = 'unknown',
  Sellers = 'sellers',
  Buyers = 'buyers',
}

export enum DealFactConfidence {
  Reported = 'reported',
  Estimate = 'estimate',
}

export enum DealFundingRoundType {
  PreSeed = 'preSeed',
  SeriesA = 'seriesA',
  SeriesB = 'seriesB',
  SeriesC = 'seriesC',
  SeriesD = 'seriesD',
  SeriesE = 'seriesE',
  SeriesF = 'seriesF',
  SeriesG = 'seriesG',
  SeriesH = 'seriesH',
  SeriesI = 'seriesI',
  SeriesJ = 'seriesJ',
  Venture = 'venture',
  OtherUnknown = 'otherUnknown',
}

export enum DealItemStatus {
  New = 'new',
  Review = 'review',
  Published = 'published',
}

export enum DealReason {
  Divestiture = 'divestiture',
  StrategicExit = 'strategicExit',
  IPO = 'IPO',
  Platform = 'platform',
  PublicToPrivate = 'publicToPrivate',
  VcRound = 'vcRound',
}

export enum DealSideShare {
  Majority = 'majority',
  Minority = 'minority',
  SharedMajority = 'sharedMajority',
}

export enum DealSideType {
  Asset = 'asset',
  Investor = 'investor',
  Other = 'other',
}

export enum DealStatus {
  Live = 'live',
  Aborted = 'aborted',
}

export enum DealType {
  Dealroom = 'dealroom',
}

export enum FinancialResultAmountType {
  Actual = 'actual',
  Estimated = 'estimated',
  Ai = 'ai',
}

export enum FinancialResultPeriodicityType {
  Annual = 'annual',
  NextTwelveMonths = 'nextTwelveMonths',
  LastTwelveMonths = 'lastTwelveMonths',
}

export enum IndustryChartType {
  RevenueSegmentation = 'revenueSegmentation',
  EbitdaSegmentation = 'ebitdaSegmentation',
  AssetBenchmarking = 'AssetBenchmarking',
  StrategicMajority = 'strategicMajority',
  LikelihoodOfSponsorExit = 'likelihoodOfSponsorExit',
  LikelihoodOfLeadershipChange = 'likelihoodOfLeadershipChange',
}

export enum IndustryStatus {
  Archived = 'archived',
  New = 'new',
  Review = 'review',
  Published = 'published',
}

export enum InvestorFundFundraisingStatus {
  Launched = 'launched',
  FirstClose = 'firstClose',
  FinalClose = 'finalClose',
  Closed = 'closed',
}

export enum InvestorStrategyClassification {
  Buyout = 'buyout',
  Growth = 'growth',
  VentureCapital = 'ventureCapital',
  SpecialSituation = 'specialSituation',
  Infrastructure = 'infrastructure',
  Credit = 'credit',
  CoInvestment = 'coInvestment',
  Others = 'others',
}

export enum InvestorStrategyPreferredStake {
  Minority = 'minority',
  Majority = 'majority',
  Flexible = 'flexible',
  Unknown = 'unknown',
}

export enum UserRole {
  User = 'user',
  Admin = 'admin',
  Superadmin = 'superadmin',
}

export enum UserStatus {
  NotInvited = 'notInvited',
  Invited = 'invited',
  Active = 'active',
  Deactivated = 'deactivated',
}

export enum UserUnitSystem {
  Km = 'km',
  Miles = 'miles',
}
