import Typography from '@gain/components/typography'
import { useSplitList } from '@gain/utils/list'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

import { Manager } from './asset-management-util'
import TableManager from './table-manager'

const StyledMoreContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
}))

export interface TableManagerContainerProps {
  managers: Manager[]
}

export default function TableManagerContainer({ managers }: TableManagerContainerProps) {
  const [showAll, setShowAll] = useState(false)
  const [initialItems, additionalItems] = useSplitList(managers, 3)

  return (
    <>
      <TableManager managers={showAll ? initialItems.concat(additionalItems) : initialItems} />
      {additionalItems.length > 0 && !showAll && (
        <StyledMoreContainer>
          <Typography
            color={'primary'}
            onClick={() => setShowAll(true)}
            sx={{ cursor: 'pointer' }}
            variant={'body2'}>
            + {additionalItems.length} more
          </Typography>
        </StyledMoreContainer>
      )}
    </>
  )
}
