import Collapse from '@mui/material/Collapse'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { ReactElement } from 'react'

import { useMenuContext } from './menu-provider'

export type ListCollapseProps = {
  collapsed: ReactElement
  expanded: ReactElement
}

export function ListCollapse(props: ListCollapseProps) {
  const [showMenu] = useMenuContext()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <Collapse
        in={!showMenu && isMdUp}
        timeout={{
          appear: theme.transitions.duration.enteringScreen,
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen,
        }}>
        {props.collapsed}
      </Collapse>
      <Collapse
        in={showMenu || !isMdUp}
        timeout={{
          appear: theme.transitions.duration.enteringScreen,
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen,
        }}>
        {props.expanded}
      </Collapse>
    </>
  )
}
