import Typography from '@gain/components/typography'
import { formatYearAndQuarter } from '@gain/utils/date'
import { formatMultiple } from '@gain/utils/number'
import Chip from '@mui/material/Chip'
import React from 'react'

export interface MultipleTableCellProps {
  value: number | null
  year?: number | null
  quarter?: number | null
  precision?: number

  // In finance some numbers are not sensible, e.g. EBITDA % > 100%. If this
  // happens, we can display 'n.m.' (not meaningful) instead of the number.
  enableNotMeaningful?: boolean
}

export default function MultipleTableCell({
  value,
  year,
  quarter,
  precision = 0,
  enableNotMeaningful = false,
}: MultipleTableCellProps) {
  if (!value) {
    return '-'
  }

  const isNotMeaningful = enableNotMeaningful && value < 0
  const chipLabel = formatYearAndQuarter(year, quarter)

  return (
    <>
      <Typography
        variant={'inherit'}
        noWrap>
        {isNotMeaningful ? 'n.m.' : formatMultiple(value, { round: precision })}
      </Typography>

      {chipLabel && (
        <>
          &nbsp;
          <Chip
            label={chipLabel}
            size={'small'}
          />
        </>
      )}
    </>
  )
}
