export type GeneratePathParams = Record<string, string | number | boolean | undefined>

export type Params = Record<string, string | undefined>

/*
 * public paths
 */
export const LOGIN_PATH = '/login'
export const LOGIN_FULL_PATH = '/login'
export const SSO_CALLBACK_PATH = '/sso/callback'
export const FORGOT_PASSWORD_PATH = '/forgot-password'
export const FORGOT_PASSWORD_RESET_PATH = '/forgot-password/reset/:secret'
export const ONBOARDING_PATH = '/onboarding/:token'
export const EMAIL_UNSUBSCRIBE_PATH = '/unsubscribe/:name/:token'

export interface ResetPasswordRouteParams {
  secret: string
}

export interface UnsubscribeRouteParams {
  name: string
  token: string
}

export interface OnboardingRouteParams {
  token: string
}

/*
 * private paths
 */
export const HOME_PATH = '/home'
export const APP_PATH = '/'

export const INDUSTRY_LIST_PATH = '/industries'
export const DEAL_LIST_PATH = '/deals'
export const ADVISOR_LIST_PATH = '/advisors'
export const CONFERENCE_LIST_PATH = '/conferences'
export const CONFERENCE_PAGE_PATH = `${CONFERENCE_LIST_PATH}/:conferenceId/:conferenceName?`
export const ASSET_LIST_PATH = '/allAssets'
export const INVESTOR_LIST_PATH = '/owners'

export const SETTINGS_PATH = '/settings'
export const SETTINGS_TEAM_MEMBERS_PATH = `${SETTINGS_PATH}/team-members`
export const SETTINGS_ACCOUNT_PATH = `${SETTINGS_PATH}/account`
export const SETTINGS_EMAIL_PATH = `${SETTINGS_PATH}/email`

export const INVESTOR_FUND_LIST_PATH = '/owners/funds'
export const SEARCH_PAGE_PATH = '/search'
export const DEAL_PAGE_PATH = '/deal/:id/:name?'
export const ADVISOR_PAGE_PATH = '/advisors/:advisorId/:advisorName?'

export const HELP_OPTIONS_PATH = '/help'
