import { useListedSecurityValuations } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset, CurrencyListItem, ListedSecurityListItem } from '@gain/rpc/app-model'
import { useIsXs } from '@gain/utils/responsive'
import Grid from '@mui/material/Grid'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import React, { useState } from 'react'

import SourcesList from '../../../features/source/source-list/list-sources'
import { useAssetListedSecurities } from '../route-asset-hooks'
import AssetValuationMetricsCard from './asset-valuation-metrics-card'
import MarketDataChart from './market-data-chart/market-data-chart'
import { hasValuationRatios } from './ratios-card/ratio-utils'
import RatiosCard from './ratios-card/ratios-card'

function getListedSecurity(listedSecurities: ListedSecurityListItem[]) {
  if (listedSecurities.length === 0) {
    return null
  }

  // For the foreseeable future, there will only be a 1-on-1 mapping between an
  // asset and listed security. In theory the same asset could be listed on more
  // than 1 exchange, but we don't support that yet.
  const listedSecurity = listedSecurities[0]
  if (!listedSecurity.tradingCurrency) {
    return null
  }

  return listedSecurity
}

export interface AssetValuationProps {
  asset: Asset
}

export default function AssetValuation({ asset }: AssetValuationProps) {
  const isXs = useIsXs()
  const listedSecurities = useAssetListedSecurities(asset)
  const listedSecurity = getListedSecurity(listedSecurities.data.items)
  const swrValuations = useListedSecurityValuations(listedSecurity?.id)
  const hasValuations = hasValuationRatios(swrValuations.data)

  // Handle state for selected currency and time range
  const [currency, setCurrency] = useState<CurrencyListItem | null>(null)
  const selectedCurrency = currency?.name ?? 'EUR'

  // Don't render when there's no listed security
  if (!listedSecurity) {
    return null
  }

  if (!listedSecurity.tradingCurrency) {
    return null
  }

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Valuation</title>
      </Head>
      <Grid
        spacing={2}
        container>
        {/* Valuation metrics bar */}
        <Grid
          xs={12}
          item>
          <AssetValuationMetricsCard
            currency={selectedCurrency}
            listedSecurityCurrency={listedSecurity.tradingCurrency}
            listedSecurityId={listedSecurity.id}
          />
        </Grid>

        {/* Market data chart, hide it on mobile */}
        {!isXs && (
          <Grid
            xs={12}
            item>
            <MarketDataChart
              currency={currency}
              listedSecurity={listedSecurity}
              listedSecurityCurrency={listedSecurity.tradingCurrency}
              onCurrencyChange={setCurrency}
            />
          </Grid>
        )}

        {/* Ratios data chart */}
        {hasValuations && (
          <Grid
            xs={12}
            item>
            <RatiosCard valuations={swrValuations.data || []} />
          </Grid>
        )}

        {/* Copyright notice */}
        <Grid
          xs={12}
          item>
          <SourcesList
            sources={[
              {
                publisher: 'FactSet',
                title: `Copyright © ${getYear(
                  new Date()
                )} FactSet UK Limited. All rights reserved.`,
                publicationYear: getYear(new Date()),
                publicationMonth: getMonth(new Date()) + 1,
                url: null,
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
