import { isDefined, valueToEnum } from '@gain/utils/common'
import { capitalize } from '@mui/material/utils'

import { ManagerPosition } from './manager.model'

export interface FormatManagerPositionOptions {
  emptyValue?: string
  disableEnumCheck?: boolean
}

export const formatManagerPosition = (
  position: string | null | undefined,
  { emptyValue = '-', disableEnumCheck = false }: FormatManagerPositionOptions = {}
) => {
  if (!isDefined(position)) {
    return emptyValue
  }

  const parsed = valueToEnum(ManagerPosition, position.toLowerCase())

  if (!isDefined(parsed)) {
    return disableEnumCheck ? capitalize(position) : emptyValue
  }

  switch (parsed) {
    case ManagerPosition.CFO:
      return 'CFO'
    case ManagerPosition.CEO:
      return 'CEO'
    case ManagerPosition.CO_CEO:
      return 'Co-CEO'
    case ManagerPosition.MANAGING_DIRECTOR:
      return 'Managing director'
    case ManagerPosition.MD:
      return 'Md'
    case ManagerPosition.DIRECTOR:
      return 'Director'
    case ManagerPosition.CHAIRMAN:
      return 'Chairman'
    case ManagerPosition.BOARD_MEMBER:
      return 'Board member'
    case ManagerPosition.REPRESENTATIVE:
      return 'Representative'
    default:
      return position && `${position.charAt(0).toUpperCase()}${position.slice(1)}`
  }
}
