import { DealListItem } from '@gain/rpc/app-model'
import { parseYearMonth } from '@gain/utils/date'
import compareDesc from 'date-fns/compareDesc'

export function compareDealListItemByDateDesc(a: DealListItem, b: DealListItem) {
  return compareDesc(
    parseYearMonth(a.announcementDateYear, a.announcementDateMonth),
    parseYearMonth(b.announcementDateYear, b.announcementDateMonth)
  )
}
