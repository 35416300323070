import {
  BriefcaseMoneyIcon,
  CompanyIcon,
  ConferenceIcon,
  HomeIcon,
  IndustryIcon,
  ShuffleIcon,
  UserCommentIcon,
} from '@gain/components/icons'
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import ListSubheader from '@mui/material/ListSubheader'
import { styled } from '@mui/material/styles'
import React from 'react'

import {
  ADVISOR_LIST_PATH,
  ASSET_LIST_PATH,
  CONFERENCE_LIST_PATH,
  DEAL_LIST_PATH,
  HOME_PATH,
  INDUSTRY_LIST_PATH,
  INVESTOR_LIST_PATH,
} from '../utils'
import { useMenuContext } from './menu-provider'
import { NavMenuListItem } from './nav-menu-list-item'

const StyledListSubheader = styled(ListSubheader, {
  shouldForwardProp: (propName) => propName !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  overflow: 'hidden',
  ...(open && {
    height: 32,
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create(['height', 'color', 'min-height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  ...(!open && {
    [theme.breakpoints.up('md')]: {
      color: 'rgba(0, 0, 0, 0)',
      height: 0,
      minHeight: 0,
      transition: theme.transitions.create(['color', 'height', 'min-height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
}))

const StyledAdvisorsListItem = styled(NavMenuListItem)({
  [`& .${listItemSecondaryActionClasses.root}`]: {
    pointerEvents: 'none',
  },
})

function Header() {
  const [showMenu] = useMenuContext()
  return <StyledListSubheader open={showMenu}>Explore</StyledListSubheader>
}

export function MenuItems() {
  return (
    <>
      <Header />
      <NavMenuListItem
        icon={<HomeIcon fontSize={'inherit'} />}
        label={'Home'}
        to={HOME_PATH}
        exact
      />
      <NavMenuListItem
        icon={<CompanyIcon fontSize={'inherit'} />}
        label={'Companies'}
        to={ASSET_LIST_PATH}
      />
      <NavMenuListItem
        icon={<BriefcaseMoneyIcon fontSize={'inherit'} />}
        label={'Investors'}
        to={INVESTOR_LIST_PATH}
      />
      <StyledAdvisorsListItem
        icon={<UserCommentIcon fontSize={'inherit'} />}
        label={'Advisors'}
        to={ADVISOR_LIST_PATH}
      />
      <NavMenuListItem
        icon={<ShuffleIcon fontSize={'inherit'} />}
        label={'Deals'}
        to={DEAL_LIST_PATH}
      />
      <NavMenuListItem
        icon={<IndustryIcon fontSize={'inherit'} />}
        label={'Industries'}
        to={INDUSTRY_LIST_PATH}
      />
      <NavMenuListItem
        icon={<ConferenceIcon fontSize={'inherit'} />}
        label={'Conferences'}
        to={CONFERENCE_LIST_PATH}
      />
    </>
  )
}
