import { isDefined, valueToEnum } from '@gain/utils/common'

import { CEO_POSITIONS } from './manager.constants'
import { ManagerPosition } from './manager.model'

export function getCeoPositionImportance(position: string | null | undefined): number {
  if (!isDefined(position)) {
    return -1
  }

  const e = valueToEnum(ManagerPosition, position.toLowerCase())

  if (!isDefined(e)) {
    return -1
  }

  return CEO_POSITIONS.slice().reverse().indexOf(e)
}
