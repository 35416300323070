import { AdvisoryActivity, DealAdvisorAdvised } from '@gain/rpc/shared-model'

type AdvisoryActivityOption = {
  label: string
  value: AdvisoryActivity

  // The CMS only flag is used to hide advisory activities that are not yet shown
  // in the app. Their order was determined in this ticket:
  // https://www.notion.so/gainpro/Fix-it-Friday-update-Advisory-activities-in-CMS-and-app-f8510ecb166d452c8c4c0187979a9c0a?pvs=4
  // TODO: Remove this once we want to show all advice types in the app
  cmsOnly?: boolean
}

const ALL_ADVISORY_ACTIVITY_OPTIONS: AdvisoryActivityOption[] = [
  {
    label: 'M&A',
    value: AdvisoryActivity.Ma,
  },
  {
    label: 'ECM',
    value: AdvisoryActivity.Ecm,
    cmsOnly: true,
  },
  {
    label: 'Legal',
    value: AdvisoryActivity.Legal,
  },
  {
    label: 'Financial DD',
    value: AdvisoryActivity.Financial,
  },
  {
    label: 'Commercial',
    value: AdvisoryActivity.Commercial,
  },
  {
    label: 'Fairness opinion',
    value: AdvisoryActivity.FairnessOpinion,
  },
  {
    label: 'Tax',
    value: AdvisoryActivity.Tax,
  },
  {
    label: 'Debt',
    value: AdvisoryActivity.Debt,
    cmsOnly: true,
  },
  {
    label: 'Operational',
    value: AdvisoryActivity.Operational,
    cmsOnly: true,
  },
  {
    label: 'ESG',
    value: AdvisoryActivity.EnvironmentalSocial,
  },
  {
    label: 'Insurance',
    value: AdvisoryActivity.Insurance,
  },
  {
    label: 'IT/Technology',
    value: AdvisoryActivity.ItTechnology,
  },
  {
    label: 'HR DD',
    value: AdvisoryActivity.Hr,
    cmsOnly: true,
  },
  {
    label: 'Executive search',
    value: AdvisoryActivity.ExecutiveSearch,
    cmsOnly: true,
  },
  {
    label: 'Public relations',
    value: AdvisoryActivity.Pr,
  },
  {
    label: 'Other/Unknown',
    value: AdvisoryActivity.Other,
  },
  {
    label: 'Forensics',
    value: AdvisoryActivity.Forensics,
    cmsOnly: true,
  },
]

export const ADVISORY_ACTIVITY_OPTIONS = ALL_ADVISORY_ACTIVITY_OPTIONS.filter(
  (option) => !option.cmsOnly
)

// TODO: Remove this once we want to show all advice types in the app
export const ADVISORY_ACTIVITY_OPTIONS_CMS = ALL_ADVISORY_ACTIVITY_OPTIONS

export const ADVISOR_ADVISED_OPTIONS = [
  {
    label: 'Buy-side',
    value: DealAdvisorAdvised.Buyers,
  },
  {
    label: 'Sell-side',
    value: DealAdvisorAdvised.Sellers,
  },
  {
    label: 'Unknown',
    value: DealAdvisorAdvised.Unknown,
  },
]

/**
 * Get the advisedOn option for a given value. if it can't be found it returns the
 * "Other/unknown" option.
 */
export function getAdvisoryActivityOption(
  activity: AdvisoryActivity | null,
  availableOptions = ADVISORY_ACTIVITY_OPTIONS
): AdvisoryActivityOption {
  let result = availableOptions.find(({ value }) => value === activity)

  // The CMS may have new options that we do not want to show in the app yet
  if (!result) {
    result = availableOptions.find(({ value }) => value === AdvisoryActivity.Other)
  }

  if (!result) {
    throw new Error('Missing "Other/unknown" option') // This is a coding error
  }

  return result
}

// This function exists for the sole purpose to replace categories that are not
// yet shown in the app with "Other/unknown". This is a temporary solution until
// we want to show all advice types in the app.
// TODO: Remove this once we want to show all advice types in the app
export function getAdvisoryActivityValue(activity: AdvisoryActivity | null) {
  return getAdvisoryActivityOption(activity).value
}

export function getAdvisoryActivityLabel(activity: AdvisoryActivity | null) {
  return getAdvisoryActivityOption(activity).label
}

/**
 * Format the advisedOn values into a comma separated string. If a label couldn't
 * be found for a value "Other/unknown" is used instead. If no values are provided
 * at all "-" is returned.
 */
export function formatAdvisoryActivitiesLabels(activityValues: AdvisoryActivity[] | null) {
  // The Set deduplicates "Other/unknown" which may be present multiple times
  const values = new Set(activityValues?.map((advisedOn) => getAdvisoryActivityLabel(advisedOn)))
  if (values.size === 0) {
    return '-'
  }

  return Array.from(values).join(', ')
}

export function getAdvisorAdvisedLabel(advised: DealAdvisorAdvised | null) {
  return ADVISOR_ADVISED_OPTIONS.find(({ value }) => value === advised)?.label
}
