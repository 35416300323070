import Table, { createTableColumns } from '../../../../common/table'
import ContentLinkManager from '../../../../features/manager/manager-content-link'
import { Manager } from './asset-management-util'
import TableCellManagerRoleInvestor from './table-cell-manager-role-investor'

const columns = createTableColumns<Manager>(
  {
    field: 'person',
    headerName: 'Name',
    renderCell: ({ row }) => <ContentLinkManager manager={row} />,
  },
  {
    field: 'investor',
    width: 256,
    headerName: 'Role / Investor',
    renderCell: ({ row }) => <TableCellManagerRoleInvestor manager={row} />,
  }
)

export interface TableManagerProps {
  managers: Manager[]
}

export default function TableManager({ managers }: TableManagerProps) {
  return (
    <Table
      columns={columns}
      rows={managers}
    />
  )
}
