import { useDealList, useFindCurrencyCallback } from '@gain/api/app/hooks'
import { AssetList, DealListItem, Investor } from '@gain/rpc/app-model'
import { listFilter, listFilters } from '@gain/rpc/utils'
import { useFormatCurrencyCallback, useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { useDialogState } from '@gain/utils/dialog'
import React, { useMemo } from 'react'

import Card from '../../../../common/card/card'
import CardMetrics, { createMetrics } from '../../../../common/metrics/metrics-card'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_ENTRIES_PATH,
  INVESTOR_PAGE_FUNDS_PATH,
  INVESTOR_PAGE_PORTFOLIO_PATH,
  INVESTOR_PAGE_REPRESENTATIVES_PATH,
  INVESTOR_PAGE_STRATEGIES_PATH,
  useInvestorPageParams,
} from '../../../utils'
import AlertDialogDryPowder from '../../alert-dry-powder'

function useInvestorDealCount(investorId: number) {
  const { data: deals } = useDealList({
    filter: listFilters(listFilter<DealListItem>('buyerInvestorIds', '=', investorId)),
    limit: 0,
  })
  return useMemo(() => deals.counts.filtered || null, [deals.counts])
}

function useDryPowder(investor: Investor) {
  const findCurrency = useFindCurrencyCallback()
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return useMemo(() => {
    let min = 0
    let max = 0

    for (const fund of investor.funds) {
      if (fund.dryPowderMin === null || fund.dryPowderMax === null) {
        continue
      }

      const currency = findCurrency(fund.currency)
      if (!currency) {
        continue
      }

      min += fund.dryPowderMin * currency.toEur
      max += fund.dryPowderMax * currency.toEur
    }

    if (min === max) {
      return null
    }

    return formatCurrencyRange(min, max, { round: 'estimate' })
  }, [formatCurrencyRange, investor.funds, findCurrency])
}

export interface InvestorMetricsCardProps {
  investor: Investor
  assets: AssetList
  representativesCount: number
}

export default function InvestorMetricsCard({
  investor,
  assets,
  representativesCount,
}: InvestorMetricsCardProps) {
  const [isDryPowderAlertOpen, handleOpenDryPowderAlert, handleCloseDryPowderAlert] =
    useDialogState()
  const investorPageParams = useInvestorPageParams()
  const dealCount = useInvestorDealCount(investor.id)
  const dryPowderRange = useDryPowder(investor)
  const formatCurrency = useFormatCurrencyCallback()

  const metrics = useMemo(() => {
    return createMetrics(
      {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_PORTFOLIO_PATH),
        label: 'Companies',
        value: assets.counts.filtered,
      },
      {
        href:
          !!dealCount && generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_ENTRIES_PATH),
        label: 'Platform deals',
        value: dealCount || 0,
      },
      {
        href:
          investor.strategies.length > 0 &&
          generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_STRATEGIES_PATH),
        label: 'Strategies',
        value: investor.strategies.length === 0 ? 1 : investor.strategies.length,
      },
      dryPowderRange !== null && {
        href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
        label: 'Drypowder est.',
        value: dryPowderRange,
        onInfoClick: handleOpenDryPowderAlert,
      },
      typeof investor.fundsRaisedLastFiveYears === 'number' &&
        investor.fundsRaisedLastFiveYears > 0 && {
          href: generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_FUNDS_PATH),
          label: 'Fundraising (L5Y)',
          value: formatCurrency(investor.fundsRaisedLastFiveYears),
        },
      {
        href:
          representativesCount > 0 &&
          generateInvestorPagePath(investorPageParams, INVESTOR_PAGE_REPRESENTATIVES_PATH),
        label: 'Representatives',
        value: representativesCount,
      }
    )
  }, [
    investorPageParams,
    assets.counts.filtered,
    dealCount,
    investor.strategies.length,
    investor.fundsRaisedLastFiveYears,
    dryPowderRange,
    handleOpenDryPowderAlert,
    formatCurrency,
    representativesCount,
  ])

  return (
    <Card sx={{ pb: 0 }}>
      <CardMetrics metrics={metrics} />
      <AlertDialogDryPowder
        onClose={handleCloseDryPowderAlert}
        open={isDryPowderAlertOpen}
      />
    </Card>
  )
}
