import { Asset, CurrencyListItem } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { FinancialResult } from '@gain/utils/financials'
import React from 'react'

import FinancialsCard from '../../../../features/financial/financials-card'

export interface AssetFinancialsCardProps {
  asset: Asset
  financialResults: FinancialResult[]
  currency: CurrencyListItem | null
  onCurrencyChange: (newCurrency: CurrencyListItem) => void
}

export default function AssetFinancialsCard({
  asset,
  financialResults,
  currency,
  onCurrencyChange,
}: AssetFinancialsCardProps) {
  return (
    <FinancialsCard
      currency={currency}
      dataCurrency={asset.generalInfo?.currency}
      exportContainerProps={{
        method: 'data.exportAssetFinancials',
        params: {
          id: asset.id,
        },
      }}
      financialResults={financialResults}
      onCurrencyChange={onCurrencyChange}
      showBeta={asset.generalInfo?.ownership === AssetOwnershipType.Listed}
    />
  )
}
