import Tooltip from '@gain/components/tooltip'
import type { AssetListItem } from '@gain/rpc/app-model'
import { styled } from '@mui/material/styles'
import Switch, { switchClasses } from '@mui/material/Switch'
import React, { useMemo } from 'react'

import ConditionalWrapper from '../../common/conditional-wrapper'
import {
  createVirtualTableColumns,
  VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH,
} from '../../common/virtual-table'
import AssetContentLink from '../asset/asset-content-link'
import AssetFte from '../asset/asset-fte'
import { PercentageTableCell } from '../asset/asset-list-item-table/table-cell/percentage-table-cell'
import FinancialValue from '../financial/financial-value'

const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'isMissingData',
})<{ isMissingData: boolean }>(({ theme, isMissingData }) => ({
  // Ensure enough specificity to override the background color
  [`& .${switchClasses.checked} + .${switchClasses.track}`]: isMissingData && {
    backgroundColor: theme.palette.grey[300],
  },
}))

export default function useBenchmarkingColumns(
  selectedAssetIds: number[],
  missingDataAssetIds: number[],
  onSelectAssetId: (assetId: number, isChecked: boolean) => void
) {
  return useMemo(() => {
    return createVirtualTableColumns<AssetListItem>(
      {
        field: 'name',
        headerName: 'Company name',
        width: 320 - VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH,
        flex: 1,
        align: 'left',
        sticky: true,
        defaultSortDirection: 'asc',
        renderCell: ({ row }) => (
          <AssetContentLink
            asset={row}
            id={row.id}
            logoFileUrl={row.logoFileUrl}
            name={row.name}
          />
        ),
      },
      {
        headerName: 'Business description',
        field: 'description',
        width: 240,
        sortable: false,
      },
      {
        field: 'fte',
        headerName: 'FTEs',
        align: 'right',
        width: 144,
        renderCell: ({ row }) => (
          <AssetFte
            fte={row.fte}
            range={row.fteRange}
            year={row.fteYear}
          />
        ),
      },
      {
        field: 'revenueEur',
        headerName: 'Revenue',
        align: 'right',
        width: 130,
        renderCell: ({ value, row }) => (
          <FinancialValue
            amount={value}
            year={row.revenueYear}
          />
        ),
      },
      {
        field: 'ebitdaPctRevenue',
        headerName: 'EBITDA %',
        width: 140,
        align: 'right',
        renderCell: ({ row }) => (
          <PercentageTableCell
            percentage={row.ebitdaPctRevenue}
            years={row.ebitdaPctRevenueYears}
            enableNotMeaningful
          />
        ),
      },
      {
        field: 'id',
        headerName: 'Include',
        width: 120,
        align: 'center',
        renderCell: ({ row }) => {
          return (
            <ConditionalWrapper
              condition={missingDataAssetIds.includes(row.id)}
              wrapper={(children) => (
                <Tooltip
                  placement={'top'}
                  title={'Insufficient data to plot company with selected axes'}>
                  <span>{children}</span>
                </Tooltip>
              )}>
              <StyledSwitch
                checked={selectedAssetIds.includes(row.id)}
                color={'success'}
                isMissingData={missingDataAssetIds.includes(row.id)}
                onChange={(_, checked) => {
                  onSelectAssetId(row.id, checked)
                }}
                size={'medium'}
              />
            </ConditionalWrapper>
          )
        },
      }
    )
  }, [missingDataAssetIds, onSelectAssetId, selectedAssetIds])
}
