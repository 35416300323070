import { Date as ApiDate, InvestorFund } from '@gain/rpc/app-model'
import compareAsc from 'date-fns/compareAsc'
import React, { useCallback, useState } from 'react'

import VirtualTable, { VirtualSort, VirtualTableProps } from '../../../common/virtual-table'
import { investorFundTableColumns } from './investor-fund-table-columns'

type InvestorFundTableProps = Omit<
  VirtualTableProps<InvestorFund>,
  'columns' | 'RowComponentProps' | 'sort' | 'onSort'
>

const createSortFn =
  (funds: InvestorFund[], direction: 'asc' | 'desc') =>
  (compare: (a: InvestorFund, b: InvestorFund) => number) =>
    funds.slice().sort((a, b) => (direction === 'asc' ? compare(a, b) : compare(b, a)))

const formatYear = (year: number) => (year < 100 ? year + 2000 : year)
const toDate = (date: ApiDate | null): Date | null =>
  date !== null ? new Date(formatYear(date.year), date.month || 0, date.day || 1) : null

function sortFunds(funds: InvestorFund[], sort: VirtualSort<InvestorFund>): InvestorFund[] {
  const sortFn = createSortFn(funds, sort.direction)
  switch (sort.field) {
    case 'name':
      return sortFn((a, b) => a.name.localeCompare(b.name))
    case 'fundSizeEur':
      return sortFn((a, b) => (a.fundSize || 0) - (b.fundSize || 0))
    case 'vintageDate':
      return sortFn(({ vintageDate: aDate }, { vintageDate: bDate }) => {
        const a = toDate(aDate)
        const b = toDate(bDate)
        if (a !== null && b === null) {
          return 1
        }
        if (a === null && b !== null) {
          return -1
        }
        if (a === null || b === null) {
          return -1
        }
        return compareAsc(a, b)
      })
    default:
      return funds
  }
}

export function InvestorFundTable({ rows, ...props }: InvestorFundTableProps) {
  const [sort, setSort] = useState<VirtualSort<InvestorFund>[]>([
    {
      field: 'vintageDate',
      direction: 'desc',
    },
  ])

  const [sortedFunds, setSortedFunds] = useState(sortFunds(rows, sort[0]))

  const onSort = useCallback(
    (nextSort: VirtualSort<InvestorFund>[]) => {
      const [first] = nextSort
      setSortedFunds(sortFunds(rows, first))
      setSort(nextSort)
    },
    [setSort, setSortedFunds, rows]
  )

  return (
    <VirtualTable
      {...props}
      columns={investorFundTableColumns}
      onSort={onSort}
      RowComponentProps={{
        hover: false,
      }}
      rows={sortedFunds}
      sort={sort}
    />
  )
}
