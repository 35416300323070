import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import React, { useMemo } from 'react'
import { generatePath, useLocation } from 'react-router-dom'

import { createTabProps } from '../../common/create-tab-props'
import {
  INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../utils'

function useTabs() {
  const params = useInvestorStrategyPageParams()

  return useMemo(() => {
    return [
      {
        label: 'Portfolio',
        path: generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params),
        value: 'assets',
      },
      {
        label: 'Funds',
        path: generatePath(INVESTOR_STRATEGY_FUNDS_PAGE_PATH, params),
        value: 'funds',
      },
      {
        label: 'Analysis',
        path: generatePath(INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH, params),
        value: 'analysis',
      },
      {
        label: 'Benchmarking',
        path: generatePath(INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH, params),
        value: 'benchmarking',
      },
    ]
  }, [params])
}

const StyledTabs = styled(Tabs)({
  [`& .${tabsClasses.indicator}`]: {
    transition: 'none',
  },
})

export default function InvestorStrategyTabs() {
  const tabs = useTabs()
  const location = useLocation()
  const currentTab = useMemo(() => location.pathname.split('/').pop(), [location])

  return (
    <StyledTabs
      value={currentTab}
      variant={'scrollable'}>
      {tabs.map(({ label, path, value }, i) => (
        <Tab
          label={label}
          {...createTabProps(i, path, value)}
        />
      ))}
    </StyledTabs>
  )
}
