import { AnnualReport } from '@gain/rpc/app-model'
import compareDesc from 'date-fns/compareDesc'
import parseJSON from 'date-fns/parseJSON'

export function getLastAnnualReport(annualReports: AnnualReport[]) {
  return (
    annualReports
      .slice()
      .sort((a, b) => compareDesc(parseJSON(a.bookYearEnd), parseJSON(b.bookYearEnd)))
      .pop() || null
  )
}
