import { AssetListItem } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'

import { Axis, AxisConfig } from './axis-config'

/**
 * Configuration for a linear axis.
 */
export interface AxisLinearConfig extends Omit<AxisConfig, 'scaleMin' | 'scaleMax'> {
  scaleMin?: number // Optional for linear scale; defaults to the minimum value
  scaleMax?: number // Optional for linear scale; defaults to the maximum value
  splitNumber: number
  assets: AssetListItem[]
  interval?: number
}

/**
 * Configuration for a linear axis.
 */
export function axisLinearConfig({
  id,
  scaleMin,
  scaleMax,
  splitNumber,
  getValue,
  assets,
  formatLabel,
  ...props
}: Omit<AxisLinearConfig, 'scaleType' | 'generateLabels' | 'calculateAxisValue'>): AxisConfig {
  const values = assets.map(getValue).filter((v) => v !== null) as number[]

  let max = scaleMax ?? NaN
  if (isNaN(max)) {
    max = Math.ceil(Math.max(...values))
  }

  let min = scaleMin ?? NaN
  if (isNaN(min)) {
    min = Math.floor(Math.min(...values))
  }

  // Avoid fractional split numbers
  if (max - min < splitNumber) {
    splitNumber = max - min
  }

  // Calculate the split interval. While Echarts can calculate this automatically,
  // we want to ensure that the splits are always the same size.
  //
  // I.e. not this `|-|---|---|--|` but always `|---|---|---|---|`
  const splitResult = calculateSplits(
    min,
    max,
    splitNumber,
    isDefined(scaleMin),
    isDefined(scaleMax)
  )
  min = splitResult.min
  max = splitResult.max

  return {
    id,
    scaleType: 'value',
    calculateAxisValue: (asset: AssetListItem) => {
      const value = getValue(asset)
      if (value === null) {
        return null
      }
      return clamp(value, min, max)
    },
    generateLabels: (axis: Axis) =>
      generateLinearLabelsOptions(axis, min, max, splitResult.actualSplitNumber, formatLabel),
    getValue,
    scaleMin: min,
    scaleMax: max,
    interval: splitResult.interval,
    splitNumber: splitResult.actualSplitNumber,
    ...props,
  }
}

/**
 * Generates EChart label configuration for a linear axis.
 */
function generateLinearLabelsOptions(
  axis: Axis,
  scaleMin: number,
  scaleMax: number,
  splitNumber: number,
  formatLabel?: AxisConfig['formatLabel']
): CartesianAxisOption {
  const defaultFormatLabel = (value: number) => value.toString()

  return {
    splitLine: { show: true },
    axisTick: { show: false },
    axisLine: { show: false },
    type: 'value',
    min: scaleMin,
    max: scaleMax,
    splitNumber,
    axisLabel: {
      show: true,
      verticalAlignMaxLabel: axis === Axis.Y ? 'top' : undefined,
      verticalAlignMinLabel: axis === Axis.Y ? 'bottom' : undefined,
      alignMaxLabel: axis === Axis.X ? 'right' : undefined,
      alignMinLabel: axis === Axis.X ? 'left' : undefined,
      formatter: formatLabel ?? defaultFormatLabel,
    },
    position: axis === Axis.X ? 'bottom' : 'left',
  }
}

/**
 * Clamps a value between a defined minimum bound and a maximum bound.
 */
export function clamp(value: number, minValue: number, maxValue: number): number {
  if (value < minValue) {
    return minValue
  }

  if (value > maxValue) {
    return maxValue
  }

  return value
}

interface SplitResult {
  min: number
  max: number
  actualSplitNumber: number
  interval?: number // This split size is missing if both min and max are forced
}

/**
 * This function calculates the best minimum, maximum, and step size to split a
 * data range into a desired number of intervals.
 *
 * It considers a predefined set of reasonable split sizes and adjusts the range
 * to be multiples of these sizes. You can force the minimum and/or maximum values
 * by setting the forceMin and forceMax parameters to true.
 *
 * Be aware that the function may return a split size that results in fewer
 * intervals than the targetSplitNumber if the data range is too small.
 */
export function calculateSplits(
  min: number,
  max: number,
  targetSplitNumber = 8,
  forceMin?: boolean,
  forceMax?: boolean
): SplitResult {
  // Define reasonable split sizes to choose from
  const splitSizes = [
    1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 10_000, 100_000, 1_000_000, 10_000_000,
  ]

  // Define default result
  let result: SplitResult = { min, max, actualSplitNumber: targetSplitNumber }

  // If both min and max are forced, no need to calculate anything
  if (forceMin && forceMax) {
    return result
  }

  // Find the split that is closest to the targetSplitNumber
  let lowestSplitNumberDiff = Infinity
  for (const splitSize of splitSizes) {
    // Round min and max values to be multiples of the split size
    const roundedMin = forceMin ? min : Math.round(min / splitSize) * splitSize
    const roundedMax = forceMax ? max : Math.round(max / splitSize) * splitSize

    // Calculate the number of splits for this size
    const actualSplitNumber = Math.round((roundedMax - roundedMin) / splitSize)

    // Calculate the difference between the actual and target split number
    const splitNumberDiff = Math.abs(targetSplitNumber - actualSplitNumber)

    // Update result if this is the split size that's closest to the target
    if (splitNumberDiff < lowestSplitNumberDiff) {
      result = {
        min: roundedMin,
        max: roundedMax,
        interval: splitSize,
        actualSplitNumber: actualSplitNumber,
      }
      lowestSplitNumberDiff = splitNumberDiff
    }
  }

  return result
}
