import { ConferenceIcon } from '@gain/components/icons'
import { ConferenceEditionListItem } from '@gain/rpc/app-model'

import ContentLink from '../../../common/content-link'
import { generateConferencePagePath } from '../../../routes/conference/conference-path'
import ConferenceEditionTooltip from '../conference-edition-tooltip'

export interface ContentLinkIndustryProps {
  conferenceId: number
  conferenceName: string
  logoFileUrl?: string | null
  conferenceEdition?: ConferenceEditionListItem
}

export default function ConferenceEditionContentLink({
  conferenceId,
  conferenceName,
  logoFileUrl,
  conferenceEdition,
}: ContentLinkIndustryProps) {
  return (
    <ConferenceEditionTooltip
      conferenceEdition={conferenceEdition}
      id={conferenceId}>
      <ContentLink
        avatarProps={{
          src: logoFileUrl || undefined,
          children: !logoFileUrl && <ConferenceIcon />,
        }}
        href={generateConferencePagePath({
          conferenceId: conferenceId.toString(),
          conferenceName,
        })}
        label={conferenceName}
      />
    </ConferenceEditionTooltip>
  )
}
