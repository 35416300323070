import Stack from '@mui/material/Stack'
import subMonths from 'date-fns/subMonths'
import subYears from 'date-fns/subYears'

import { ChartLegendChip } from '../../../../common/chart/chart-legend'

export type TimeRangeKey = '3M' | '6M' | '1Y' | '3Y' | '5Y'

const timeRanges: Record<
  TimeRangeKey,
  {
    label: string
    dateFromNow: () => Date
  }
> = {
  '3M': {
    label: '3M',
    dateFromNow: () => subMonths(new Date(), 3),
  },
  '6M': {
    label: '6M',
    dateFromNow: () => subMonths(new Date(), 6),
  },
  '1Y': {
    label: '1Y',
    dateFromNow: () => subYears(new Date(), 1),
  },
  '3Y': {
    label: '3Y',
    dateFromNow: () => subYears(new Date(), 3),
  },
  '5Y': {
    label: '5Y',
    dateFromNow: () => subYears(new Date(), 5),
  },
}

export function getDateForTimeRange(key: TimeRangeKey) {
  return timeRanges[key].dateFromNow()
}

interface TimeRangePickerProps {
  value: TimeRangeKey
  onChange: (value: TimeRangeKey) => void
}

export default function TimeRangePicker({ value, onChange }: TimeRangePickerProps) {
  return (
    <Stack
      direction={'row'}
      gap={1}>
      {Object.entries(timeRanges).map(([key, config]) => (
        <ChartLegendChip
          key={key}
          active={value === (key as TimeRangeKey)}
          label={config.label}
          onClick={() => onChange(key as TimeRangeKey)}
        />
      ))}
    </Stack>
  )
}
