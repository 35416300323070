import OverflowContainer from '@gain/components/overflow-container'
import { DealAdvisorAsset } from '@gain/rpc/app-model'
import React from 'react'

import AssetContentLink from '../asset/asset-content-link'

interface DealAdvisorAssetsProps {
  assets: DealAdvisorAsset[]
}

export default function DealAdvisorAssets({ assets }: DealAdvisorAssetsProps) {
  return (
    <OverflowContainer
      chipSize={'medium'}
      lineHeight={24}
      maxChildren={6}
      maxLines={1}
      disableObserver>
      {assets.map((asset) => (
        <AssetContentLink
          id={asset.id}
          logoFileUrl={asset.logoFileUrl}
          name={asset.name}
          variant={'logo'}
        />
      ))}
    </OverflowContainer>
  )
}
