import { useAccountProjectCode } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import {
  ActivityIcon,
  FolderIcon,
  LogOutIcon,
  MailIcon,
  UserIcon,
  UsersIcon,
} from '@gain/components/icons'
import { AuthContext } from '@gain/modules/auth'
import { useProjectCode, useUserProfileContext } from '@gain/modules/user'
import { UserListItem } from '@gain/rpc/app-model'
import { isAdministrator, isGainProUser } from '@gain/utils/user'
import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useContext } from 'react'
import { generatePath, Link } from 'react-router-dom'

import useLogout from '../../features/auth/use-logout'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../features/filter/filter-bar'
import {
  generateUserSessionsPath,
  SETTINGS_ACCOUNT_PATH,
  SETTINGS_EMAIL_PATH,
  SETTINGS_TEAM_MEMBERS_PATH,
} from '../../routes/utils'
import { ResetBadgeMenuItem } from './reset-badge-menu-item'

const StyledMenuDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1),
}))

export type SettingsMenuProps = MenuProps

export function SettingsMenu(props: SettingsMenuProps) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const profile = useUserProfileContext()
  const projectCode = useProjectCode()
  const rpcClient = useRpcClient()
  const currentProjectCode = useAccountProjectCode()
  const authContext = useContext(AuthContext)
  const logout = useLogout(authContext, rpcClient, true)

  // Generate path to the team members page showing only active users
  const generateTeamMembersPath = () => {
    const teamMembersPath = generatePath(SETTINGS_TEAM_MEMBERS_PATH)
    const activeUsersQueryParams = filterModelToQueryString<UserListItem, 'status'>([
      filterValueGroup(filterValueItem('status', ['active'])),
    ])
    return `${teamMembersPath}?${activeUsersQueryParams}`
  }

  return (
    <Menu
      id={'settings-menu'}
      {...props}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      keepMounted>
      {profile.featureSessionTracking && (
        <MenuItem onClick={projectCode.openProjectCodeDialog}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={`Project: ${currentProjectCode.data || '-'}`} />
        </MenuItem>
      )}

      {profile.featureSessionTracking && <StyledMenuDivider />}

      <MenuItem
        component={Link}
        to={SETTINGS_ACCOUNT_PATH}>
        <ListItemIcon>
          <UserIcon />
        </ListItemIcon>
        <ListItemText primary={'My account'} />
      </MenuItem>
      <MenuItem
        component={Link}
        to={SETTINGS_EMAIL_PATH}>
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary={'Email preferences'} />
      </MenuItem>

      {(isAdministrator(profile.role) || profile.featureSessionTracking) && !isXs && (
        <StyledMenuDivider />
      )}

      {isAdministrator(profile.role) && !isXs && (
        <MenuItem
          component={Link}
          to={generateTeamMembersPath()}>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          <ListItemText primary={'Manage team members'} />
        </MenuItem>
      )}

      {isAdministrator(profile.role) && profile.featureSessionTracking && (
        <MenuItem
          component={Link}
          to={generateUserSessionsPath()}>
          <ListItemIcon>
            <ActivityIcon />
          </ListItemIcon>
          <ListItemText primary={'User sessions'} />
        </MenuItem>
      )}

      {!isXs && <StyledMenuDivider />}

      <ResetBadgeMenuItem />

      {(isXs || isGainProUser(profile)) && !isXs && <StyledMenuDivider />}

      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogOutIcon />
        </ListItemIcon>
        <ListItemText primary={'Log out'} />
      </MenuItem>
    </Menu>
  )
}
