export enum ManagerPosition {
  CFO = 'cfo',
  CEO = 'ceo',
  CO_CEO = 'co-ceo',
  MANAGING_DIRECTOR = 'managing director',
  MD = 'md',
  DIRECTOR = 'director',
  CHAIRMAN = 'chairman',
  BOARD_MEMBER = 'Board member',
  REPRESENTATIVE = 'Representative',
}
