import { Components } from '@mui/material/styles'

export const MuiBackdrop = (): Components['MuiBackdrop'] => ({
  styleOverrides: {
    root: {
      background: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(4px)',
    },
    invisible: {
      background: 'transparent',
      backdropFilter: 'none',
    },
  },
})
