import { ChangeEvent, useCallback } from 'react'

import { SelectionChangeEvent } from './virtual-table-model'

export const useHandleToggleSelection = (
  selectionModel: number[],
  max: number,
  onSelectionChange: (event: SelectionChangeEvent) => void
) =>
  useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const indices = Array.from({ length: max }).map((_, index) => index)

      if (checked) {
        const selected = indices.filter((index) => !selectionModel.includes(index))
        onSelectionChange({ selected })
      } else {
        const deselected = indices.filter((index) => selectionModel.includes(index))
        onSelectionChange({ deselected })
      }
    },
    [selectionModel, max, onSelectionChange]
  )
