import { isAcc, isExp, isLocal, isTest } from '@gain/utils/environment'

function getApiURL() {
  switch (true) {
    case isLocal():
      return 'http://localhost:8080'

    case isExp():
      return 'https://exp.gain.pro'

    case isTest():
      return 'https://test.gain.pro'

    case isAcc():
      return 'https://acc.gain.pro'

    default:
      return 'https://gain.pro'
  }
}

export const apiDomain = getApiURL()
export const baseUrl = apiDomain

export const RpcEndpoint = {
  APP: `${apiDomain}/app/rpc/`,
  CMS: `${apiDomain}/cms/rpc/`
}
