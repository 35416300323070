import { RefObject, useCallback, useEffect, useState } from 'react'

import { useResizeObserver } from './use-resize-observer.hook'

/**
 * TODO:: Add support for breakpoints? To only recall setWidth (or any other state update) when the breakpoint
 * changes, this will improve performance when many cards (as example) are renderd on the page that use this
 */
export function useElementWidth<Element extends HTMLElement>(ref: RefObject<Element | null>) {
  const [width, setWidth] = useState(ref?.current?.getBoundingClientRect().width || 0)

  const handleWidthChange = useCallback(() => {
    const currentWidth = ref.current?.getBoundingClientRect().width || 0
    setWidth((prevWidth) => (prevWidth !== currentWidth ? currentWidth : prevWidth))
  }, [setWidth, ref])

  useEffect(() => {
    if (ref.current) {
      handleWidthChange()
    }
  }, [handleWidthChange, ref])

  useResizeObserver(ref, handleWidthChange)

  return width
}
