import { InfoIcon } from '@gain/components/icons'
import { useDialogState } from '@gain/utils/dialog'
import Chip, { chipClasses } from '@mui/material/Chip'
import { ChipProps } from '@mui/material/Chip/Chip'
import IconButton from '@mui/material/IconButton'
import { styled, SxProps } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'
import React, { MouseEvent, useCallback } from 'react'

import { AlertDialog } from '../dialog'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 2,
  color: theme.palette.grey[600],

  [`& .${svgIconClasses.root}`]: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}))

const StyledChip = styled(Chip)({
  '& svg': {
    order: 1,
    [`&.${chipClasses.icon}`]: {
      marginLeft: -4, // The default style also uses negative margin
      marginRight: 4,
    },
  },
})

export interface InfoButtonProps {
  dialogTitle: string
  dialogMessage: string
  sx?: SxProps

  // When label is provided, the InfoButton will be displayed as a Chip.
  label?: string
  color?: ChipProps['color']
  size?: ChipProps['size']
}

export default function InfoButton({
  dialogTitle,
  dialogMessage,
  label,
  sx,
  color,
  size = 'small',
}: InfoButtonProps) {
  const [infoDialogOpen, handleOpenInfoDialog, handleCloseInfoDialog] = useDialogState()

  const handleOpenRatingInfoDialog = useCallback(
    (event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      handleOpenInfoDialog()
    },
    [handleOpenInfoDialog]
  )

  return (
    <>
      {label ? (
        <StyledChip
          color={color}
          icon={<InfoIcon />}
          label={label}
          onClick={handleOpenRatingInfoDialog}
          size={size}
          sx={sx}
        />
      ) : (
        <StyledIconButton
          onClick={handleOpenRatingInfoDialog}
          sx={sx}>
          <InfoIcon fontSize={'inherit'} />
        </StyledIconButton>
      )}

      <AlertDialog
        message={dialogMessage}
        onClose={handleCloseInfoDialog}
        open={infoDialogOpen}
        title={dialogTitle}
      />
    </>
  )
}
