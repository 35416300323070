import { ChevronRightIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { isDefined } from '@gain/utils/common'
import { styled } from '@mui/material/styles'
import React, { ReactElement, ReactNode } from 'react'

import InfoButton from '../../info-button'
import useCardContext from './use-card-context'

export interface CardHeaderProps {
  title?: string | ReactElement
  endAdornment?: ReactNode
  explainer?: string
  actions?: ReactElement | false
  className?: string
  absolute?: boolean
}

const StyledActionsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledCardHeader = styled('header', {
  shouldForwardProp: (prop) => prop !== 'absolute',
})<{ absolute?: boolean }>(({ theme, absolute }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 56,
  padding: theme.spacing(2, 3, 1, 3),

  ...(absolute && {
    position: 'absolute',
  }),
}))

const StyledTitleContainer = styled('div')({
  padding: '6px 0',
  display: 'inline-flex',
  alignItems: 'center',
})

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.text.secondary,
}))

export default function CardHeader({
  title,
  explainer,
  actions,
  className,
  absolute,
  endAdornment,
}: CardHeaderProps) {
  const { href } = useCardContext()

  if (!isDefined(title) && !isDefined(href)) {
    return null
  }

  return (
    <StyledCardHeader
      absolute={absolute}
      className={className}>
      <StyledTitleContainer>
        {title && typeof title !== 'string' && title}

        {title && typeof title === 'string' && (
          <Typography
            color={'text.primary'}
            variant={'h5'}
            noWrap>
            {title}
          </Typography>
        )}

        {href && <StyledChevronRightIcon strokeWidth={2} />}

        {explainer && typeof title === 'string' && (
          <InfoButton
            dialogMessage={explainer}
            dialogTitle={title}
            sx={{ ml: 0.5 }}
          />
        )}

        {endAdornment}
      </StyledTitleContainer>

      <StyledActionsContainer>{actions}</StyledActionsContainer>
    </StyledCardHeader>
  )
}
