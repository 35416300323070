import { useAssetListItems } from '@gain/api/app/hooks'
import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { useSessionStorage } from '@gain/utils/storage'
import Stack from '@mui/material/Stack'
import { isEqual, isUndefined, uniq, uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { ChartSizeTypeConfig } from '../../common/chart/chart-select'
import Loading from '../../common/loading'
import matchesSearchQuery from '../../common/search/matches-search-query'
import { AxisConfig } from '../chart/company-bubble-echart/axis/axis-config'
import BenchmarkingChart from './benchmarking-chart'
import BenchmarkingTable from './benchmarking-table'
import useSortedAssets from './use-sorted-assets'
import useVisibleAssets from './use-visible-assets'

interface BenchmarkingProps {
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorProfileStrategy[]

  // Object type & id are used to store the page settings in session storage
  // under a unique key.
  objectType: string
  objectId: number

  // Initial assets are shown as the default assets in the table. The first
  // DEFAULT_SELECT_ASSETS are automatically selected; the rest can be selected
  // manually.
  initialAssets: AssetListItem[]
  loadingInitialAssets: boolean
}

export default function Benchmarking({
  objectType,
  objectId,
  initialAssets,
  loadingInitialAssets,
  industrySegments,
  investorStrategies,
}: BenchmarkingProps) {
  const storageKey = `BENCHMARKING-${objectType}-${objectId}`
  const [addedAssetIds, setAddedAssetIds] = useSessionStorage<number[]>(
    `${storageKey}-ADDED-ASSET-IDS`,
    []
  )
  const [selectedAssetIds, setSelectedAssetIds] = useSessionStorage<number[]>(
    `${storageKey}-SELECTED-ASSET-IDS`,
    []
  )
  const [missingDataAssetIds, setMissingDataAssetIds] = useState<number[]>([])
  const [searchQuery, setSearchQuery] = useState<string | null>(null)

  // Select the assets that are fetched by being added manually. We want to avoid
  // a flicker when the assets are added to the table which is where the
  // "keepPreviousData" option comes in.
  const swrAddedAssets = useAssetListItems(addedAssetIds, {
    keepPreviousData: addedAssetIds.length > 0,
  })
  const addedAssets = swrAddedAssets.data.items

  // Update the selected asset ids when the user selects or deselects an asset.
  const handleSelectAssetId = useCallback(
    (assetId: number, isSelected: boolean) => {
      let newSelectedAssetIds: number[]
      if (isSelected) {
        newSelectedAssetIds = uniq([...selectedAssetIds, assetId])
      } else {
        newSelectedAssetIds = selectedAssetIds.filter((id) => id !== assetId)
      }
      setSelectedAssetIds(newSelectedAssetIds)
    },
    [selectedAssetIds, setSelectedAssetIds]
  )

  // Add assets to the selected assets list and the added assets list when
  // someone saves the dialog.
  const handleAddAssets = useCallback(
    async (newAssetIds: number[]) => {
      setAddedAssetIds(uniq([...newAssetIds, ...addedAssetIds]))
      setSelectedAssetIds(uniq([...newAssetIds, ...selectedAssetIds]))
    },
    [addedAssetIds, selectedAssetIds, setAddedAssetIds, setSelectedAssetIds]
  )

  // Combine the assets from the backend with the initial assets. Filter out any
  // assets that don't match the search query.
  const allAssets = useMemo(() => {
    return uniqBy([...(addedAssets ?? []), ...initialAssets], 'id')
  }, [addedAssets, initialAssets])

  // Determine if either initial assets or manually added assets are still loading.
  // When loading is finished we automatically select the assets (if not already
  // selected in session storage).
  const isLoading = loadingInitialAssets || swrAddedAssets.loading
  const prevIsLoading = usePrevious(isLoading)
  useEffect(() => {
    const wasLoading = isUndefined(prevIsLoading) || prevIsLoading
    if (!isLoading && wasLoading && selectedAssetIds.length === 0) {
      setSelectedAssetIds(allAssets.map(({ id }) => id))
    }
  }, [
    allAssets,
    isLoading,
    prevIsLoading,
    selectedAssetIds.length,
    setSelectedAssetIds,
    addedAssets,
  ])

  // Gather all assets; prioritize those that came from the backend.
  // The assets are ordered by the order of the assetIds array and the initial
  // assets are appended at the end.
  const [sortedAssets, sort, onSort] = useSortedAssets(allAssets, selectedAssetIds, addedAssetIds)

  // Only assets with datapoints for the X/Y axis and a size are plotted in
  // the chart. Assets that can't be plotted are marked as missing in the table.
  const handleChangeSettings = useCallback(
    (xAxis: AxisConfig, yAxis: AxisConfig, sizeType: ChartSizeTypeConfig<AssetListItem>) => {
      const assetsMissingData = sortedAssets.filter(
        (asset) =>
          xAxis.getValue(asset) === null ||
          yAxis.getValue(asset) === null ||
          sizeType.getValue(asset) === null
      )
      setMissingDataAssetIds(assetsMissingData.map(({ id }) => id))
    },
    [sortedAssets]
  )

  // Resets to the initial state.
  const handleReset = useCallback(() => {
    setSelectedAssetIds(initialAssets.map(({ id }) => id))
    setAddedAssetIds([])
  }, [initialAssets, setAddedAssetIds, setSelectedAssetIds])

  // Reset the state when the initial assets change. This happens when the user
  // changes filters of a filtered bookmark list.
  const prevInitialAssets = usePrevious(initialAssets.map((asset) => asset.id).sort())
  useEffect(() => {
    if (
      prevInitialAssets &&
      !isEqual(initialAssets.map((asset) => asset.id).sort(), prevInitialAssets)
    ) {
      handleReset()
    }
  }, [handleReset, initialAssets, prevInitialAssets])

  // Disables the reset button when there are no changes. The order of the
  // selected assets can change when toggled on/off, so we sort both arrays
  // before comparing them.
  const hasChanges =
    !isEqual([...selectedAssetIds].sort(), allAssets.map(({ id }) => id).sort()) ||
    addedAssetIds.length > 0

  // Filter the assets based on the search query.
  const filteredAssets = useMemo(
    () =>
      sortedAssets.filter((asset) => !searchQuery || matchesSearchQuery(asset.name, searchQuery)),
    [sortedAssets, searchQuery]
  )

  // Only show assets that are selected. In addition, only pass assets that have
  // data for the X/Y axis and size to prevent them from affecting the axis calculations.
  const plottableAssets = useVisibleAssets(sortedAssets, selectedAssetIds, missingDataAssetIds)

  // Only show a loader if the initial assets are still loading. When adding
  // assets manually there's no need to show the loader.
  if (loadingInitialAssets) {
    return <Loading />
  }

  return (
    <Stack
      gap={2}
      mb={2}
      width={'100%'}>
      <BenchmarkingChart
        assets={plottableAssets}
        industrySegments={industrySegments}
        investorStrategies={investorStrategies}
        onChangeSettings={handleChangeSettings}
      />

      <BenchmarkingTable
        assets={filteredAssets}
        hasChanges={hasChanges}
        missingDataAssetIds={missingDataAssetIds}
        onAddAssets={handleAddAssets}
        onReset={handleReset}
        onSearch={setSearchQuery}
        onSelectAssetId={handleSelectAssetId}
        onSort={onSort}
        selectedAssetIds={selectedAssetIds}
        sort={sort}
      />
    </Stack>
  )
}
