import { useAssetListItems, useSimilarAssets } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import React from 'react'

import Loading from '../../../common/loading'
import Benchmarking from '../../../features/benchmarking'

interface AssetBenchmarkingProps {
  asset: Asset
}

export default function AssetBenchmarking({ asset }: AssetBenchmarkingProps) {
  const swrCurrentAsset = useAssetListItems([asset.id])
  const swrSimilarAssets = useSimilarAssets({ likeAssetId: asset.id, limit: 300 })

  if (
    swrSimilarAssets.loading ||
    swrCurrentAsset.loading ||
    !swrSimilarAssets.data ||
    !swrCurrentAsset.data
  ) {
    return <Loading />
  }

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Benchmarking</title>
      </Head>

      <Benchmarking
        initialAssets={[...swrCurrentAsset.data.items, ...swrSimilarAssets.data.items]}
        loadingInitialAssets={swrCurrentAsset.loading || swrSimilarAssets.loading}
        objectId={asset.id}
        objectType={'asset'}
      />
    </>
  )
}
