import { AssetManager } from '@gain/rpc/app-model'
import { getCeoPositionImportance, isCeo } from '@gain/utils/manager'
import { useMemo } from 'react'

export function getManagingCeoFromAssetManagers(managers: AssetManager[]) {
  return (
    managers
      .filter((manager) => isCeo(manager.position))
      .filter((manager) => manager.leaveYear === null && manager.leaveMonth === null)
      .slice()
      .sort((a, b) => {
        const aIndex = getCeoPositionImportance(a.position)
        const bIndex = getCeoPositionImportance(b.position)
        return aIndex - bIndex
      })
      .pop() || null
  )
}

export function useAssetManagingCeo(managers: AssetManager[]) {
  return useMemo(() => {
    return getManagingCeoFromAssetManagers(managers)
  }, [managers])
}
