import { useInvestorList, useInvestorStrategyList } from '@gain/api/app/hooks'
import { AssetShareholder, InvestorListItem, InvestorStrategyListItem } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'
import { useMemo } from 'react'

export interface Shareholder extends AssetShareholder {
  investor: InvestorListItem | null
  strategy: InvestorStrategyListItem | null
}

const createShareholder = (investors: InvestorListItem[], strategies: InvestorStrategyListItem[]) =>
  function (assetShareholder: AssetShareholder) {
    return {
      ...assetShareholder,
      investor: investors.find((investor) => investor.id === assetShareholder.investorId) || null,
      strategy:
        strategies.find((strategy) => strategy.strategyId === assetShareholder.strategyId) || null,
    }
  }

function useAssetShareholdersInvestors(shareholders: AssetShareholder[]) {
  return useInvestorList({
    filter: [
      listFilter(
        'id',
        '=',
        shareholders.map((shareholder) => shareholder.investorId)
      ),
    ],
  })
}

function useAssetShareholderStrategies(shareholders: AssetShareholder[]) {
  return useInvestorStrategyList({
    filter: [
      listFilter(
        'strategyId',
        '=',
        shareholders.map((shareholder) => shareholder.strategyId).filter(isTruthy)
      ),
    ],
  })
}

export function useShareholders(shareholders: AssetShareholder[]) {
  const investors = useAssetShareholdersInvestors(shareholders)
  const strategies = useAssetShareholderStrategies(shareholders)

  return useMemo(() => {
    return {
      loading: investors.loading || strategies.loading,
      error: investors.error || strategies.error,
      data: shareholders.map(createShareholder(investors.data.items, strategies.data.items)),
    }
  }, [shareholders, investors, strategies])
}
