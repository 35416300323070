import { useIsBrowserExtension } from '@gain/modules/browser-extension'
import { useIsXs } from '@gain/utils/responsive'
import React from 'react'

import { ProfileInfoAndTabsProps } from './profile-info-and-tabs-model'
import ProfileInfoAndTabsSmUp from './profile-info-and-tabs-sm-up'
import ProfileInfoAndTabsXs from './profile-info-and-tabs-xs'

export default function ProfileTabBar(props: ProfileInfoAndTabsProps) {
  const isBrowserExtension = useIsBrowserExtension()
  const isXs = useIsXs()

  if (isXs && !isBrowserExtension) {
    return <ProfileInfoAndTabsXs {...props} />
  }

  return (
    <ProfileInfoAndTabsSmUp
      allowScrollButtonsMobile={isBrowserExtension}
      {...props}
    />
  )
}
