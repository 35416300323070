import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'

export enum RouteSegmentTabs {
  Summary = 'summary',
  Assessment = 'assessment',
  Companies = 'companies',
  Analysis = 'analysis',
  Benchmarking = 'benchmarking',
  Deals = 'deals',
  Assets = 'assets',
  Advisors = 'advisors',
  SimilarAssets = 'similar-assets',
}

export const ROUTE_SEGMENT_PATH = '/segment/:id/:title'
export const ROUTE_SEGMENT_SUMMARY_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Summary}`
export const ROUTE_SEGMENT_ASSESSMENT_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Assessment}`
export const ROUTE_SEGMENT_COMPANIES_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Companies}`
export const ROUTE_SEGMENT_ANALYSIS_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Analysis}`
export const ROUTE_SEGMENT_BENCHMARKING_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Benchmarking}`
export const ROUTE_SEGMENT_DEALS_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Deals}`
export const ROUTE_SEGMENT_ADVISORS_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Advisors}`

export const ROUTE_SEGMENT_ASSETS_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.Assets}`
export const ROUTE_SEGMENT_SIMILAR_ASSETS_PATH = `${ROUTE_SEGMENT_PATH}/${RouteSegmentTabs.SimilarAssets}`

export interface RouteSegmentParams {
  id: string
  title: string
}

export function useRouteSegmentParams() {
  const params = useParams<RouteSegmentParams>()

  return useMemo(
    () => ({
      id: parseInt(params.id, 10),
      title: params.title,
    }),
    [params.id, params.title]
  )
}

export interface GenerateRouteSegmentParams {
  id: number
  title?: string | null
}

export function generateRouteSegmentPath(
  { id, title }: GenerateRouteSegmentParams,
  tab = RouteSegmentTabs.Summary
) {
  return generatePath(`${ROUTE_SEGMENT_PATH}/${tab}`, {
    id: id.toString(10),
    // React Router fails when there are parentheses in the title,
    // so we strip the parentheses until there's a proper solution.
    // - https://github.com/remix-run/react-router/issues/10546
    title: (title || '-').replace(/[()]/g, ''),
  })
}
