import { isDefined } from '@gain/utils/common'
import format from 'date-fns/format'
import setMonth from 'date-fns/setMonth'

export interface FormatMonthYearOptions {
  emptyValue?: string
  separator?: string
  hideCurrentYear?: boolean
  monthFormat?: string
}

export function formatMonthYear(
  month: number | null | undefined,
  year: number | null | undefined,
  options?: FormatMonthYearOptions
) {
  const emptyValue = options?.emptyValue ?? '-'
  const separator = options?.separator ?? ','
  const hideCurrentYear = options?.hideCurrentYear || false
  const monthFormat = options?.monthFormat || 'MMM'

  if (isDefined(month) && isDefined(year)) {
    if (hideCurrentYear && year === new Date().getFullYear()) {
      return format(new Date(year, month - 1), monthFormat)
    }
    return format(new Date(year, month - 1), `${monthFormat}${separator} yyyy`)
  }

  if (isDefined(year)) {
    return year.toString(10)
  }

  if (isDefined(month)) {
    return format(setMonth(new Date(), month - 1), monthFormat)
  }

  return emptyValue
}
