import { AnnualReport } from '@gain/rpc/app-model'
import parseJSON from 'date-fns/parseJSON'

export function getAnnualReportYear(annualReport: null): null
export function getAnnualReportYear(annualReport: AnnualReport): number
export function getAnnualReportYear(annualReport: AnnualReport | null): number | null
export function getAnnualReportYear(annualReport: AnnualReport | null): number | null {
  if (annualReport !== null) {
    return parseJSON(annualReport.bookYearEnd).getFullYear()
  }
  return null
}
