import { InfoIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { RowType } from '@gain/utils/table'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import React from 'react'

import InfoButton from '../info-button'
import { tableClasses } from './table'
import { ColumnConfig } from './table-model'

interface StyledThProps {
  width?: number | string
  textAlign?: 'left' | 'right' | 'center' | 'justify'
  paddingLeft?: number
  paddingRight?: number
  disablePaddingLeft?: boolean
}

const STYLED_TH_PROPS = ['width', 'disablePaddingLeft', 'textAlign', 'paddingLeft', 'paddingRight']

const StyledTh = styled('th', {
  shouldForwardProp: (prop) => !STYLED_TH_PROPS.includes(prop.toString()),
})<StyledThProps>(({ theme, width, paddingRight, paddingLeft, disablePaddingLeft }) => ({
  height: 38,
  boxSizing: 'border-box',

  width,

  paddingLeft: theme.spacing(paddingLeft || 1),
  paddingRight: theme.spacing(paddingRight || 1),

  backgroundColor: theme.palette.background.paper,

  ...theme.typography.body2,

  position: 'relative',

  [theme.breakpoints.only('xs')]: {
    top: 0,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },

  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-of-type': {
    paddingRight: theme.spacing(3),
  },

  '&:first-of-type': {
    paddingLeft: theme.spacing(3),
    ...(disablePaddingLeft && {
      paddingLeft: 0,
    }),
  },

  [`&.${tableClasses.stickyCell}`]: {
    position: 'sticky',
    left: 0,
    zIndex: 3,
  },
}))

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

interface TableHeaderProps<Row extends RowType> {
  className?: string
  column: ColumnConfig<Row>
  disablePaddingLeft?: boolean
}

export default function TableHeader<Row extends RowType>({
  className,
  column,
  disablePaddingLeft,
}: TableHeaderProps<Row>) {
  return (
    <StyledTh
      className={clsx(className, {
        [tableClasses.stickyCell]: column.sticky,
      })}
      colSpan={column.headerSpan}
      disablePaddingLeft={disablePaddingLeft}
      paddingLeft={column.paddingLeft}
      paddingRight={column.paddingRight}
      textAlign={column.headerAlign || column.align}
      width={column.headerSpan && column.headerSpan > 1 ? column.headerWidth : column.width}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}
        justifyContent={column.headerAlign || column.align}>
        <Typography
          color={'text.secondary'}
          variant={'inherit'}
          noWrap>
          {typeof column.renderHeader === 'function' ? column.renderHeader() : column.headerName}
        </Typography>

        {column.onInfoClick && <StyledInfoIcon onClick={column.onInfoClick} />}

        {column.headerExplainer && (
          <InfoButton
            dialogMessage={column.headerExplainer}
            dialogTitle={column.headerName || ''}
          />
        )}
      </Stack>
    </StyledTh>
  )
}
