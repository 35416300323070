import { isDefined } from '@gain/utils/common'
import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import {
  INVESTOR_PAGE_PATH,
  InvestorPageParams,
  InvestorPagePathParams,
} from './investor-page-path'

export const INVESTOR_STRATEGY_PAGE_PATH = `${INVESTOR_PAGE_PATH}/strategy/:strategyId/:strategyName?`
export const INVESTOR_STRATEGY_ASSETS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/assets`
export const INVESTOR_STRATEGY_FUNDS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/funds`
export const INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/analysis`
export const INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH = `${INVESTOR_STRATEGY_PAGE_PATH}/benchmarking`

export interface InvestorStrategyPageParams extends InvestorPagePathParams {
  strategyId: string
  strategyName?: string
}

export interface GenerateInvestorStrategyPagePathParams extends InvestorPageParams {
  strategyId: number | string
  strategyName?: string | null
}

export const useInvestorStrategyPageParams = () => {
  const params = useParams<InvestorStrategyPageParams>()
  return useMemo(
    () => ({
      investorId: parseInt(params.investorId, 10),
      investorName: decodeURIComponent(params.investorName),
      preview:
        isDefined(params.preview) && params.preview !== 'false'
          ? true
          : params.preview === 'false'
          ? false
          : undefined,
      strategyId: parseInt(params.strategyId),
      strategyName: decodeURIComponent(params.strategyName || ''),
    }),
    [params]
  )
}

export function generateInvestorStrategyPagePathParams(
  params: GenerateInvestorStrategyPagePathParams
) {
  return generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, {
    investorId: params.investorId,
    investorName: params.investorName,
    strategyId: params.strategyId,
    strategyName: params.strategyName || undefined,
  })
}
