import { LinkedInIcon, UserIcon } from '@gain/components/icons'
import { AssetManager } from '@gain/rpc/app-model'
import { formatAssetManager } from '@gain/utils/asset'
import IconButton from '@mui/material/IconButton'

import ContentLink from '../../../common/content-link'

export interface ContentLinkManagerProps {
  manager: AssetManager
}

export default function ContentLinkManager({ manager }: ContentLinkManagerProps) {
  const url = manager?.person?.linkedInUrl

  return (
    <ContentLink
      avatarProps={{
        children: <UserIcon />,
      }}
      endIcon={
        url && (
          <IconButton
            onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
            size={'small'}
            sx={{ fontSize: 18 }}>
            <LinkedInIcon />
          </IconButton>
        )
      }
      label={formatAssetManager(manager)}
      region={manager.person?.nationality}
    />
  )
}
