import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs, { tabsClasses, TabsProps } from '@mui/material/Tabs'
import React from 'react'
import { Link } from 'react-router-dom'

import { ProfileTab } from './profile-info-and-tabs-model'

interface ProfileTabsProps
  extends Omit<TabsProps, 'variant' | 'children' | 'value' | 'TabIndicatorProps'> {
  tabs?: ProfileTab[]
  activeTab?: string
  replaceUrl?: boolean
  disableIndicatorAnimation?: boolean
}

const StyledTabs = styled(Tabs)<TabsProps>({
  width: '100%',

  [`& .${tabsClasses.flexContainer}`]: {
    display: 'block',
  },
})

export default function ProfileTabs({
  tabs,
  activeTab,
  replaceUrl,
  disableIndicatorAnimation,
  ...tabsProps
}: ProfileTabsProps) {
  if (!tabs || tabs.length === 0 || !activeTab || !tabs.find((tab) => tab.value === activeTab)) {
    return null
  }

  return (
    <StyledTabs
      TabIndicatorProps={{ style: { ...(disableIndicatorAnimation && { transition: 'none' }) } }}
      value={activeTab}
      variant={'scrollable'}
      {...tabsProps}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          aria-controls={`nav-tabpanel-${index}`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          component={Link}
          id={`nav-tab-${index}`}
          label={tab.label}
          replace={replaceUrl}
          to={tab.path}
          value={tab.value}
        />
      ))}
    </StyledTabs>
  )
}
