import { ChevronRightIcon } from '@gain/components/icons'
import { useDialogState } from '@gain/utils/dialog'
import Alert, { alertClasses } from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React from 'react'

import { Link } from '../../common/link'
import BenchmarkingMovedInfoDialog from './benchmarking-moved-info-dialog'

const StyledLink = styled(Link)({
  fontWeight: 'strong',
})

const StyledRightButton = styled(Button)(({ theme }) => ({
  fontSize: 'inherit',
  flexShrink: 0, // Otherwise it would cause an overflow
  textWrap: 'nowrap',
  marginLeft: theme.spacing(2),
}))

const StyledAlert = styled(Alert)(({ theme }) => ({
  padding: theme.spacing(0.5, 1, 0.5, 2),
  [`& .${alertClasses.message}`]: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}))

interface BenchmarkingMovedBarProps {
  benchmarkingUrl: string
}

/**
 * NOTE: this is a temporary component and will be removed after a couple of
 * weeks. The code is just a copy+paste of various pieces without effort to
 * clean them up.
 */
export default function BenchmarkingMovedBar({ benchmarkingUrl }: BenchmarkingMovedBarProps) {
  const [dialogOpen, handleOpenDialog, handleCloseDialog] = useDialogState()

  return (
    <>
      <BenchmarkingMovedInfoDialog
        benchmarkingUrl={benchmarkingUrl}
        onClose={handleCloseDialog}
        open={dialogOpen}
      />

      <StyledAlert
        icon={false}
        severity={'info'}>
        <div>
          The charts below are now also available in the new{' '}
          <StyledLink
            sx={{ fontWeight: 'bold' }}
            to={benchmarkingUrl}
            disableStyle>
            Benchmarking tab
          </StyledLink>
          , and will be removed here in the coming weeks.
        </div>

        <StyledRightButton
          color={'info'}
          endIcon={<ChevronRightIcon color={'inherit'} />}
          onClick={handleOpenDialog}>
          Learn more
        </StyledRightButton>
      </StyledAlert>
    </>
  )
}
