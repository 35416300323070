import {
  AnnualReport as AppAnnualReport,
  AnnualReportFile as AppAnnualReportFile,
} from '@gain/rpc/app-model'
import {
  AnnualReport as CMSAnnualReport,
  AnnualReportFile as CMSAnnualReportFile,
} from '@gain/rpc/cms-model'
import parseISO from 'date-fns/parseISO'
import { useMemo } from 'react'

import { formatAnnualReportType } from './format-annual-report-type'

/**
 * Given an annual report, this function attempts to find the best name
 * to describe this AR.
 */
export function getAnnualReportName(ar: AppAnnualReport | CMSAnnualReport): string {
  return [
    // Name of the report
    ar.type || ar.description || 'Annual report',
    // Book year
    ar.bookYearEnd != null && parseISO(ar.bookYearEnd).getFullYear(),
    // Filter null values, join with a dash
  ]
    .filter(Boolean)
    .join(' ')
}

/**
 * Returns the customer-friendly display name of an AnnualReport / AnnualReportFile
 * pair. This display name should be used in all customer facing UI in the app.
 */
export function formatAnnualReportDisplayName(
  ar: AppAnnualReport | CMSAnnualReport,
  arFile: AppAnnualReportFile | CMSAnnualReportFile
): string {
  const arType =
    arFile.type != null && arFile.type.trim().length > 0
      ? formatAnnualReportType(arFile.type)
      : null

  return (
    [
      // List all (optional) parts of the filename
      'Annual Report',
      ar.bookYearEnd != null && parseISO(ar.bookYearEnd).getFullYear(),
      arType != null && '-',
      arType,
    ]
      // Filter null values, join all values into a string
      .filter(Boolean)
      .join(' ')
  )
}

export interface AnnualReportFileEntry {
  report: AppAnnualReport | CMSAnnualReport
  arFile: AppAnnualReportFile | CMSAnnualReportFile
  reportIndex: number
}

/**
 * This hook takes an array of annual reports and transforms it
 * into an array of AnnualReport / AnnualReportFile pairs, ready to
 * be used in lists or tables for AR files.
 */
export function useAnnualReportFileEntries(
  annualReports: AppAnnualReport[] | CMSAnnualReport[],
  publicOnly = false
): AnnualReportFileEntry[] {
  return useMemo(() => {
    if (annualReports.length === 0) {
      return []
    }

    return annualReports.flatMap((report, index) =>
      report.files
        .filter((arFile) => !publicOnly || !arFile.internal)
        .map((arFile) => ({
          report,
          arFile,
          reportIndex: index,
        }))
    )
  }, [annualReports, publicOnly])
}
