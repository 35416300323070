import { radioClasses } from '@mui/material/Radio'
import { Components, Theme } from '@mui/material/styles'
import React from 'react'

import { RadioCheckedIcon } from './radio-checked-icon/radio-checked-icon'
import { RadioIcon } from './radio-icon/radio-icon'

export const MuiRadio = (theme: Theme): Components['MuiRadio'] => ({
  defaultProps: {
    color: 'primary',
    icon: <RadioIcon />,
    checkedIcon: <RadioCheckedIcon />,
  },
  styleOverrides: {
    root: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    colorPrimary: {
      [`&.${radioClasses.checked}`]: {
        color: theme.palette.grey['900'],
      },
    },
  },
})
