import { isEqual } from 'lodash'
import { useMemo, useRef } from 'react'

function isEqualObjectIds<T extends { id: number }>(array1: T[], array2: T[]): boolean {
  // Extract the IDs from both arrays
  const ids1 = array1.map((item) => item.id)
  const ids2 = array2.map((item) => item.id)

  // Use lodash to check if the two arrays of IDs are equal
  return isEqual(ids1.sort(), ids2.sort())
}

/**
 * Returns the assets that are visible in the chart. By design this does not
 * consider group legends in the chart that can be toggled on and off.
 */
export default function useVisibleAssets<T extends { id: number }>(
  assets: T[],
  selectedAssetIds: number[],
  missingDataAssetIds: number[]
): T[] {
  const previousState = useRef<T[]>([])

  return useMemo(() => {
    const result = assets
      .filter(({ id }) => selectedAssetIds.includes(id))
      .filter(({ id }) => !missingDataAssetIds.includes(id))

    // Return the previous state if nothing changed to prevent re-renders
    if (isEqualObjectIds(result, previousState.current)) {
      return previousState.current
    }

    previousState.current = result
    return result
  }, [assets, missingDataAssetIds, selectedAssetIds])
}
