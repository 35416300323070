import { AppSwrKey, useRpcClient } from '@gain/api/swr'
import { BookmarksFilteredList, BookmarksFilteredLists } from '@gain/rpc/app-model'
import { listSort } from '@gain/rpc/utils'
import compareDesc from 'date-fns/compareDesc'
import { useCallback, useMemo } from 'react'
import { mutate } from 'swr'

import { useBookmarkFilteredLists } from './app-api-hooks'

/**
 * This updates the state of the SWR bookmarks filtered list. You have
 * the option to provide a callback function to manually modify the cache.
 * If no callback is provided, the function retrieves the latest version
 * of the list from the backend.
 */
export function useMutateBookmarksFilteredList() {
  const fetcher = useRpcClient()

  return useCallback(
    (
      listId: number,
      mutator?: (listItem: BookmarksFilteredList | undefined) => BookmarksFilteredList | undefined
    ) => {
      return mutate(
        // Mutate all listCustomAssetQueries queries regardless of params
        (key: AppSwrKey<'lists.listCustomAssetQueries'>) => {
          return key?.method === 'lists.listCustomAssetQueries'
        },
        async (lists: BookmarksFilteredLists | undefined) => {
          if (!lists) {
            return
          }
          let items = [...lists.items]
          const index = items.findIndex((item) => item.id === listId)

          // When a callback is provided update the list item
          if (mutator) {
            const updatedList = mutator(items.find((item) => item.id === listId))

            if (!updatedList) {
              // List was removed
              items.splice(index, 1)
            } else if (index >= 0) {
              // List was updated
              items[index] = updatedList
            } else {
              // List was added
              items = [updatedList, ...lists.items]
            }
          } else {
            // When no callback is provided fetch updated list from backend
            items[index] = await fetcher({
              method: 'lists.getCustomAssetQuery',
              params: { id: listId },
            })
          }

          items.sort(compareLastViewedAt)

          return { ...lists, items }
        },
        { revalidate: !mutator }
      )
    },
    [fetcher]
  )
}

function compareLastViewedAt(a: BookmarksFilteredList, b: BookmarksFilteredList) {
  return compareDesc(new Date(a.lastViewedAt ?? Date.now()), new Date(b.lastViewedAt ?? Date.now()))
}

/**
 * Always use the same sorted bookmarks filtered list request to
 * ensure the cache key remains the same.
 */
function useSortedBookmarksFilteredLists() {
  return useBookmarkFilteredLists({
    sort: [listSort('lastViewedAt', 'desc')],
  })
}

/**
 * Returns bookmarks filtered lists the user has saved.
 */
export function useSavedBookmarksFilteredLists() {
  const { loading, data } = useSortedBookmarksFilteredLists()

  const lists = useMemo(() => data.items.filter((list) => !list.isRecentlyFiltered), [data.items])

  return useMemo(() => ({ loading, lists }), [loading, lists])
}

/**
 * Returns bookmarks filtered lists marked as "Recently Filtered";
 * these lists are stored automatically while filtering and unlike
 * saved lists have no title and cannot be shared.
 */
export function useRecentlyFilteredBookmarkLists() {
  const { loading, data } = useSortedBookmarksFilteredLists()

  const lists = useMemo(() => data.items.filter((list) => list.isRecentlyFiltered), [data.items])

  return useMemo(() => ({ loading, lists }), [loading, lists])
}

/**
 * Returns a bookmarks filtered list with specified id or undefined
 * when not found.
 */
export function useBookmarksFilteredList(id: number) {
  const { data, loading } = useSortedBookmarksFilteredLists()
  const list = data?.items.find((item) => item.id === id)
  return { list, loading }
}
