import { useMemo } from 'react'

export const useCheckboxTooltip = (
  totalSize: number,
  max: number,
  isAllSelected: boolean
): string =>
  useMemo(() => {
    if (totalSize < max) {
      return ''
    }

    if (!isAllSelected) {
      return `Select first ${max}`
    }
    return `Deselect first ${max}`
  }, [totalSize, max, isAllSelected])
