import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card, { CardContent, CardHeader, CardProps } from '../../../../common/card/card'
import { Manager } from './asset-management-util'
import ListManager from './list-manager'
import TableManagerContainer from './table-manager-container'

export interface CardAssetManagementProps extends CardProps {
  managers: Manager[]
}

export default function CardAssetManagement({ managers, ...cardProps }: CardAssetManagementProps) {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Card {...cardProps}>
      <CardHeader title={'Management'} />
      {!isXs && <TableManagerContainer managers={managers} />}
      {isXs && (
        <CardContent sx={{ pt: 1, pb: 2 }}>
          <ListManager managers={managers} />
        </CardContent>
      )}
    </Card>
  )
}
