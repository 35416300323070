import { useCallback } from 'react'

import ChartSelect from '../chart-select/chart-select'
import { ChartGroupByConfig, ChartGroupValueType } from './chart-group'

interface ChartGroupBySelectProps<
  Data,
  ValueType extends ChartGroupValueType = ChartGroupValueType
> {
  options: ChartGroupByConfig<Data, ValueType>[]
  value: ChartGroupByConfig<Data, ValueType>
  onChange: (value: ChartGroupByConfig<Data, ValueType>) => void
}

export default function ChartGroupBySelect<
  Data,
  ValueType extends ChartGroupValueType = ChartGroupValueType
>({ options, value, onChange }: ChartGroupBySelectProps<Data, ValueType>) {
  const handleGetLabel = useCallback((option: ChartGroupByConfig<Data, ValueType>) => {
    return option.label
  }, [])

  return (
    <ChartSelect
      getLabel={handleGetLabel}
      label={'Group by'}
      onChange={onChange}
      options={options}
      value={value}
    />
  )
}
