import { DrawerContent } from '@app/common/drawer/drawer-content'
import { DrawerHeader } from '@app/common/drawer/drawer-header'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRef, useState } from 'react'

import { NAV_BAR_HEIGHT } from '../../layout/nav-bar'
import { BookmarksListMenu } from './bookmarks-list-menu'
import MoreUpdatesBadge from './bookmarks-list-menu/more-updates-chip'
import { BottomMenu } from './bottom-menu'
import { MenuItems } from './menu-items'
import { useMenuContext } from './menu-provider'
import useHasMenuOverflow from './use-has-menu-overflow'

export const MD_UP_DRAWER_WIDTH = 264
export const MD_UP_MINI_DRAWER_WIDTH = 65

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  zIndex: theme.zIndex.appBar - 1,
  width: open ? MD_UP_DRAWER_WIDTH : MD_UP_MINI_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.leavingScreen
      : theme.transitions.duration.enteringScreen,
  }),
}))

const StyledDrawerContent = styled(DrawerContent, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'calc(100% - 49px)',
  overflowX: 'hidden',
  '&&': {
    padding: 0,
  },
  [theme.breakpoints.up('lg')]: {
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    width: MD_UP_DRAWER_WIDTH,
  },
  width: open ? MD_UP_DRAWER_WIDTH : MD_UP_MINI_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.leavingScreen
      : theme.transitions.duration.enteringScreen,
  }),
}))

const StyledList = styled(List, { shouldForwardProp: (prop) => prop !== 'hasOverflow' })<{
  hasOverflow: boolean
}>(({ theme, hasOverflow }) => ({
  overflowY: 'auto',
  overflowX: 'hidden', // Hide horizontal scrollbar when toggling between collapsed & expanded
  overscrollBehavior: 'contain',
  padding: theme.spacing(0, hasOverflow ? 2.5 : 2, 1.5, 2),
  ...(hasOverflow && {
    maskImage:
      'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px)',
    maskSize: '100% 20000px',
    maskPosition: 'left bottom',
    transition: 'mask-position 0.3s',
    '&:hover': {
      maskPosition: 'left top',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none !important',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '12px',
      borderRadius: '10px',
      border: '2px solid transparent',
      backgroundClip: 'padding-box',
      backgroundColor: '#a0a0a099',
    },
  }),
}))

const StyledDrawerHeaderSM = styled(DrawerHeader)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
}))

export function Menu() {
  const ref = useRef(null)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const [showMenu, setShowMenu] = useMenuContext()
  const [hadMoreUpdates, setHadMoreUpdates] = useState<boolean | undefined>()
  const [showMoreUpdates, setShowMoreUpdates] = useState(false)
  const [lastUpdatedElement, setLastUpdatedElement] = useState<Element | undefined>()
  const hasOverflow = useHasMenuOverflow(ref)

  const offsetTop = isSm ? 0 : NAV_BAR_HEIGHT
  const closeMenu = () => setShowMenu(false)

  const handleLastUpdatedListIntersection = (element: Element, inView: boolean) => {
    if (hadMoreUpdates === undefined) {
      setHadMoreUpdates(!inView) // If not in view there are updates below the fold
      if (!inView) {
        setLastUpdatedElement(element)
        setShowMoreUpdates(true)
      }
    }

    if (inView && showMoreUpdates) {
      setShowMoreUpdates(false)
    }
  }

  const handleMoreUpdatesClick = () => {
    if (lastUpdatedElement) {
      lastUpdatedElement.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
    setShowMoreUpdates(false)
  }

  if (isSm) {
    return (
      <Drawer
        anchor={'left'}
        onClose={closeMenu}
        open={showMenu}
        PaperProps={{
          style: {
            display: 'block',
          },
        }}
        keepMounted>
        <StyledDrawerHeaderSM
          onClose={closeMenu}
          title={'Menu'}
        />
        <StyledDrawerContent>
          <List
            sx={{ overflowY: 'auto', pb: 1.5, px: 2 }}
            dense
            disablePadding>
            <MenuItems />
            <BookmarksListMenu />
          </List>
          <BottomMenu />
        </StyledDrawerContent>
      </Drawer>
    )
  }

  return (
    <StyledDrawer
      anchor={'left'}
      open={showMenu}
      PaperProps={{
        style: {
          top: offsetTop,
          padding: 0,
          height: `calc(100vh - ${offsetTop}px)`,
        },
      }}
      variant={'permanent'}>
      <StyledDrawerContent open={showMenu}>
        {hadMoreUpdates && (
          <MoreUpdatesBadge
            onClick={handleMoreUpdatesClick}
            show={showMoreUpdates}
          />
        )}
        <StyledList
          ref={ref}
          hasOverflow={hasOverflow}
          sx={{ overflowY: 'overlay' }} // Add overlay here because styled() doesn't recognize "overlay"
          dense
          disablePadding>
          <MenuItems />
          <BookmarksListMenu onLastUpdatedListIntersection={handleLastUpdatedListIntersection} />
        </StyledList>
        <BottomMenu />
      </StyledDrawerContent>
    </StyledDrawer>
  )
}
