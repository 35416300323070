import { isDefined } from '@gain/utils/common'
import { capitalize } from '@mui/material/utils'
import formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'

interface FormatDateDistanceOptions {
  emptyValue?: string
  suffix?: string
}

const parseDate = (value: number | string | Date | null | undefined): number | null => {
  if (typeof value === 'number') {
    return value
  } else if (typeof value === 'string') {
    return parseISO(value).getTime()
  } else if (value instanceof Date) {
    return value.getTime()
  }
  return null
}

export const formatDateDistance = (
  from: number | string | Date | null | undefined,
  to: number | string | Date | null | undefined = new Date(),
  { emptyValue = '-', suffix = 'ago' }: FormatDateDistanceOptions = {}
) => {
  const fromDate = parseDate(from)
  const toDate = parseDate(to)

  if (!isDefined(fromDate) || !isDefined(toDate)) {
    return emptyValue
  }

  const result = capitalize(
    formatDistance(fromDate, toDate, {
      // addSuffix: true,
      includeSeconds: true,
    })
  )

  return [result, suffix].filter(isDefined).join(' ')
}
