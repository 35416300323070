import { AdvisorDealListItem, AssetListItem, AssetOwnershipCount } from '@gain/rpc/app-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { CountryCode, getCountry, getRegionForCountryCode } from '@gain/utils/countries'
import { formatSector, formatSubsector, getSectorForSubsector } from '@gain/utils/sector'

import { formatAssetOwnershipType } from '../../features/asset/asset-ownership'
import { chartColorSet, chartColorSetGreen, chartColorSetGrey } from '../chart/chart-colors'

export interface TableValueDistributionConfig<D> {
  getValue: (item: D) => string | null

  // Optional function to get the increment value for the count. If not provided,
  // the count will be incremented by 1 for each item.
  getIncrementBy?: (item: D) => number

  // Optional function to group values into subgroups.
  getGroupBy?: (key: string) => string | undefined

  formatValue: (value: string) => string
  formatGroup?: (value: string) => string
  colorSet: string[]
}

export const ASSET_VALUE_SHARE_OWNERSHIP_CONFIG: TableValueDistributionConfig<AssetListItem> = {
  getValue: (asset: AssetListItem) => asset.ownership,
  formatValue: (value) => formatAssetOwnershipType(value as AssetOwnershipType),
  formatGroup: (value) => value,
  colorSet: chartColorSetGrey,
}

export const ASSET_OWNERSHIP_COUNT_CONFIG: TableValueDistributionConfig<AssetOwnershipCount> = {
  getValue: (item: AssetOwnershipCount) => item.type,
  getIncrementBy: (item: AssetOwnershipCount) => item.count,
  formatValue: (value) => formatAssetOwnershipType(value as AssetOwnershipType),
  formatGroup: (value) => value,
  colorSet: chartColorSetGrey,
}

export const ASSET_VALUE_SHARE_REGION_CONFIG: TableValueDistributionConfig<AssetListItem> = {
  getValue: (asset: AssetListItem) => asset.region,
  getGroupBy: (key) => getRegionForCountryCode(key) || 'Other',
  formatValue: (value) => getCountry(value as CountryCode),
  formatGroup: (value) => value,
  colorSet: chartColorSet,
}

export const ASSET_VALUE_SHARE_SECTOR_CONFIG: TableValueDistributionConfig<AssetListItem> = {
  getValue: (asset: AssetListItem) => asset.subsector,
  getGroupBy: (key) => getSectorForSubsector(key) || key,
  formatValue: (value) => formatSubsector(value) || value,
  formatGroup: (value) => formatSector(value) || value,
  colorSet: chartColorSetGreen.slice().reverse(),
}

export const DEAL_ADVISOR_REGION_CONFIG: TableValueDistributionConfig<AdvisorDealListItem> = {
  getValue: (advisorDealListItem: AdvisorDealListItem) => advisorDealListItem.region,
  getGroupBy: (key) => getRegionForCountryCode(key) || 'Other',
  formatValue: (value) => getCountry(value as CountryCode),
  formatGroup: (value) => value,
  colorSet: chartColorSet,
}

export const ADVISOR_DEAL_SECTOR_CONFIG: TableValueDistributionConfig<AdvisorDealListItem> = {
  getValue: (advisorDealListItem: AdvisorDealListItem) => advisorDealListItem.subsector,
  getGroupBy: (key) => getSectorForSubsector(key) || key,
  formatValue: (value) => formatSubsector(value) || value,
  formatGroup: (value) => formatSector(value) || value,
  colorSet: chartColorSetGreen.slice().reverse(),
}
