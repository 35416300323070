import { RowType } from '@gain/utils/table'
import { iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import TableSortLabel from '@mui/material/TableSortLabel'
import React from 'react'

import InfoButton from '../info-button'
import { RenderHeaderParams } from './virtual-table-model'

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  [`& .${iconButtonClasses.root}`]: {
    color: theme.palette.text.secondary,
  },

  [`&:hover .${iconButtonClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}))

export function VirtualTableHeadCellContent<Row extends RowType, Field extends keyof Row>({
  colDef,
  api,
}: RenderHeaderParams<Row, Field>) {
  return (
    <StyledTableSortLabel
      active={api.handleSortActive(colDef as never)}
      direction={api.handleSortDirection(colDef as never)}
      onClick={api.handleSort(colDef as never)}>
      {colDef.headerName}
      {colDef.headerExplainer && (
        <InfoButton
          dialogMessage={colDef.headerExplainer}
          dialogTitle={colDef.headerName ?? ''}
        />
      )}
    </StyledTableSortLabel>
  )
}
