import { AlertDialogContextProps, changesNotSavedDialog, useOpenDialog } from '@app/common/dialog'
import {
  BOOKMARKS_FILTERED_PATH,
  BOOKMARKS_LIST_COMPANIES_PATH,
  BOOKMARKS_LIST_PATH,
  BOOKMARKS_PATH,
  BookmarksPathParams,
  HOME_PATH,
} from '@app/routes/utils'
import { BookmarksFilteredList, CustomAssetList, CustomAssetListEnum } from '@gain/rpc/app-model'
import { RpcError, RpcErrorCode } from '@gain/rpc/utils'
import { compareNumberDesc } from '@gain/utils/common'
import { isXs } from '@gain/utils/responsive'
import { useCallback, useMemo, useRef } from 'react'
import { generatePath, matchPath, useHistory } from 'react-router-dom'

import { useCustomAssetLists } from './app-api-hooks'
import { useSavedBookmarksFilteredLists } from './bookmarks-filtered-hooks'

/**
 * The returned list contains all bookmark lists of all types ordered by the
 * highest number of updated assets. If two or more items have the same asset
 * update count they will be ordered alphabetically.
 *
 * After the first retrieval the list order is stored and maintained until the
 * user refreshes the page. This prevents unexpected reordering when the
 * updated asset count is reset to 0.
 */
export function useListItems() {
  const customAssetQueryLists = useSavedBookmarksFilteredLists()
  const customAssetLists = useCustomAssetLists()
  const previousOrder = useRef<number[]>([])

  return useMemo(() => {
    if (!customAssetLists.data || !customAssetQueryLists.lists) {
      return [] // Don't show anything until both lists are loaded
    }

    let result = [...customAssetLists.data, ...customAssetQueryLists.lists]
    if (previousOrder.current.length > 0) {
      // If we had a previous order maintain said order until the user refreshes the page
      result = result.sort((a, b) => {
        return previousOrder.current.indexOf(a.id) - previousOrder.current.indexOf(b.id)
      })
    } else {
      // Order by updatedAssetCount DESC, title ASC
      result = result.sort((a, b) => {
        if (a.updatedAssetCount && b.updatedAssetCount) {
          return compareNumberDesc(a.updatedAssetCount, b.updatedAssetCount)
        } else if (a.updatedAssetCount) {
          return -1
        } else if (b.updatedAssetCount) {
          return 1
        }

        return a.title.localeCompare(b.title)
      })
    }

    previousOrder.current = result.map(({ id }) => id)

    return result
  }, [customAssetLists.data, customAssetQueryLists.lists])
}

/**
 * Due to list sharing, it is possible that a list may be removed or the user's
 * access to the list is revoked while they are modifying it. In such a scenario,
 * this shows a dialog box and redirects the user to the lists page if they are
 * currently viewing the affected list.
 *
 * Returns true if the error was handled, otherwise false.
 */
export function useCheckBookmarksListError() {
  const openDialog = useOpenDialog()
  const history = useHistory()

  return useCallback(
    (err: unknown, fallback: AlertDialogContextProps = changesNotSavedDialog) => {
      // Return false if we don't recognize the error so that the caller
      // can handle the error in a different way.
      if (
        !(err instanceof RpcError) ||
        ![RpcErrorCode.Unauthorized, RpcErrorCode.InvalidResourceIdentifier].includes(err.code)
      ) {
        if (fallback) {
          openDialog(fallback)
        }
        return false
      }

      openDialog({
        title: 'List not available',
        message:
          'The list you are trying to access isn’t available to you. The list might be deleted or someone might have revoked your access.',
        buttonText: 'Ok',
      })

      // Redirect to the home page if we're currently viewing a list path that no longer exists
      if (
        matchPath(window.location.pathname, { path: BOOKMARKS_LIST_PATH }) ||
        matchPath(window.location.pathname, { path: BOOKMARKS_FILTERED_PATH })
      ) {
        history.push(generatePath(HOME_PATH))
      }

      return true
    },
    [history, openDialog]
  )
}

/**
 * The returned callback should be called after deleting or leaving a bookmarks page.
 *
 * On mobile this redirects the user to the "My lists"-page.
 *
 * On desktop, it redirects to the bookmarks list on the top. If no bookmark lists remain
 * after deleting or leaving all lists it redirects to the homepage instead.
 */
export function useNavigateToNextBookmarksList() {
  const history = useHistory()
  const xs = isXs()
  const lists = useListItems()

  return useCallback(
    (deletedListId: number, deletedListType: 'customAssetList' | 'customAssetQueryList') => {
      // Navigate away from the list page being deleted only if it is currently being viewed.
      let currentPath = BOOKMARKS_LIST_PATH
      if (deletedListType === 'customAssetQueryList') {
        currentPath = BOOKMARKS_FILTERED_PATH
      }
      const match = matchPath<BookmarksPathParams>(window.location.pathname, {
        path: currentPath,
        strict: false,
      })
      if (!match || match.params['listId'] !== `${deletedListId}`) {
        return
      }

      // Navigate to "Bookmarks"-page on mobile
      if (xs) {
        history.push(generatePath(BOOKMARKS_PATH))
        return
      }

      // Navigate to top list on desktop, or the homepage if none are left
      const next = lists?.find((list) => list.id !== deletedListId)
      if (next) {
        let nextPath = BOOKMARKS_FILTERED_PATH
        if (isCustomAssetList(next)) {
          nextPath = BOOKMARKS_LIST_COMPANIES_PATH
        }
        history.push(generatePath(nextPath, { listId: next.id }))
      } else {
        history.push(generatePath(HOME_PATH))
      }
    },
    [history, lists, xs]
  )
}

/**
 * This type guard checks whether a given list is an asset-list or a filtered bookmarks list.
 */
export function isCustomAssetList(
  list: CustomAssetList | BookmarksFilteredList
): list is CustomAssetList {
  return list.type === CustomAssetListEnum.CustomAssetList
}
