import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'
import startOfMonth from 'date-fns/startOfMonth'

/**
 * Returns the start of the next month in the format "1st of Jan".
 */
export function startOfNextMonth(): string {
  return format(startOfMonth(addMonths(new Date(), 1)), 'do \'of\' LLL')
}
