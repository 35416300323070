import { DealItemStatus, DealStatus } from './lib/rpc-shared-model'
import { ChipOption, Option } from './shared-model-interfaces'

export const DEAL_STATUS_OPTIONS: ReadonlyArray<Option<DealStatus>> = [
  {
    label: 'Live / rumoured',
    value: DealStatus.Live,
  },
  {
    label: 'Aborted',
    value: DealStatus.Aborted,
  },
]

export const DEAL_ITEM_STATUS_OPTIONS: ReadonlyArray<ChipOption<DealItemStatus>> = [
  {
    label: 'Draft',
    value: DealItemStatus.New,
    color: 'info',
  },
  {
    label: 'Review',
    value: DealItemStatus.Review,
    color: 'warning',
  },
  {
    label: 'Published',
    value: DealItemStatus.Published,
    color: 'success',
  },
]
