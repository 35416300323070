import Flag from '@gain/components/flag'
import { BriefcaseMoneyIcon, IndustryIcon } from '@gain/components/icons'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import React, { ReactNode } from 'react'

import Skeleton from '../../common/skeleton'
import { ObjectListItemAvatar } from './object-list-item-avater.component'
import { CombinedSearchType } from './object-list-item-formatters'
import { ObjectListItemLink, ObjectListItemLinkProps } from './object-list-item-link.component'
import { ObjectListItemText } from './object-list-item-text.component'

const ImageContainer = styled('div')({
  position: 'relative',
  width: 'fit-content',
})

const StyledFlag = styled(Flag)({
  position: 'absolute',
  right: -2,
  bottom: -2,
})

const StyledAvatarIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: -3,
  bottom: -3,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.secondary,
  width: 18,
  height: 18,

  '& svg': {
    width: 12,
    height: 12,
  },
}))

const StyledDescription = styled(Typography)<TypographyProps<'span', { component: 'span' }>>(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
  })
)

interface ObjectListItemProps extends ObjectListItemLinkProps {
  type?: CombinedSearchType
  description: ReactNode
  region: string | null
  icon: ReactNode
  name?: string
  imageUrl?: string | null
  children?: ReactNode
}

export function ObjectListItem({
  to,
  type,
  name,
  icon,
  description,
  region,
  imageUrl,
  isSkeleton,
  onClick,
  children,
  ...props
}: ObjectListItemProps) {
  return (
    <ObjectListItemLink
      {...props}
      isSkeleton={isSkeleton}
      onClick={onClick}
      to={to}>
      {(isSkeleton || imageUrl || icon) && (
        <Skeleton
          enabled={isSkeleton}
          width={40}>
          <ImageContainer>
            <ObjectListItemAvatar
              src={imageUrl || undefined}
              type={type}
              variant={'rounded'}>
              {icon}
            </ObjectListItemAvatar>

            {region && type !== 'investor' && <StyledFlag code={region} />}

            {((!region && type === 'industry') || type === 'investor') && (
              <StyledAvatarIconContainer>
                {type === 'industry' && <IndustryIcon />}
                {type === 'investor' && <BriefcaseMoneyIcon />}
              </StyledAvatarIconContainer>
            )}
          </ImageContainer>
        </Skeleton>
      )}

      <ObjectListItemText>
        <Skeleton
          boxSx={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}
          enabled={isSkeleton}
          width={'40%'}>
          <>
            <Typography
              color={'text.primary'}
              component={'span'}
              variant={'subtitle2'}
              noWrap>
              {isSkeleton ? 'Loading...' : name}
            </Typography>
            {!isSkeleton && description && (
              <StyledDescription
                color={'text.secondary'}
                component={'span'}
                variant={'body2'}
                noWrap>
                {isSkeleton ? 'Loading...' : description}
              </StyledDescription>
            )}
          </>
        </Skeleton>

        {children}
      </ObjectListItemText>
    </ObjectListItemLink>
  )
}
