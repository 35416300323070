import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '@app/features/investor-strategy/investor-strategy-contants'
import { useInvestor } from '@gain/api/app/hooks'
import { ChevronRightIcon } from '@gain/components/icons'
import { SlotHome, SlotName } from '@gain/components/slot'
import Head from '@gain/modules/head'
import { useIsXs } from '@gain/utils/responsive'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import { generatePath, Redirect, Route, Switch } from 'react-router-dom'

import { Link } from '../../common/link'
import MainContainer from '../../common/main-container'
import { TabPageHeaderContainer } from '../../common/tab-page-header-container'
import { TabPageTabContainer } from '../../common/tab-page-tab-container'
import MobilePageHeader from '../../layout/mobile-page-header'
import {
  generateInvestorPagePath,
  INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH,
  INVESTOR_STRATEGY_ASSETS_PAGE_PATH,
  INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH,
  INVESTOR_STRATEGY_FUNDS_PAGE_PATH,
  useInvestorStrategyPageParams,
} from '../utils'
import InvestorStrategyTabs from './investor-strategy-tabs'
import RouteAnalysis from './route-analysis'
import RouteBenchmarking from './route-benchmarking'
import RouteCompanies from './route-companies'
import RouteFunds from './route-funds'

const StyledTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  fontSize: 16,
  color: theme.palette.text.secondary,
  position: 'relative',
  bottom: -4,
}))

const StyledLink = styled(Link)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.text.primary,
  },
}))

export default function RouteInvestorStrategy() {
  const isXs = useIsXs()
  const params = useInvestorStrategyPageParams()
  const { investorId, investorName, strategyName } = params
  const { data: investor } = useInvestor({
    id: params.investorId,
    preview: params.preview,
  })

  if (!investor) {
    return null
  }

  return (
    <>
      <Head>
        <title>
          {investor?.name || ''} - {strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL}
        </title>
      </Head>
      {isXs && (
        <MobilePageHeader
          title={`${investor?.name || ''} - ${
            strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL
          }`}
        />
      )}
      <MainContainer fluid>
        <TabPageHeaderContainer>
          <Box
            alignItems={'baseline'}
            display={'flex'}>
            <StyledTypography
              color={'text.secondary'}
              variant={'subtitle1'}>
              <StyledLink to={generateInvestorPagePath({ investorId, investorName })}>
                {investorName}
              </StyledLink>
            </StyledTypography>
            <StyledChevronRightIcon
              color={'inherit'}
              fontSize={'inherit'}
            />
            <Typography
              color={'textPrimary'}
              variant={'h2'}>
              {strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL}
            </Typography>
          </Box>

          <TabPageTabContainer>
            <InvestorStrategyTabs />
            <SlotHome slotName={SlotName.Tabs} />
          </TabPageTabContainer>
        </TabPageHeaderContainer>

        <Switch>
          <Route
            path={INVESTOR_STRATEGY_ASSETS_PAGE_PATH}
            exact>
            <RouteCompanies investor={investor} />
          </Route>
          <Route
            path={INVESTOR_STRATEGY_FUNDS_PAGE_PATH}
            exact>
            <RouteFunds investor={investor} />
          </Route>
          <Route
            path={INVESTOR_STRATEGY_ANALYSIS_PAGE_PATH}
            exact>
            <RouteAnalysis investor={investor} />
          </Route>
          <Route
            path={INVESTOR_STRATEGY_BENCHMARKING_PAGE_PATH}
            exact>
            <RouteBenchmarking investor={investor} />
          </Route>
          <Route path={'*'}>
            <Redirect to={generatePath(INVESTOR_STRATEGY_ASSETS_PAGE_PATH, params)} />
          </Route>
        </Switch>
      </MainContainer>
    </>
  )
}
