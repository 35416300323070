import { UserIcon } from '@gain/components/icons'
import { formatAssetManager } from '@gain/utils/asset'
import { formatManagerPositionSince } from '@gain/utils/manager'

import ListItem from '../../../../common/list/list-item'
import { generateInvestorPagePath } from '../../../utils'
import { Manager } from './asset-management-util'

function formatSecondaryLine(manager: Manager) {
  if (manager.investor) {
    return manager.investor.name
  }

  return formatManagerPositionSince(manager.position, manager.joinYear, manager.joinMonth)
}

export interface ListItemManagerProps {
  manager: Manager
}

export default function ListItemManager({ manager }: ListItemManagerProps) {
  return (
    <ListItem
      avatarProps={{
        children: <UserIcon />,
      }}
      href={
        !!manager.person?.investorId &&
        generateInvestorPagePath({
          investorId: manager.person.investorId,
          investorName: manager.investor?.name || '-',
        })
      }
      primary={formatAssetManager(manager)}
      region={manager.person?.nationality}
      secondary={formatSecondaryLine(manager)}
    />
  )
}
