import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'
import { listFilter } from '@gain/rpc/utils'
import React from 'react'

import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../../features/investor-strategy/investor-strategy-contants'
import { ListViewAsset } from '../../../features/list-view'
import { useTrackPageView } from '../../../features/planhat/use-track-activity'
import { useInvestorStrategyPageParams } from '../../utils'

export interface RouteCompaniesProps {
  investor: Investor
}

export default function RouteCompanies({ investor }: RouteCompaniesProps) {
  const { strategyId, strategyName } = useInvestorStrategyPageParams()
  useTrackPageView('investor_strategy', { id: strategyId, tab: 'assets', requireTab: true })

  return (
    <>
      <Head>
        <title>
          {investor?.name || ''} - {strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL} -
          Assets
        </title>
      </Head>

      <ListViewAsset defaultFilter={[listFilter('strategyIds', '=', strategyId)]} />
    </>
  )
}
