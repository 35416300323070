import { ConferenceEditionListItem } from '@gain/rpc/app-model'
import { formatCountry } from '@gain/utils/countries'
import React, { useMemo } from 'react'

import { CardContentEmpty } from '../../../../common/card/card'
import VirtualTable, { createVirtualTableColumns } from '../../../../common/virtual-table'
import ConferenceEditionContentLink from '../../../../features/conference-edition/conference-edition-content-link'

export interface AssetFurtherContextConferencesTableProps {
  conferences: ConferenceEditionListItem[]
}

export default function AssetFurtherContextConferencesTable({
  conferences,
}: AssetFurtherContextConferencesTableProps) {
  const columns = useMemo(
    () =>
      createVirtualTableColumns<ConferenceEditionListItem>(
        {
          headerName: 'Conference name',
          field: 'name',
          sortable: false,
          width: 0,
          flex: 1,
          renderCell: ({ row }) => (
            <ConferenceEditionContentLink
              conferenceEdition={row}
              conferenceId={row.conferenceEditionId}
              conferenceName={row.name}
              logoFileUrl={row.logoFileUrl}
            />
          ),
        },
        {
          field: 'venueCountryCode',
          headerName: 'Country',
          width: 96,
          sortable: false,
          align: 'right',
          valueFormatter: ({ value }) => formatCountry(value),
        }
      ),
    []
  )

  if (conferences.length === 0) {
    return <CardContentEmpty message={'No conferences available'} />
  }

  return (
    <VirtualTable
      columns={columns}
      RowComponentProps={() => ({
        hover: false,
      })}
      rows={conferences}
      variant={'inline'}
    />
  )
}
