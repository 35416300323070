import {
  AssetListItem,
  IndustryMarketSegmentListItem,
  InvestorProfileStrategy,
} from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { isCmdClickEvent } from '@gain/utils/event'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import {
  ChartGroup,
  ChartGroupByConfig,
  ChartGroupBySelect,
  ChartGroupValueType,
  getPreferredChartGroupBy,
  useChartGroups,
} from '../../../common/chart/chart-groups'
import ChartLegend from '../../../common/chart/chart-legend'
import { ChartSizeTypeConfig, ChartSizeTypeSelect } from '../../../common/chart/chart-select'
import MeasureDimensions from '../../../common/responsive/measure-dimensions'
import { generateAssetPagePath } from '../../../routes/utils'
import { useAssetChartSizeTypes } from '../chart-utils/asset-chart-size-types'
import { AssetChartGroupType } from '../chart-utils/asset-group-by-configs'
import useAvailableIndustrySegmentGroups from '../chart-utils/use-available-industry-segment-groups'
import TreemapChart from '../charts/treemap-chart'
import { TreeLeaf, TreeNode, TreeRoot } from '../charts/treemap-chart/treemap-chart-model'

function getVisibleGroups(
  availableGroups: ChartGroup<AssetListItem>[],
  visibleGroupId?: ChartGroupValueType
) {
  if (isDefined(visibleGroupId)) {
    const visibleGroup = availableGroups.find((group) => group.value === visibleGroupId)

    if (visibleGroup) {
      return [visibleGroup]
    }
  } else {
    return availableGroups
  }

  return []
}

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0),
}))

export interface CompanyTreeMapChartProps {
  assets: AssetListItem[]
  industrySegments?: IndustryMarketSegmentListItem[]
  investorStrategies?: InvestorProfileStrategy[]
  disableGroupBySelect?: boolean
  disableSizeSelect?: boolean
  disableLegend?: boolean
  defaultGroupTypeId?: AssetChartGroupType
  height?: number
  visibleGroupId?: ChartGroupValueType
  highlightGroupId?: ChartGroupValueType
  paddingX?: number
  onLeafClick?: (asset: AssetListItem, event: MouseEvent) => void
}

function CompanyTreeMapChart({
  assets,
  industrySegments,
  investorStrategies,
  defaultGroupTypeId,
  disableGroupBySelect,
  disableSizeSelect,
  disableLegend,
  height = 600,
  visibleGroupId,
  highlightGroupId,
  paddingX,
  onLeafClick,
}: CompanyTreeMapChartProps) {
  const assetSizeTypes = useAssetChartSizeTypes()
  const history = useHistory()

  // This determines the size of the tree map areas
  const [sizeType, setSizeType] = useState<ChartSizeTypeConfig<AssetListItem>>(assetSizeTypes[0])

  // Available group by options
  const groupByOptions = useAvailableIndustrySegmentGroups(industrySegments, investorStrategies)

  // Keep track of the currently active group
  const [activeGroupBy, setActiveGroupBy] = useState<ChartGroupByConfig<AssetListItem>>(
    getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId)
  )

  // Extract the available groups from the assets based on the active groupBy
  const availableGroups = useChartGroups(assets, activeGroupBy)

  // Keep track of which groups in the active groupBy are currently visible
  const [visibleGroups, setVisibleGroups] = useState<ChartGroup<AssetListItem>[]>(
    getVisibleGroups(availableGroups, visibleGroupId)
  )

  // Keep track of the highlighted group
  const [highlightGroup, setHighlightGroup] = useState<ChartGroup<AssetListItem> | null>(null)

  // Filter assets that are inside visible groups and have a value greater than 0
  const filteredAssets = useMemo(() => {
    const temp = visibleGroups.map((group) => group.value)
    return assets.filter(
      (asset) => temp.includes(activeGroupBy.getValue(asset)) && (sizeType.getValue(asset) || 0) > 0
    )
  }, [assets, activeGroupBy, sizeType, visibleGroups])

  const tree = useMemo(() => {
    return {
      type: 'node',
      children: availableGroups
        .map((group) => {
          return {
            type: 'node',
            key: group.value,
            value: 0,
            name: group.label,
            data: group,
            color: group.color,
            children: filteredAssets
              .filter((asset) => group.value === activeGroupBy.getValue(asset))
              .sort((a, b) => (sizeType.getValue(b) || 0) - (sizeType.getValue(a) || 0))
              .map((asset) => {
                return {
                  type: 'leaf',
                  key: asset.id.toString(10),
                  value: sizeType.getValue(asset),
                  name: asset.name,
                  data: asset,
                } as TreeLeaf<AssetListItem>
              }),
          } as TreeNode<ChartGroup<AssetListItem>, AssetListItem>
        })
        .sort((a, b) => {
          const aTotal = a.children.reduce((acc, child) => acc + (child.value || 0), 0)
          const bTotal = b.children.reduce((acc, child) => acc + (child.value || 0), 0)

          return bTotal - aTotal
        }),
    } as TreeRoot<ChartGroup<AssetListItem>, AssetListItem>
  }, [filteredAssets, activeGroupBy, availableGroups, sizeType])

  const handleLeafClick = useCallback(
    (asset: AssetListItem, event: MouseEvent) => {
      if (onLeafClick) {
        onLeafClick(asset, event)
      } else {
        const assetPagePath = generateAssetPagePath({
          id: asset.id,
          name: asset.name,
        })

        if (isCmdClickEvent(event)) {
          window.open(history.createHref({ pathname: assetPagePath }))
        } else {
          history.push(assetPagePath)
        }
      }
    },
    [history, onLeafClick]
  )

  const handleGroupHover = useCallback(
    (group: ChartGroup<AssetListItem> | null) => {
      if (highlightGroupId) {
        return
      }

      setHighlightGroup(group)
    },
    [highlightGroupId]
  )

  useEffect(() => {
    setActiveGroupBy(getPreferredChartGroupBy(groupByOptions, defaultGroupTypeId))
  }, [defaultGroupTypeId, groupByOptions])

  useEffect(() => {
    setVisibleGroups(availableGroups)
  }, [availableGroups])

  useEffect(() => {
    setVisibleGroups(getVisibleGroups(availableGroups, visibleGroupId))
  }, [availableGroups, visibleGroupId])

  useEffect(() => {
    if (isDefined(highlightGroupId)) {
      const group = availableGroups.find((item) => item.value === highlightGroupId)
      if (group) {
        setHighlightGroup(group)
      }
    }
  }, [availableGroups, highlightGroupId])

  return (
    <StyledRoot sx={{ px: paddingX }}>
      <Grid
        spacing={1.5}
        container>
        {(!disableSizeSelect || !disableGroupBySelect) && (
          <Grid
            alignItems={'center'}
            justifyContent={'end'}
            spacing={2}
            container
            item>
            {!disableSizeSelect && (
              <Grid item>
                <ChartSizeTypeSelect
                  onChange={setSizeType}
                  options={assetSizeTypes}
                  value={sizeType}
                />
              </Grid>
            )}
            {!disableGroupBySelect && (
              <Grid item>
                <ChartGroupBySelect
                  onChange={setActiveGroupBy}
                  options={groupByOptions}
                  value={activeGroupBy}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          xs={12}
          item>
          <MeasureDimensions fixedHeight={height}>
            {({ width }) => (
              <TreemapChart
                data={tree}
                height={height}
                highlightGroupKey={highlightGroup?.value}
                onLeafClick={handleLeafClick}
                valueFormatter={sizeType.formatter}
                width={width}
              />
            )}
          </MeasureDimensions>
          {!disableLegend && (
            <ChartLegend
              groups={availableGroups}
              onChange={setVisibleGroups}
              onGroupHover={handleGroupHover}
              value={visibleGroups}
            />
          )}
        </Grid>
      </Grid>
    </StyledRoot>
  )
}

export default memo(CompanyTreeMapChart)
