import { AlertDialog, AlertDialogProps } from '../../common/dialog'

export type AlertDryPowderProps = Omit<AlertDialogProps, 'message' | 'title'>

export default function AlertDialogDryPowder(props: AlertDryPowderProps) {
  return (
    <AlertDialog
      message={
        'Committed capital that has not been called by the fund to date. Drypowder estimate based on the average time between final closings of the funds in a particular strategy, decreasing linearly over time.'
      }
      title={'Drypowder'}
      {...props}
    />
  )
}
