import Head from '@gain/modules/head'
import { AssetListItem, Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import React from 'react'

import Benchmarking from '../../../features/benchmarking'

export interface RouteBenchmarkingProps {
  investor: Investor
  assets: AssetListItem[]
  strategies: InvestorProfileStrategy[]
}

export default function RouteBenchmarking({
  assets,
  investor,
  strategies,
}: RouteBenchmarkingProps) {
  return (
    <>
      <Head>
        <title>{investor.name} - Benchmarking</title>
      </Head>

      <Benchmarking
        initialAssets={assets}
        investorStrategies={strategies}
        loadingInitialAssets={false}
        objectId={investor.id}
        objectType={'investor'}
      />
    </>
  )
}
