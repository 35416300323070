import { ListItemKey } from '@gain/rpc/list-model'
import { nanoid } from '@reduxjs/toolkit'
import { FormApi } from 'final-form'
import {
  createContext,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import { FilterModel } from '../filter-model'
import { filterValueGroup, filterValueItem } from '../filter-value-builders'
import { FilterFormValues, filterModelToValues, valuesToFilterModel } from './filter-form-value'

type Unsubscribe = () => void

export interface FilterApi<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
> {
  subscribe: (callback: (filterModel: FilterModel<Item, FilterField>) => void) => Unsubscribe
  addOrUpdate: (
    field: FilterField,
    setValue: (currentValue: any | null) => null | any
  ) => any | null

  // Resets the filter values back to the values it was initialized with.
  // If you provide a filterModel they will be used as the new initial values.
  reset: (filterModel?: FilterModel<Item, FilterField>) => void
}

export const FilterBarApiContext = createContext<unknown>(null)

interface FilterApiRendererProps<Item extends object, FilterField extends ListItemKey<Item>> {
  form: FormApi<FilterFormValues<Item, FilterField>>
  initialValues: FilterFormValues<Item, FilterField>
}

const FilterApiRenderer = forwardRef(function FilterBarApi<
  Item extends object,
  FilterField extends ListItemKey<Item>
>(
  { form, initialValues, children }: PropsWithChildren<FilterApiRendererProps<Item, FilterField>>,
  filterBarApiRef: ForwardedRef<FilterApi<Item, FilterField>>
) {
  const listeners = useRef<Record<string, (filterModel: FilterModel<Item, FilterField>) => void>>(
    {}
  )

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (state) => {
        const model = valuesToFilterModel(state.values)
        Object.keys(listeners.current).forEach((key) => listeners.current[key](model))
      },
      {
        values: true,
      }
    )

    return () => {
      unsubscribe()
    }
  }, [form])

  const subscribe = useCallback(
    (callback: (filterModel: FilterModel<Item, FilterField>) => void): Unsubscribe => {
      const id = nanoid()
      listeners.current[id] = callback
      callback(valuesToFilterModel(form.getState().values))
      return () => {
        delete listeners.current[id]
      }
    },
    [form]
  )

  const addOrUpdate = useCallback(
    (field: FilterField, setValue: (currentValue: any | null) => null | any) => {
      const groups = form.getState().values.groups || []

      // Lookup matching value item
      for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
        const group = groups[groupIndex]
        for (let itemIndex = 0; itemIndex < group.value.length; itemIndex++) {
          const item = group.value[itemIndex]
          if (item.filterId === field) {
            form.change(
              `groups[${groupIndex}].value[${itemIndex}].value` as never,
              setValue(item.value)
            )
            return
          }
        }
      }

      // If there is no filter for the given field we create it
      form.mutators['push'](
        'groups',
        filterValueGroup<Item, FilterField>(filterValueItem(field, setValue(null)))
      )
    },
    [form]
  )

  // Resets filter values.
  const reset = useCallback(
    (filterModel?: FilterModel<Item, FilterField>) => {
      if (filterModel) {
        form.restart(filterModelToValues(filterModel))
      } else {
        form.restart(initialValues)
      }
    },
    [form, initialValues]
  )

  const api = {
    subscribe,
    addOrUpdate,
    reset,
  }

  useImperativeHandle(filterBarApiRef, () => api)

  return <FilterBarApiContext.Provider value={api}>{children}</FilterBarApiContext.Provider>
})

export default FilterApiRenderer
