import { useDeleteRecentVisit } from '@gain/api/app/hooks'
import { CloseIcon } from '@gain/components/icons'
import Typography from '@gain/components/typography'
import { UserRecentVisitDetails, UserRecentVisitObjectType } from '@gain/rpc/app-model'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React from 'react'

import Skeleton from '../../../common/skeleton'
import BookmarksListAddAssetButton from '../../bookmarks-list/bookmarks-list-add-assets/bookmarks-list-add-asset-button'
import {
  correctItemDescription,
  correctItemIcon,
  correctItemName,
  correctItemRegion,
  correctItemTo,
  ObjectListItem,
} from '../../object-list-item'

const StyledRecentVisitTitle = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  paddingLeft: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 32,
  height: 'inherit',
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body1.fontSize,
  padding: theme.spacing(1, 0.5),
  lineHeight: 0,
}))

const StyledActionButtons = styled('div')({
  marginLeft: 'auto',
  whiteSpace: 'nowrap',
})

const StyledBookmarksListAddAssetButton = styled(BookmarksListAddAssetButton)(({ theme }) => ({
  minWidth: 32,
  minHeight: 32,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

export interface RecentVisitsListProps {
  items: (UserRecentVisitDetails | null)[]
  loading?: boolean
  onClick?: () => void
  onOpenCustomAssetMenu?: () => void
  onCloseCustomAssetMenu?: () => void
}

export default function RecentVisitsList({
  items,
  onClick,
  loading = false,
  onOpenCustomAssetMenu,
  onCloseCustomAssetMenu,
}: RecentVisitsListProps) {
  const deleteRecentVisit = useDeleteRecentVisit()

  if (items.length === 0) {
    return null
  }

  const handleDelete = (event, id: number) => {
    event.stopPropagation()
    event.preventDefault() // Prevent triggering parent item onClick handler
    deleteRecentVisit(id)
  }

  const handlePointerDown = (event) => {
    event.preventDefault() // Prevent search input from losing focus
  }

  return (
    <Stack>
      <Skeleton enabled={loading}>
        <StyledRecentVisitTitle
          color={'text.secondary'}
          variant={'body2'}>
          Recently viewed
        </StyledRecentVisitTitle>
      </Skeleton>

      {items.map((item, index) => (
        <ObjectListItem
          key={item?.id || index}
          description={correctItemDescription(item?.objectType, item?.description, item?.regions)}
          icon={correctItemIcon(item?.objectType, item?.imageUrl)}
          imageUrl={item?.imageUrl}
          isSkeleton={item === null}
          name={correctItemName(item?.objectType, item?.name)}
          onClick={onClick}
          region={correctItemRegion(item?.objectType, item?.regions)}
          to={correctItemTo(item?.objectType, item?.objectId, item?.name)}
          type={item?.objectType}
          data-hj-suppress>
          {!!item && (
            <StyledActionButtons>
              {item.objectType === UserRecentVisitObjectType.Asset && (
                <StyledBookmarksListAddAssetButton
                  assetId={item.objectId}
                  color={'primary'}
                  fontSize={16}
                  onCloseMenu={onCloseCustomAssetMenu}
                  onOpenMenu={onOpenCustomAssetMenu}
                />
              )}

              <StyledIconButton
                color={'inherit'}
                onClick={(event) => handleDelete(event, item.id)}
                onPointerDown={handlePointerDown}
                size={'small'}>
                <CloseIcon color={'inherit'} />
              </StyledIconButton>
            </StyledActionButtons>
          )}
        </ObjectListItem>
      ))}
    </Stack>
  )
}
