import {
  findFilterByField,
  isAndListFilter,
  isBooleanListFilter,
  isEqualsListFilter,
  isNonBooleanListFilter,
  isNotListFilter,
  isOrListFilter,
  ListFilter,
  ListFilterBoolean,
  ListFilterNonBoolean,
  ListFilterNonBooleanOperator,
  ListItemKey,
} from '@gain/rpc/list-model'
import { isNumber, isString } from '@gain/utils/typescript'

import { AutocompleteIncludeMode } from './filter/filter-autocomplete/filter-autocomplete'
import { autocompleteIncludeFilterValue } from './filter/filter-autocomplete/filter-autocomplete-utils'
import {
  AutocompleteMatchMode,
  FILTER_RANGE_MAX_OPERATOR,
  FILTER_RANGE_MIN_OPERATOR,
  FILTER_RANGE_OPERATORS,
  FilterAutocompleteOptionsValue,
  FilterAutocompleteValue,
  FilterCheckboxListValue,
  FilterCheckboxValue,
  FilterCityItem,
  FilterCityValue,
  FilterConfig,
  FilterConfigMap,
  FilterGeoPointValue,
  FilterGeoPolygonValue,
  FilterRangeOperator,
  FilterRangeValue,
  FilterSimilarToValue,
  FilterTextValue,
} from './filter-config/filter-config-model'
import { FilterModel } from './filter-model'
import { filterValueGroup, filterValueItem } from './filter-value-builders'
import { FilterValueGroup, FilterValueItem } from './filter-value-model'

function parseAutocompleteValue<Item extends object = object>(
  filter: ListFilterNonBoolean<Item>
): FilterAutocompleteValue {
  if (Array.isArray(filter.value) && filter.operator === '=' && filter.value.every(isNumber)) {
    return {
      include: {
        value: filter.value,
        mode: 'any',
      },
      exclude: {
        value: [],
        mode: 'all',
      },
    }
  }

  return null
}

function parseCheckboxValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: ListFilterNonBoolean<Item, ListFilterNonBooleanOperator, FilterField>
): FilterCheckboxValue {
  if (typeof filter.value === 'boolean' && filter.operator === '=') {
    return filter.value === true ? true : null
  }

  return null
}

function parseCheckboxListValue<Item extends object = object>(
  filter: ListFilterNonBoolean<Item>
): FilterCheckboxListValue {
  if (
    filter.operator === '=' &&
    Array.isArray(filter.value) &&
    (filter.value.every(isNumber) || filter.value.every(isString))
  ) {
    return filter.value as number[] | string[]
  }

  return null
}

/**
 * Searches for a matching range value filter in the given filters array. IMPORTANT: this function
 * will remove a matching filter from the existing filters array to make sure the filter will not be
 * parsed again.
 */
function spliceRangeValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(field: FilterField, operator: FilterRangeOperator, filters: ListFilter<Item>[]): number | null {
  const index = filters.findIndex(
    (item) => item.operator === operator && item.field === field && typeof item.value === 'number'
  )

  if (index === -1) {
    return null
  }

  return filters.splice(index, 1).reduce((acc, current) => current.value as number, 0)
}

function parseRangeValue<Item extends object = object>(
  filter: ListFilterNonBoolean<Item>,
  allFilters: ListFilter<Item>[]
): FilterRangeValue {
  if (filter.operator === FILTER_RANGE_MIN_OPERATOR && isNumber(filter.value)) {
    const max = spliceRangeValue(filter.field, FILTER_RANGE_MAX_OPERATOR, allFilters)
    return [filter.value, max]
  } else if (filter.operator === FILTER_RANGE_MAX_OPERATOR && isNumber(filter.value)) {
    const min = spliceRangeValue(filter.field, FILTER_RANGE_MIN_OPERATOR, allFilters)
    return [min, filter.value]
  }

  return null
}

function isBooleanRangeFilterItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(listFilter: ListFilter<Item>, filterConfigMap: FilterConfigMap<Item, FilterField>) {
  if (
    isNonBooleanListFilter(listFilter) &&
    FILTER_RANGE_OPERATORS.includes(listFilter.operator) &&
    listFilter.field in filterConfigMap
  ) {
    const filterConfig = filterConfigMap[listFilter.field as unknown as FilterField]
    return ['range', 'range-currency'].includes(filterConfig.type)
  }

  return false
}

function isBooleanRangeFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  listFilter: ListFilterBoolean<Item>,
  filterConfigMap: FilterConfigMap<Item, FilterField>
): boolean {
  return (
    listFilter.operator === 'and' &&
    listFilter.value.every((item) => isBooleanRangeFilterItem(item, filterConfigMap)) &&
    listFilter.value.length > 0 &&
    listFilter.value[0].field in filterConfigMap &&
    ['range', 'range-currency'].includes(filterConfigMap[listFilter.value[0].field].type)
  )
}

function parseBooleanRangeFilterValueItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(listFilter: ListFilterBoolean<Item>): FilterValueItem<Item, FilterField> | null {
  const listFilters = listFilter.value.slice(0)

  if (listFilters.length > 0 && isNonBooleanListFilter(listFilters[0])) {
    const value = parseRangeValue(listFilters[0], listFilters)
    if (value !== null) {
      return filterValueItem(listFilters[0].field as unknown as FilterField, value)
    }
  }

  return null
}

function parseTextValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filter: ListFilterNonBoolean<Item, ListFilterNonBooleanOperator, FilterField>): FilterTextValue {
  if (filter.operator === '=' && typeof filter.value === 'string') {
    return filter.value
  }

  return null
}

function isGeoPointValue(value: any): value is FilterGeoPointValue {
  if (value === null) {
    return true
  }

  if (typeof value !== 'object') {
    return false
  }

  if (typeof value['lon'] !== 'number') {
    return false
  } else if (typeof value['lat'] !== 'number') {
    return false
  } else if (typeof value['distance'] !== 'number') {
    return false
  }

  return true
}

function parseGeoPointValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: ListFilterNonBoolean<Item, ListFilterNonBooleanOperator, FilterField>
): FilterGeoPointValue {
  if (filter.operator === 'within' && isGeoPointValue(filter.value)) {
    return filter.value
  }

  return null
}

function isGeoPolygonValue(value: any): value is FilterGeoPolygonValue {
  if (value === null) {
    return true
  }

  if (typeof value !== 'object') {
    return false
  }

  return typeof value['placeId'] === 'string'
}

function parseGeoPolygonValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: ListFilterNonBoolean<Item, ListFilterNonBooleanOperator, FilterField>
): FilterGeoPolygonValue | null {
  if (filter.operator === 'within' && isGeoPolygonValue(filter.value)) {
    return filter.value as unknown as FilterGeoPolygonValue
  }
  return null
}

function parseSimilarToValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: ListFilterNonBoolean<Item, ListFilterNonBooleanOperator, FilterField>
): FilterSimilarToValue | null {
  if (filter.operator === '=' && typeof filter.value === 'number') {
    return filter.value
  }

  return null
}

function isCityFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(value: any, filterMap: FilterConfigMap<Item, FilterField>): value is FilterCityValue {
  if (!(isOrListFilter(value) && Array.isArray(value.value))) {
    return false
  }

  return value.value.every((item) => {
    if (
      !(isAndListFilter(item) && item.value.length === 3 && isNonBooleanListFilter(item.value[0]))
    ) {
      return false
    }

    const filter = filterMap[item.value[0].field] as FilterConfig<Item, FilterField>

    if (!filter || filter.type !== 'city') {
      return false
    }

    return (
      isNonBooleanListFilter(item.value[0]) &&
      item.value[0].field === filter.id &&
      typeof item.value[0].value === 'string' &&
      item.value[0].operator === '=' &&
      isNonBooleanListFilter(item.value[1]) &&
      item.value[1].field === filter.regionField &&
      (typeof item.value[1].value === 'string' || item.value[1] === null) &&
      item.value[1].operator === '=' &&
      isNonBooleanListFilter(item.value[2]) &&
      item.value[2].field === filter.countryCodeField &&
      typeof item.value[2].value === 'string' &&
      item.value[2].operator === '='
    )
  })
}

function parseBooleanCityFilterValueItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: ListFilterBoolean<Item>,
  filterMap: FilterConfigMap<Item, FilterField>
): FilterValueItem<Item, FilterField> | null {
  if (isCityFilter(filter, filterMap)) {
    const firstItem = (filter.value[0] as ListFilterBoolean<Item>).value[0]
    const field = firstItem.field as FilterField
    return filterValueItem(
      field,
      filter.value.map((item) => {
        const b = item as ListFilterBoolean<Item>
        return {
          city: b.value[0].value,
          region: b.value[1].value,
          countryCode: b.value[2].value,
        } as FilterCityItem
      })
    )
  }

  return null
}

function getBooleanFilterFilterType<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(listFilter: ListFilterBoolean<Item>, filterConfigMap: FilterConfigMap<Item, FilterField>) {
  if (isAutocompleteFilter(listFilter, filterConfigMap)) {
    return 'autocomplete'
  } else if (isBooleanRangeFilter(listFilter, filterConfigMap)) {
    return 'range'
  } else if (isCityFilter(listFilter, filterConfigMap)) {
    return 'city'
  }

  return null
}

interface PartialAutocompleteFilterValue {
  include?: Partial<FilterAutocompleteOptionsValue>
  exclude?: Partial<FilterAutocompleteOptionsValue>
}

export function autocompleteFilterValue({
  include,
  exclude,
}: PartialAutocompleteFilterValue): FilterAutocompleteValue {
  return {
    include: {
      value: include?.value || [],
      mode: include?.mode || 'all',
    },
    exclude: {
      value: exclude?.value || [],
      mode: exclude?.mode || 'all',
    },
  }
}

function parseAutocompleteOptions<Item extends object>(
  listFilter: ListFilter<Item>
): {
  field: string
  includeMode: AutocompleteIncludeMode
  matchMode: AutocompleteMatchMode
  value: number[]
} | null {
  let includeMode: AutocompleteIncludeMode = 'include'
  let andOrFilter = listFilter
  if (isNotListFilter(listFilter)) {
    includeMode = 'exclude'
    andOrFilter = listFilter.value[0]
  }

  if (!isAndListFilter(andOrFilter) && !isOrListFilter(andOrFilter)) {
    return null
  }

  const matchMode: AutocompleteMatchMode = andOrFilter.operator === 'and' ? 'all' : 'any'
  const values = andOrFilter.value

  if (
    values.length > 0 &&
    values.every((item) => isEqualsListFilter(item) && typeof item.value === 'number')
  ) {
    return {
      field: values[0].field,
      includeMode,
      value: values.map((item) => item.value as number),
      matchMode: matchMode,
    }
  }
  return null
}

function parseAutocomplete<Item extends object, FilterField extends ListItemKey<Item>>(
  listFilter: ListFilter<Item, 'and'>
): FilterValueItem<Item, FilterField> | null {
  if (listFilter.value.length > 2 || listFilter.value.length === 0) {
    return null
  }

  let field: string | null = null
  const value = listFilter.value.reduce((acc, current) => {
    const options = parseAutocompleteOptions(current)
    if (options === null) {
      return acc
    }
    field = options.field
    return {
      ...acc,
      [options.includeMode]: {
        value: options.value,
        mode: options.matchMode,
      },
    }
  }, {} as PartialAutocompleteFilterValue)

  if (field && (value.include?.value?.length || value.exclude?.value?.length)) {
    return filterValueItem(field, autocompleteFilterValue(value))
  }

  return null
}

function parseAutocompleteFilterValueItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(listFilter: ListFilterBoolean<Item>): FilterValueItem<Item, FilterField> | null {
  if (isAndListFilter(listFilter)) {
    if (listFilter.value.every(isNonBooleanListFilter)) {
      // Previous AND tag filter (e.g. pet-food AND cat-food)
      return filterValueItem(
        listFilter.value[0].field as FilterField,
        autocompleteIncludeFilterValue(
          listFilter.value.flatMap((item) => item.value as number),
          'all'
        )
      )
    }

    return parseAutocomplete(listFilter)
  } else if (isOrListFilter(listFilter)) {
    // Previous OR tag filter (e.g. pet-food OR cat-food)
    if (listFilter.value.every(isNonBooleanListFilter)) {
      return filterValueItem(
        listFilter.value[0].field as FilterField,
        autocompleteIncludeFilterValue(
          listFilter.value.flatMap((item) => item.value as number),
          'any'
        )
      )
    }
  }

  return null
}

function parseBooleanFilterValueItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  listFilter: ListFilterBoolean<Item>,
  filterConfigMap: FilterConfigMap<Item, FilterField>
): FilterValueItem<Item, FilterField> | null {
  switch (getBooleanFilterFilterType(listFilter, filterConfigMap)) {
    case 'autocomplete':
      return parseAutocompleteFilterValueItem(listFilter)
    case 'range':
      return parseBooleanRangeFilterValueItem(listFilter)
    case 'city':
      return parseBooleanCityFilterValueItem<Item, FilterField>(listFilter, filterConfigMap)
    default:
      return null
  }
}

function parseNonBooleanFilterValue<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filterConfig: FilterConfig<Item, FilterField>,
  listFilter: ListFilterNonBoolean<Item>,
  allFilters: ListFilter<Item>[]
) {
  switch (filterConfig.type) {
    case 'autocomplete':
      return parseAutocompleteValue(listFilter)
    case 'checkbox':
      return parseCheckboxValue(listFilter)
    case 'checkbox-list':
      return parseCheckboxListValue(listFilter)
    case 'range':
    case 'range-currency':
      return parseRangeValue(listFilter, allFilters)
    case 'text':
      return parseTextValue(listFilter)
    case 'geo-point':
      return parseGeoPointValue(listFilter)
    case 'geo-polygon':
      return parseGeoPolygonValue(listFilter)
    case 'similar-to':
      return parseSimilarToValue(listFilter)
    case 'city':
      throw new Error('city filter must be wrapped in OR filter')
  }
}

function isAutocompleteFilter<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(filter: ListFilterBoolean<Item>, filterConfigMap: FilterConfigMap<Item, FilterField>) {
  const autocompleteFilterFields = Object.keys(filterConfigMap).reduce((acc, current) => {
    const config = filterConfigMap[current as FilterField]
    if (config.type === 'autocomplete') {
      return acc.concat(config.id)
    }
    return acc
  }, new Array<FilterField>())

  return autocompleteFilterFields.some((field) => findFilterByField(filter, field))
}

function parseFilterValueItem<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  filter: ListFilter<Item>,
  filters: ListFilter<Item>[],
  filterConfigMap: FilterConfigMap<Item, FilterField>
): FilterValueItem<Item, FilterField> | null {
  if (isBooleanListFilter(filter)) {
    return parseBooleanFilterValueItem(filter, filterConfigMap)
  } else if (isNonBooleanListFilter(filter) && filter.field in filterConfigMap) {
    const filterConfig = filterConfigMap[filter.field as unknown as FilterField]
    const value = parseNonBooleanFilterValue(filterConfig, filter, filters)

    if (value !== null) {
      return filterValueItem<Item, FilterField>(filterConfig.id, value)
    }
  }

  return null
}

function parseFilterValueGroup<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  listFilter: ListFilter<Item, 'or'>,
  filterConfigMap: FilterConfigMap<Item, FilterField>
): FilterValueGroup<Item, FilterField> | null {
  const filtersToParse = listFilter.value.slice()
  const filterValueItems = filtersToParse.reduce((acc, filter) => {
    const valueItem = parseFilterValueItem(filter, filtersToParse, filterConfigMap)
    if (valueItem !== null) {
      return acc.concat(valueItem)
    }

    return acc
  }, new Array<FilterValueItem<Item, FilterField>>())

  if (filterValueItems.length > 0) {
    return filterValueGroup(...filterValueItems)
  }

  return null
}

/**
 * Converts the given search and filter to a {FilterModel} which is used inside the {FilterBar}.
 * Filters that cannot be mapped will be silently ignored.
 */
export function toFilterModel<
  Item extends object = object,
  FilterField extends ListItemKey<Item> = ListItemKey<Item>
>(
  listFilters: ListFilter<Item>[],
  filterMap: FilterConfigMap<Item, FilterField>
): FilterModel<Item, FilterField> {
  // Create a copy so we can modify this array while parsing the filters
  const filtersToParse = listFilters.slice()

  return filtersToParse.reduce((acc, listFilter) => {
    if (isOrListFilter(listFilter)) {
      const valueGroup = parseFilterValueGroup(listFilter, filterMap)
      if (valueGroup !== null) {
        return acc.concat(valueGroup)
      }
      return acc
    } else {
      // Parse value items at root level and convert them to groups
      const valueItem = parseFilterValueItem(listFilter, filtersToParse, filterMap)
      if (valueItem !== null) {
        return acc.concat(filterValueGroup(valueItem))
      }
    }

    return acc
  }, new Array<FilterValueGroup<Item, FilterField>>())
}
