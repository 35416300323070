import Head from '@gain/modules/head'
import { Asset } from '@gain/rpc/app-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import Grid from '@mui/material/Grid'
import { useCallback, useRef, useState } from 'react'

import CardDescription from '../../../common/card/card-description'
import { calculateVisibleRows } from '../../../common/visible-rows'
import AssetSimilarCompaniesCard from '../../../features/asset/asset-similar-companies-card'
import SourcesList from '../../../features/source/source-list'
import { useAssetPageParams } from '../../utils'
import { useAssetSources } from '../route-asset-hooks'
import CardAssetCompetitors from './card-asset-competitors'
import CardCompaniesRelatedIndustries, {
  useAssetIndustryMarketSegments,
} from './card-companies-related-industries'

interface AssetMarketProps {
  availableHeight: number
  asset: Asset
}

export default function AssetMarket({ asset, availableHeight }: AssetMarketProps) {
  const params = useAssetPageParams()
  const sources = useAssetSources(asset?.sources, 'market')
  const industryMarketSegments = useAssetIndustryMarketSegments(params.id)
  const nrOfVisibleRows = calculateVisibleRows(availableHeight)

  const ref = useRef<HTMLDivElement>(null)
  const [minHeight, setMinHeight] = useState(0)
  const updateMinHeight = useCallback(() => {
    if (ref.current) {
      setMinHeight(ref.current.clientHeight)
    }
  }, [ref])

  const hasNoOtherCards =
    !asset.market?.competition &&
    !asset.competitors.length &&
    !industryMarketSegments.data.items.length
  const similarCompaniesOnly = hasNoOtherCards && !sources.length

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Market</title>
      </Head>

      <Grid
        spacing={2}
        container>
        {!hasNoOtherCards && (
          <Grid item>
            <Grid
              ref={ref}
              spacing={2}
              container>
              {(asset.market?.competition || industryMarketSegments.data.items.length > 0) && (
                <Grid
                  lg={asset.competitors.length > 0 ? 4 : 12}
                  sx={{ minHeight: { lg: minHeight } }}
                  xs={12}
                  item>
                  <Grid
                    spacing={2}
                    sx={{ minHeight: { lg: minHeight } }}
                    container>
                    {asset.market?.competition && (
                      <Grid item>
                        <CardDescription
                          title={'Competitive position'}
                          fullHeight>
                          {asset.market?.competition}
                        </CardDescription>
                      </Grid>
                    )}
                    {industryMarketSegments.data.items.length > 0 && (
                      <Grid item>
                        <CardCompaniesRelatedIndustries
                          segments={industryMarketSegments.data.items}
                          fullHeight
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {asset.competitors.length > 0 && (
                <Grid
                  lg={
                    asset.market?.competition || industryMarketSegments.data.items.length > 0
                      ? 8
                      : 12
                  }
                  xs={12}
                  item>
                  <CardAssetCompetitors
                    asset={asset}
                    callback={updateMinHeight}
                    fullHeight
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        <Grid
          xs={12}
          item>
          <AssetSimilarCompaniesCard
            asset={asset}
            hideAutomated={asset.profileType === AssetProfileType.Automated}
            visibleRowCount={similarCompaniesOnly ? nrOfVisibleRows : 15}
          />
        </Grid>

        {sources.length > 0 && (
          <Grid
            xs={12}
            item>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
