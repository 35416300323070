import { AssetListItem, InvestorProfileStrategy } from '@gain/rpc/app-model'
import { useScrollSpy, useScrollToSectionCallback } from '@gain/utils/dom'
import { useIsXs } from '@gain/utils/responsive'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useCallback, useMemo, useRef } from 'react'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import ChartWrapper from '../../../common/chart/chart-wrapper/chart-wrapper'
import InViewport from '../../../common/in-viewport'
import ListNavigation, { LIST_NAVIGATION_TOP_SPACING } from '../../../common/list-navigation'
import { BenchmarkingMovedBar } from '../../../features/benchmarking'
import {
  AssetChartSizeType,
  CompanyBubbleChart,
  CompanyBubbleEChart,
  CompanyTreeMapChart,
} from '../../../features/chart'
import { useAxisConfig } from '../../../features/chart/company-bubble-echart/axis/axis-config'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_BENCHMARKING_PATH,
  useInvestorPageParams,
} from '../../utils'
import InvestorEsgAssessmentCardContent from './investor-esg-assessment-card'
import InvestorInvestmentCriteriaCardContent from './investor-investment-criteria-card-content'
import { getRatingsPerStrategy } from './investor-rating-utils'

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  maxWidth: '100%',
  width: '100%',
}))

const StyledCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  minWidth: 0,
  width: '100%',
}))

const SECTIONS = [
  'Portfolio overview',
  'Investment criteria',
  'ESG assessment',
  'Benchmarking',
  'Sweet spot',
  'Company benchmarking',
  'Strategic maturity',
  'Likelihood of sponsor exit',
]

export interface RouteAnalysisProps {
  assets: AssetListItem[]
  strategies: InvestorProfileStrategy[]
}

export default function RouteAnalysis({ assets, strategies }: RouteAnalysisProps) {
  const theme = useTheme()
  const isXs = useIsXs()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const params = useInvestorPageParams()

  const sectionsRef = useRef<Array<HTMLElement | null>>([])
  const activeSectionIndex = useScrollSpy(sectionsRef)
  const handleScrollToSection = useScrollToSectionCallback(
    sectionsRef,
    activeSectionIndex,
    LIST_NAVIGATION_TOP_SPACING - 16 // subtract top padding
  )

  const handleSectionRef = useCallback(
    (title: string) => (el: HTMLElement | null) => {
      const index = SECTIONS.indexOf(title)

      if (index === -1) {
        throw new Error(`Could not find ${title} in sections`)
      }

      sectionsRef.current[index] = el
    },
    []
  )

  const ratingStrategies = useMemo(
    () => getRatingsPerStrategy(strategies, assets),
    [strategies, assets]
  )

  // TODO: benchmarking cleanup: this is temporary until we remove the growth momentum chart from this page
  const axisConfig = useAxisConfig(assets)

  return (
    <StyledRoot>
      {isLgUp && (
        <ListNavigation
          activeItemIndex={activeSectionIndex}
          items={SECTIONS}
          onItemClick={handleScrollToSection}
        />
      )}
      <StyledCardContainer>
        <InViewport
          ref={handleSectionRef('Portfolio overview')}
          minHeight={isXs ? 536 : 504}
          keepMounted>
          <Card sx={{ pb: 0 }}>
            <CardHeader
              title={'Portfolio overview'}
              absolute
            />
            <CardContent sx={{ pt: isXs ? 7 : 3 }}>
              <CompanyTreeMapChart
                assets={assets}
                height={400}
                investorStrategies={strategies}
              />
            </CardContent>
          </Card>
        </InViewport>

        <BenchmarkingMovedBar
          benchmarkingUrl={generateInvestorPagePath(params, INVESTOR_PAGE_BENCHMARKING_PATH)}
        />

        <InViewport
          ref={handleSectionRef('Investment criteria')}
          minHeight={isXs ? 876 : 608}
          keepMounted>
          <Card sx={{ overflow: 'auto', pb: 0 }}>
            <InvestorInvestmentCriteriaCardContent strategies={ratingStrategies} />
          </Card>
        </InViewport>
        <InViewport
          ref={handleSectionRef('ESG assessment')}
          minHeight={isXs ? 261 : 237}
          keepMounted>
          <Card sx={{ overflow: 'auto', pb: 0 }}>
            <InvestorEsgAssessmentCardContent strategies={ratingStrategies} />
          </Card>
        </InViewport>

        {!isXs && (
          <ChartWrapper
            ref={handleSectionRef('Benchmarking')}
            minHeight={592}
            title={'Benchmarking'}>
            <CompanyBubbleEChart
              assets={assets}
              axisConfig={axisConfig}
              height={406}
              investorStrategies={strategies}
              xAxisId={'fteCagrPctTwoYears'}
              yAxisId={'fteGrowthPctSixMonths'}
            />
          </ChartWrapper>
        )}

        {!isXs && (
          <InViewport
            ref={handleSectionRef('Sweet spot')}
            minHeight={536}
            keepMounted>
            <Card sx={{ pb: 0 }}>
              <CardHeader title={'Sweet spot'} />
              <CompanyBubbleChart
                assets={assets}
                defaultSizeType={AssetChartSizeType.Fte}
                fixedHeight={348}
                investorStrategies={strategies}
                xScale={'ebitda'}
                yScale={'ratingOrganicGrowth'}
              />
            </Card>
          </InViewport>
        )}

        {!isXs && (
          <InViewport
            ref={handleSectionRef('Company benchmarking')}
            minHeight={536}
            keepMounted>
            <Card sx={{ pb: 0 }}>
              <CardHeader title={'Company benchmarking'} />
              <CompanyBubbleChart
                assets={assets}
                defaultSizeType={AssetChartSizeType.Fte}
                fixedHeight={348}
                investorStrategies={strategies}
                xScale={'ratingOrganicGrowth'}
                yScale={'ratingEbitda'}
              />
            </Card>
          </InViewport>
        )}

        {!isXs && (
          <InViewport
            ref={handleSectionRef('Strategic maturity')}
            minHeight={536}
            keepMounted>
            <Card sx={{ pb: 0 }}>
              <CardHeader title={'Strategic maturity'} />
              <CompanyBubbleChart
                assets={assets}
                defaultSizeType={AssetChartSizeType.Fte}
                fixedHeight={348}
                investorStrategies={strategies}
                xScale={'ratingBuyAndBuild'}
                yScale={'ratingMultinational'}
              />
            </Card>
          </InViewport>
        )}

        {!isXs && (
          <InViewport
            ref={handleSectionRef('Likelihood of sponsor exit')}
            minHeight={536}
            keepMounted>
            <Card sx={{ pb: 0 }}>
              <CardHeader title={'Likelihood of sponsor exit'} />
              <CompanyBubbleChart
                assets={assets}
                defaultSizeType={AssetChartSizeType.Fte}
                fixedHeight={348}
                investorStrategies={strategies}
                xScale={'yearsInPortfolio'}
                yScale={'ratingGrowth'}
              />
            </Card>
          </InViewport>
        )}
      </StyledCardContainer>
    </StyledRoot>
  )
}
