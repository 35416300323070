import { compareNumberDesc } from '@gain/utils/common'
import { formatPercentage } from '@gain/utils/number'
import { objectKeys } from '@gain/utils/typescript'
import { groupBy } from 'lodash'
import { useMemo } from 'react'

import { LeafRow, ValueDistributionRow } from './table-value-distribution.component'
import type { TableValueDistributionConfig } from './table-value-distribution-config'

/**
 * Calculate the percentage and count for each unique value.
 */
export function useValueDistributionRows<D>(
  items: D[],
  config: TableValueDistributionConfig<D>
): ValueDistributionRow[] {
  return useMemo(() => {
    const percentagePerValue = getPercentagePerValue(items, config.getValue, config.getIncrementBy)

    if (config.getGroupBy) {
      const percentagePerValueGroupMap = groupBy(objectKeys(percentagePerValue), config.getGroupBy)

      return Object.keys(percentagePerValueGroupMap)
        .map((groupType): ValueDistributionRow => {
          const percentage = percentagePerValueGroupMap[groupType].reduce(
            (acc, current) => acc + percentagePerValue[current].percentage,
            0
          )

          const count = percentagePerValueGroupMap[groupType].reduce(
            (acc, current) => acc + percentagePerValue[current].count,
            0
          )

          return {
            label: config.formatGroup ? config.formatGroup(groupType) : groupType,
            type: groupType,
            color: '', // set colors after sorting
            count,
            percentage,
            formattedPercentage: formatPercentage(percentage, { round: 'smart' }),
            values: percentagePerValueGroupMap[groupType]
              .map(
                (key): LeafRow => ({
                  label: config.formatValue(key),
                  type: key,
                  count: percentagePerValue[key].count,
                  percentage: percentagePerValue[key].percentage,
                  formattedPercentage: formatPercentage(percentagePerValue[key].percentage, {
                    round: 'smart',
                  }),
                })
              )
              .sort(compareByPercentageDesc),
          }
        })
        .sort(compareByPercentageDesc)
        .map((row, index) => ({
          ...row,
          color: config.colorSet[index % (config.colorSet.length - 1)],
        }))
    }

    // If no grouping is specified, create rows for each unique value
    return objectKeys(percentagePerValue)
      .map((key): ValueDistributionRow => {
        return {
          label: config.formatValue(key),
          type: key,
          color: '', // set color after sorting
          count: percentagePerValue[key].count,
          percentage: percentagePerValue[key].percentage,
          formattedPercentage: formatPercentage(percentagePerValue[key].percentage, {
            round: 'smart',
          }),
        }
      })
      .sort(compareByPercentageDesc)
      .map((row, index) => ({
        ...row,
        color: config.colorSet[index % (config.colorSet.length - 1)],
      }))
  }, [items, config])
}

/**
 * Calculate the percentage and count for each unique value. Each value is counted
 * by the `getIncrementBy` function, or 1 if not provided.
 */
function getPercentagePerValue<D>(
  items: D[],
  getValue: TableValueDistributionConfig<D>['getValue'],
  getIncrementBy: TableValueDistributionConfig<D>['getIncrementBy']
): Record<string, { percentage: number; count: number }> {
  let total = 0

  const valueCountPerKey = items.reduce((acc, item) => {
    const value = getValue(item)
    if (value === null) {
      return acc
    }

    const increment = getIncrementBy?.(item) ?? 1

    if (acc[value]) {
      acc[value] += increment
    } else {
      acc[value] = increment
    }

    total += increment

    return acc
  }, {} as Record<string, number>)

  // Calculate percentage per key
  return objectKeys(valueCountPerKey).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        percentage: (valueCountPerKey[key] / total) * 100,
        count: valueCountPerKey[key],
      },
    }),
    {}
  )
}

/**
 * Compare two percentages in descending order.
 */
function compareByPercentageDesc(a: LeafRow, b: LeafRow): number {
  return compareNumberDesc(a.percentage, b.percentage)
}
