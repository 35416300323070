import { ManagerPosition } from './manager.model'

// FIXME: We should be able to filter by a boolean flag "ceo" in backend instead of relying on position names
export const CEO_POSITIONS = [
  ManagerPosition.CEO,
  ManagerPosition.CO_CEO,
  ManagerPosition.MANAGING_DIRECTOR,
  ManagerPosition.MD,
  ManagerPosition.DIRECTOR,
]
