import { isDefined } from '@gain/utils/common'

export type ChartGroupValueType = string | number | null

export interface ChartGroup<Data, ValueType extends ChartGroupValueType = ChartGroupValueType> {
  label: string
  value: ValueType
  color: string
  items: Data[]
}

export interface ChartGroupByConfig<
  Data,
  ValueType extends ChartGroupValueType = ChartGroupValueType
> {
  id?: string
  label: string
  getValue: (d: Data) => ValueType
  getLabel: (value: ValueType) => string
  compare?: (a: ChartGroup<Data>, b: ChartGroup<Data>) => number
}

export function getPreferredChartGroupBy<Data>(
  groups: ChartGroupByConfig<Data>[],
  groupId?: string
): ChartGroupByConfig<Data> {
  if (!groupId) {
    return groups[0]
  }

  return groups.find(({ id }) => id === groupId) || groups[0]
}

export function getVisibleChartGroups<Data>(
  availableGroups: ChartGroup<Data>[],
  visibleGroupId?: ChartGroupValueType
) {
  if (isDefined(visibleGroupId)) {
    const visibleGroup = availableGroups.find((group) => group.value === visibleGroupId)

    if (visibleGroup) {
      return [visibleGroup]
    }
  } else {
    return availableGroups
  }

  return []
}
