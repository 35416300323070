import Flag from '@gain/components/flag'
import { LeafIcon } from '@gain/components/icons'
import { AssetListItem } from '@gain/rpc/app-model'
import {
  formatAssetBusinessActivityLabel,
  formatAssetCustomerBaseLabel,
  formatAssetPricePositioningLabel,
  formatAssetSalesChannelLabel,
} from '@gain/utils/asset'
import { formatUrlHostname } from '@gain/utils/common'
import { formatCountryCity } from '@gain/utils/countries'
import { formatDate, formatMonthYear, formatYear } from '@gain/utils/date'
import { formatDealFundingSeriesLabel } from '@gain/utils/deal'
import {
  ratingBuyAndBuild,
  ratingContracted,
  ratingConversion,
  ratingEbitda,
  ratingEsg,
  ratingEsgEnvironmental,
  ratingEsgOverall,
  ratingEsgSocial,
  ratingGrossMargin,
  ratingGrowth,
  RatingKeys,
  ratingLeader,
  ratingMultinational,
  ratingNonCyclical,
  ratingOrganicGrowth,
  RatingTableCell,
} from '@gain/utils/investment-criteria'
import { formatSector, formatSubsector } from '@gain/utils/sector'
import Chip from '@mui/material/Chip'
import React from 'react'

import { ColumnConfig, VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH } from '../../../common/virtual-table'
import {
  NameVirtualTableHeaderCell,
  TagsVirtualTableCell,
} from '../../../common/virtual-table/cells'
import FinancialValue from '../../financial/financial-value'
import AssetOwnershipTypeExplainer from '../asset-ownership'
import { AssetListItemTableCellFte } from './asset-list-item-table-cell-fte'
import { AssetListItemTableCellMajorityOwnerName } from './asset-list-item-table-cell-majority-owner-name'
import AssetListItemTableCellMatchingText from './asset-list-item-table-cell-matching-text'
import { AssetListItemTableCellMinorityInvestors } from './asset-list-item-table-cell-minority-investors'
import { AssetListItemTableCellName } from './asset-list-item-table-cell-name'
import { RatingTableCell as AssetRatingTableCell } from './asset-list-item-table-cell-rating'
import { AssetListItemTableCellUpdatedDate } from './asset-list-item-table-cell-updated-date'
import { FinancialTableCell } from './table-cell/financial-table-cell'
import MultipleTableCell from './table-cell/multiple-table-cell'
import { PercentageTableCell } from './table-cell/percentage-table-cell'

const createInvestmentCriteriaColumn = (
  field: RatingKeys<AssetListItem>,
  label: string,
  explainer?: string
): ColumnConfig<AssetListItem> => ({
  field,
  headerName: label,
  headerExplainer: explainer,
  align: 'left',
  width: 175,
  renderCell: (params) => (
    <AssetRatingTableCell
      rating={params.value}
      type={field}
    />
  ),
})

export const assetListItemTableColumns: ColumnConfig<AssetListItem>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 320 - VIRTUAL_TABLE_CHECKBOX_CELL_WIDTH,
    flex: 1,
    align: 'left',
    sticky: true,
    defaultSortDirection: 'asc',
    renderCell: AssetListItemTableCellName,
    renderHeader: NameVirtualTableHeaderCell,
  },
  {
    field: 'relevanceRank',
    headerName: 'Rank',
    headerExplainer:
      'Most similar companies based on business description and tags, where 1 is the closest match',
    align: 'right',
    width: 120,
    defaultSortDirection: 'asc',
  },
  {
    field: 'tagIds',
    headerName: 'Tags',
    align: 'left',
    width: 360,
    renderCell: ({ row }) => (
      <TagsVirtualTableCell
        idField={'tagIds'}
        row={row}
        valueField={'tags'}
      />
    ),
  },
  {
    field: 'ftsHeadline',
    headerName: 'Matching text',
    align: 'left',
    width: 398,
    defaultSortDirection: 'desc',
    sortFields: ['ftsRelevance'],
    renderCell: ({ value }) => <AssetListItemTableCellMatchingText value={value} />,
  },
  {
    field: 'lastDealYear',
    headerName: 'Last platform deal',
    align: 'left',
    width: 176,
    sortFields: ['lastDealYear', 'lastDealMonth'],
    valueFormatter: (params) => formatMonthYear(params.row.lastDealMonth, params.row.lastDealYear),
  },
  {
    field: 'fte',
    headerName: 'FTEs',
    align: 'right',
    width: 160,
    renderCell: (params) => <AssetListItemTableCellFte {...params} />,
    sortFields: ['fteRangeCategory', 'fte'],
  },
  {
    field: 'webUrl',
    headerName: 'Company website',
    width: 200,
    valueFormatter: ({ value }) => formatUrlHostname(value, true),
  },
  {
    field: 'revenueWithAiGeneratedEur',
    headerName: 'Revenue',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <FinancialTableCell
        financial={row.revenueWithAiGeneratedEur}
        isAiGenerated={row.revenueIsAiGenerated}
        results={row.revenueResults}
        year={row.revenueYear}
        years={row.revenueYears}
      />
    ),
  },
  {
    field: 'grossMarginEur',
    headerName: 'Gross margin',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <FinancialTableCell
        financial={row.grossMarginEur}
        results={row.grossMarginResults}
        year={row.grossMarginYear}
        years={row.grossMarginYears}
      />
    ),
  },
  {
    field: 'grossMarginPctRevenue',
    headerName: 'Gross margin %',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <PercentageTableCell
        percentage={row.grossMarginPctRevenue}
        results={row.grossMarginPctRevenueResults}
        years={row.grossMarginPctRevenueYears}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'ebitdaEur',
    headerName: 'EBITDA',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <FinancialTableCell
        financial={row.ebitdaEur}
        results={row.ebitdaResults}
        year={row.ebitdaYear}
        years={row.ebitdaYears}
      />
    ),
  },
  {
    field: 'ebitdaPctRevenue',
    headerName: 'EBITDA %',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <PercentageTableCell
        percentage={row.ebitdaPctRevenue}
        results={row.ebitdaPctRevenueResults}
        years={row.ebitdaPctRevenueYears}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'ebitEur',
    headerName: 'EBIT',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.ebitEur}
        year={params.row.ebitYear}
      />
    ),
  },
  {
    field: 'ebitPctRevenue',
    headerName: 'EBIT %',
    width: 160,
    align: 'right',
    renderCell: ({ row }) => (
      <PercentageTableCell
        percentage={row.ebitPctRevenue}
        year={row.ebitYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'totalAssetsEur',
    headerName: 'Total assets',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.totalAssetsEur}
        year={params.row.totalAssetsYear}
      />
    ),
  },
  {
    field: 'capexEur',
    headerName: 'CAPEX',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.capexEur}
        year={params.row.capexYear}
      />
    ),
  },
  {
    field: 'netDebtEur',
    headerName: 'Net debt',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.netDebtEur}
        year={params.row.netDebtYear}
      />
    ),
  },
  {
    field: 'debtEur',
    headerName: 'Interest-bearing debt',
    align: 'right',
    width: 200,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.debtEur}
        year={params.row.debtYear}
      />
    ),
  },
  {
    field: 'cashEur',
    headerName: 'Cash & cash equivalents',
    align: 'right',
    width: 224,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.cashEur}
        year={params.row.cashYear}
      />
    ),
  },
  {
    field: 'capitalEur',
    headerName: 'Trade working capital',
    align: 'right',
    width: 208,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.capitalEur}
        year={params.row.capitalYear}
      />
    ),
  },
  {
    field: 'inventoryEur',
    headerName: 'Inventory',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.inventoryEur}
        year={params.row.inventoryYear}
      />
    ),
  },
  {
    field: 'receivablesEur',
    headerName: 'Receivables',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.receivablesEur}
        year={params.row.receivablesYear}
      />
    ),
  },
  {
    field: 'payablesEur',
    headerName: 'Payables',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.payablesEur}
        year={params.row.payablesYear}
      />
    ),
  },
  {
    field: 'marketCapitalizationEur',
    headerName: 'Market cap',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.value}
        quarter={params.row.marketCapitalizationFiscalYearQuarter}
        year={params.row.marketCapitalizationFiscalYear}
      />
    ),
  },
  {
    field: 'enterpriseValueEur',
    headerName: 'Enterprise value',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.value}
        quarter={params.row.enterpriseValueFiscalYear}
        year={params.row.enterpriseValueFiscalYearQuarter}
      />
    ),
  },
  {
    field: 'revenueFteRatioEur',
    headerName: 'Revenue / FTE',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.revenueFteRatioEur}
        format={'thousands-or-millions'}
        year={params.row.revenueFteRatioYear}
      />
    ),
  },
  {
    field: 'netDebtEbitdaRatio',
    headerName: 'Net debt / EBITDA',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        value={params.value}
        year={params.row.netDebtEbitdaRatioYear}
        enableNotMeaningful
      />
    ),
  },

  // Ratios
  {
    field: 'enterpriseValueEbitdaRatio',
    headerName: 'EV / EBITDA',
    align: 'right',
    width: 136,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        quarter={params.row.enterpriseValueEbitdaRatioFiscalYearQuarter}
        value={params.value}
        year={params.row.enterpriseValueEbitdaRatioFiscalYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'enterpriseValueEbitRatio',
    headerName: 'EV / EBIT',
    align: 'right',
    width: 136,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        quarter={params.row.enterpriseValueEbitRatioFiscalYearQuarter}
        value={params.value}
        year={params.row.enterpriseValueEbitRatioFiscalYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'enterpriseValueRevenueRatio',
    headerName: 'EV / Sales',
    align: 'right',
    width: 136,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        quarter={params.row.enterpriseValueRevenueRatioFiscalYearQuarter}
        value={params.value}
        year={params.row.enterpriseValueRevenueRatioFiscalYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'enterpriseValueEbitdaMinusCapexRatio',
    headerName: 'EV / (EBITDA - CAPEX)',
    align: 'right',
    width: 184,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        quarter={params.row.enterpriseValueEbitdaMinusCapexRatioFiscalYearQuarter}
        value={params.value}
        year={params.row.enterpriseValueEbitdaMinusCapexRatioFiscalYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'enterpriseValueInvestedCapitalRatio',
    headerName: 'EV / Invested Capital',
    align: 'right',
    width: 184,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        quarter={params.row.enterpriseValueInvestedCapitalRatioFiscalYearQuarter}
        value={params.value}
        year={params.row.enterpriseValueInvestedCapitalRatioFiscalYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'enterpriseValueFreeCashFlowRatio',
    headerName: 'EV / Free Cash Flow',
    align: 'right',
    width: 184,
    renderCell: (params) => (
      <MultipleTableCell
        precision={1}
        quarter={params.row.enterpriseValueFreeCashFlowRatioFiscalYearQuarter}
        value={params.value}
        year={params.row.enterpriseValueFreeCashFlowRatioFiscalYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'returnOnAssets',
    headerName: 'Return on assets',
    align: 'right',
    width: 168,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.returnOnAssets}
        year={params.row.returnOnAssetsYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'returnOnEquity',
    headerName: 'Return on equity',
    align: 'right',
    width: 168,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.returnOnEquity}
        year={params.row.returnOnEquityYear}
        enableNotMeaningful
      />
    ),
  },
  {
    field: 'returnOnCapitalEmployed',
    headerName: 'Return on capital employed',
    align: 'right',
    width: 232,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.returnOnCapitalEmployed}
        year={params.row.returnOnCapitalEmployedYear}
        enableNotMeaningful
      />
    ),
  },

  {
    field: 'ebitdaMinusCapexEur',
    headerName: 'EBITDA - CAPEX',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.ebitdaMinusCapexEur}
        year={params.row.ebitdaMinusCapexYear}
      />
    ),
  },
  {
    field: 'fteGrowthPctThreeMonths',
    headerName: '3M FTE growth',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.fteGrowthPctThreeMonths}
        year={params.row.fteYear}
      />
    ),
  },
  {
    field: 'fteGrowthPctSixMonths',
    headerName: '6M FTE growth',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.fteGrowthPctSixMonths}
        year={params.row.fteYear}
      />
    ),
  },
  {
    field: 'fteGrowthPctOneYear',
    headerName: '1Y FTE growth',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.fteGrowthPctOneYear}
        year={params.row.fteYear}
      />
    ),
  },
  {
    field: 'fteCagrPctTwoYears',
    headerName: '2Y FTE CAGR',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.fteCagrPctTwoYears}
        year={params.row.fteYear}
      />
    ),
  },
  {
    field: 'fteCagrPctThreeYears',
    headerName: '3Y FTE CAGR',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.fteCagrPctThreeYears}
        year={params.row.fteYear}
      />
    ),
  },
  {
    field: 'revenueGrowthPctOneYear',
    headerName: '1Y revenue growth',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.revenueGrowthPctOneYear}
        year={params.row.revenueYear}
      />
    ),
  },
  {
    field: 'revenueCagrPctTwoYears',
    headerName: '2Y revenue CAGR',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.revenueCagrPctTwoYears}
        year={params.row.revenueYear}
      />
    ),
  },
  {
    field: 'revenueCagrPctThreeYears',
    headerName: '3Y revenue CAGR',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.revenueCagrPctThreeYears}
        year={params.row.revenueYear}
      />
    ),
  },
  {
    field: 'grossMarginGrowthPctOneYear',
    headerName: '1Y gross margin growth',
    align: 'right',
    width: 204,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.grossMarginGrowthPctOneYear}
        year={params.row.grossMarginYear}
      />
    ),
  },
  {
    field: 'grossMarginCagrPctTwoYears',
    headerName: '2Y gross margin CAGR',
    align: 'right',
    width: 204,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.grossMarginCagrPctTwoYears}
        year={params.row.grossMarginYear}
      />
    ),
  },
  {
    field: 'grossMarginCagrPctThreeYears',
    headerName: '3Y gross margin CAGR',
    align: 'right',
    width: 204,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.grossMarginCagrPctThreeYears}
        year={params.row.grossMarginYear}
      />
    ),
  },
  {
    field: 'ebitdaGrowthPctOneYear',
    headerName: '1Y EBITDA growth',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.ebitdaGrowthPctOneYear}
        year={params.row.ebitdaYear}
      />
    ),
  },
  {
    field: 'ebitdaCagrPctTwoYears',
    headerName: '2Y EBITDA CAGR',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.ebitdaCagrPctTwoYears}
        year={params.row.ebitdaYear}
      />
    ),
  },
  {
    field: 'ebitdaCagrPctThreeYears',
    headerName: '3Y EBITDA CAGR',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.ebitdaCagrPctThreeYears}
        year={params.row.ebitdaYear}
      />
    ),
  },
  {
    field: 'ebitGrowthPctOneYear',
    headerName: '1Y EBIT growth',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.ebitGrowthPctOneYear}
        year={params.row.ebitYear}
      />
    ),
  },
  {
    field: 'ebitCagrPctTwoYears',
    headerName: '2Y EBIT CAGR',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.ebitCagrPctTwoYears}
        year={params.row.ebitYear}
      />
    ),
  },
  {
    field: 'ebitCagrPctThreeYears',
    headerName: '3Y EBIT CAGR',
    align: 'right',
    width: 160,
    renderCell: (params) => (
      <PercentageTableCell
        percentage={params.row.ebitCagrPctThreeYears}
        year={params.row.ebitYear}
      />
    ),
  },
  {
    field: 'region',
    headerName: 'Company HQ',
    align: 'center',
    width: 111,
    renderCell: (params) => (params.row.region ? <Flag code={params.row.region} /> : <>-</>),
  },
  {
    field: 'headquartersCity',
    headerName: 'HQ city',
    align: 'left',
    width: 216,
    sortFields: ['headquartersCity', 'headquartersCountryCode'],
    valueFormatter: ({ row }) =>
      formatCountryCity(row.headquartersCountryCode, row.headquartersCity, row.headquartersRegion),
  },
  {
    field: 'sector',
    headerName: 'Sector',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) => formatSector(value),
  },
  {
    field: 'subsector',
    headerName: 'Subsector',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) => formatSubsector(value),
  },

  // Ownership
  {
    field: 'yearFounded',
    headerName: 'Year founded',
    align: 'right',
    width: 128,
    valueFormatter: (params) => params.value || '-',
  },
  {
    field: 'ceoAge',
    headerName: 'CEO age',
    align: 'right',
    width: 128,
    valueFormatter: (params) => params.value || '-',
  },
  {
    field: 'ownership',
    headerName: 'Ownership type',
    align: 'left',
    width: 248,
    renderCell: (params) => (
      <AssetOwnershipTypeExplainer
        isVerified={params.row.ownershipIsVerified}
        ownershipType={params.value}
      />
    ),
  },
  {
    field: 'majorityOwnerName',
    headerName: 'Majority investor',
    align: 'left',
    width: 216,
    renderCell: (params) => <AssetListItemTableCellMajorityOwnerName {...params} />,
  },
  {
    field: 'ownerNames',
    headerName: 'Minority investor(s)',
    align: 'left',
    width: 216,
    sortable: false,
    renderCell: (params) => <AssetListItemTableCellMinorityInvestors {...params} />,
  },
  {
    field: 'addOnDealCountL5Y',
    headerName: 'Number of add-ons (L5Y)',
    align: 'left',
    width: 216,
    renderCell: (params) => params.value || '-',
  },
  {
    field: 'totalDealFundingRaisedEur',
    headerName: 'Total funding',
    align: 'left',
    width: 176,
    renderCell: ({ value }) => <FinancialValue amount={value} />,
  },
  {
    field: 'latestDealRoundType',
    headerName: 'Latest round',
    align: 'left',
    width: 176,
    renderCell: ({ value, row }) =>
      value ? (
        <>
          {formatDealFundingSeriesLabel(value)}

          {row.latestDealRoundYear && (
            <>
              &nbsp;
              <Chip
                label={formatYear(row.latestDealRoundYear, 'short')}
                size={'small'}
              />
            </>
          )}
        </>
      ) : (
        '-'
      ),
  },
  {
    field: 'latestDealRoundSizeEur',
    headerName: 'Latest round size',
    align: 'left',
    width: 176,
    renderCell: ({ value, row }) =>
      value ? (
        <>
          <FinancialValue amount={value} />

          {row.latestDealRoundYear && (
            <>
              &nbsp;
              <Chip
                label={formatYear(row.latestDealRoundYear, 'short')}
                size={'small'}
              />
            </>
          )}
        </>
      ) : (
        '-'
      ),
  },
  {
    field: 'latestDealPreMoneyValuationEur',
    headerName: 'Latest pre-money valuation',
    align: 'left',
    width: 240,
    renderCell: ({ value, row }) =>
      value ? (
        <>
          <FinancialValue amount={value} />

          {row.latestDealPreMoneyValuationYear && (
            <>
              &nbsp;
              <Chip
                label={formatYear(row.latestDealPreMoneyValuationYear, 'short')}
                size={'small'}
              />
            </>
          )}
        </>
      ) : (
        '-'
      ),
  },
  {
    field: 'latestDealPostMoneyValuationEur',
    headerName: 'Latest post-money valuation',
    align: 'left',
    width: 240,
    renderCell: ({ value, row }) =>
      value ? (
        <>
          <FinancialValue amount={value} />

          {row.latestDealPostMoneyValuationYear && (
            <>
              &nbsp;
              <Chip
                label={formatYear(row.latestDealPostMoneyValuationYear, 'short')}
                size={'small'}
              />
            </>
          )}
        </>
      ) : (
        '-'
      ),
  },
  // Next deal
  {
    field: 'predictedExitYear',
    headerName: 'Predicted exit year',
    align: 'right',
    width: 184,
    valueFormatter: (params) => params.value || '-',
  },
  {
    field: 'predictedExitEbitdaEur',
    headerName: 'Predicted EBITDA',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.predictedExitEbitdaEur}
        year={params.row.predictedExitYear}
      />
    ),
  },
  {
    field: 'predictedExitMultiple',
    headerName: 'Predicted multiple',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <MultipleTableCell
        value={params.value}
        year={params.row.predictedExitYear}
      />
    ),
  },
  {
    field: 'predictedExitEvEur',
    headerName: 'Predicted EV',
    align: 'right',
    width: 176,
    renderCell: (params) => (
      <FinancialValue
        amount={params.row.predictedExitEvEur}
        year={params.row.predictedExitYear}
      />
    ),
  },
  // Investment criteria
  createInvestmentCriteriaColumn('ratingGrowth', ratingGrowth.label, ratingGrowth.explainer),
  createInvestmentCriteriaColumn(
    'ratingOrganicGrowth',
    ratingOrganicGrowth.label,
    ratingOrganicGrowth.explainer
  ),
  createInvestmentCriteriaColumn(
    'ratingGrossMargin',
    ratingGrossMargin.label,
    ratingGrossMargin.explainer
  ),
  createInvestmentCriteriaColumn('ratingEbitda', ratingEbitda.label, ratingEbitda.explainer),
  createInvestmentCriteriaColumn(
    'ratingConversion',
    ratingConversion.label,
    ratingConversion.explainer
  ),
  createInvestmentCriteriaColumn(
    'ratingNonCyclical',
    ratingNonCyclical.label,
    ratingNonCyclical.explainer
  ),
  createInvestmentCriteriaColumn(
    'ratingContracted',
    ratingContracted.label,
    ratingContracted.explainer
  ),
  createInvestmentCriteriaColumn('ratingLeader', ratingLeader.label, ratingLeader.explainer),
  createInvestmentCriteriaColumn(
    'ratingMultinational',
    ratingMultinational.label,
    ratingMultinational.explainer
  ),
  createInvestmentCriteriaColumn(
    'ratingBuyAndBuild',
    ratingBuyAndBuild.label,
    ratingBuyAndBuild.explainer
  ),
  {
    field: 'esgOutperformer',
    headerName: 'ESG outperformer',
    headerExplainer:
      'Proactively addresses ESG risks related to the Company and the underlying industry segment.',
    align: 'left',
    width: 184,
    renderCell: ({ value }) =>
      !value ? (
        '-'
      ) : (
        <Chip
          color={'success'}
          icon={<LeafIcon color={'inherit'} />}
          label={'ESG outperformer'}
        />
      ),
  },
  {
    field: 'latestIndustryRatingOverall',
    headerName: ratingEsgOverall.label,
    headerExplainer: ratingEsgOverall.explainer,
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
        withHalfs
      />
    ),
  },
  {
    field: 'latestIndustryRatingEnvironmental',
    headerName: ratingEsgEnvironmental.label,
    headerExplainer: ratingEsgEnvironmental.explainer,
    align: 'left',
    width: 185,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
        withHalfs
      />
    ),
  },
  {
    field: 'latestIndustryRatingSocial',
    headerName: ratingEsgSocial.label,
    headerExplainer: ratingEsgSocial.explainer,
    align: 'left',
    width: 168,
    renderCell: (params) => (
      <RatingTableCell
        criteria={ratingEsg}
        rating={params.value}
        withHalfs
      />
    ),
  },
  {
    field: 'customerBase',
    headerName: 'Customer base',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) =>
      value.map((type) => formatAssetCustomerBaseLabel(type)).join(', ') || '-',
  },
  {
    field: 'businessActivity',
    headerName: 'Business activity',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) =>
      value ? value.map((type) => formatAssetBusinessActivityLabel(type)).join(', ') : '-',
  },
  {
    field: 'salesChannel',
    headerName: 'Sales channel',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) =>
      value?.length
        ? value.map((salesChannel) => formatAssetSalesChannelLabel(salesChannel)).join(', ')
        : '-',
  },
  {
    field: 'pricePositioning',
    headerName: 'Price positioning',
    align: 'left',
    width: 184,
    valueFormatter: ({ value }) => (value ? formatAssetPricePositioningLabel(value) : '-'),
  },
  {
    field: 'publishedAt',
    headerName: 'Published',
    align: 'left',
    width: 152,
    valueFormatter: (params) => (typeof params.value === 'string' ? formatDate(params.value) : '-'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updates',
    align: 'left',
    width: 232,
    sortFields: ['financialsAt'],
    renderCell: (params) => <AssetListItemTableCellUpdatedDate {...params} />,
  },
  {
    field: 'financialsAt',
    headerName: 'Latest financials',
    align: 'left',
    width: 152,
    valueFormatter: (params) => (typeof params.value === 'string' ? formatDate(params.value) : '-'),
  },
]

export const assetTableColumnNames = assetListItemTableColumns
  .map(({ field }) => field)
  .filter(Boolean) as (keyof AssetListItem)[]
