import { ListedSecurityValuation } from '@gain/rpc/app-model'
import { isDefined } from '@gain/utils/common'
import { formatRatio } from '@gain/utils/number'
import { useTheme } from '@mui/material/styles'
import { LineSeriesOption } from 'echarts/charts'
import { OptionDataValue } from 'echarts/types/src/util/types'
import { useMemo } from 'react'

import { ECOption } from '../../../../common/echart/echart'
import { RatioKey } from './ratio-utils'

const BOUNDARY_GAP_X = 0.04
const BOUNDARY_GAP_Y = 0.08
const YEAR = 3600 * 24 * 1000 * 365
const TRIANGLE_SYMBOL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAABeUlEQVQ4Ea2SsUoDQRCGZ+4O0U6wEHwK9QE0XZ7jEGtjaaGc6AOc2ArGSuwOu2hh2MpEUIRYiAqbxtioJwqKmKz3j7mwycUI6jQ7++/st//uLNE/BINRCLZHPfdj8rc8DxuZm0HT8MKfIMYxEbchE+NjNDI89CPvIX6hx6dn1Gm5DrLFta0jMpTLz0xRfnYa0sBY39wjgAyx76SVhswq8pI6k8VU7zeqykVao8OVuWIHEi7PlxPsBjbt7qt+e0XD6apakzxxIQd3IFCNcYNkiG/qDbrWDUiZKKlTccEOF+ECBV2QMPBjY4y4iQ4rGQBcnJxfid5yHXGRgciq8cKk6fr27p5wdzuig2OZioslX6drXU4gihtu+chh/fXtHak4qF3WkWrbBYQMBKI8MlMZAFX5ekQAEXjM0HIBTX4skt5Ay5k4p6q4Ene1tLe2rxMUtVu+Aze2i14A5t9CsJi0vJAMMXJmitKWYm7HQIg8svxk1i3PXbQ32vknqsGntaQMDL8AAAAASUVORK5CYII='

export interface RatioDataSeriesPoint {
  date: string
  ratio: number
}
export type RatioDataSeries = Array<RatioDataSeriesPoint>

/**
 * useActiveRatioSeries returns the RatioDataSeries (used as datasource for
 * the actual chart) for the given key.
 */
function useActiveRatioSeries(
  activeRatioKey: RatioKey | undefined,
  valuations: ListedSecurityValuation[]
): RatioDataSeries {
  return useMemo(() => {
    if (!activeRatioKey) {
      return []
    }

    return valuations.reduce((acc, current) => {
      const ratio = current[activeRatioKey]

      if (isDefined(ratio)) {
        acc.push({
          date: current.date,
          ratio: ratio,
        })
      }

      return acc
    }, new Array<RatioDataSeriesPoint>())
  }, [activeRatioKey, valuations])
}

/**
 * getOutliersByZScore removes values from the series using a z-score algorithm.
 * Since we're never interested in values of zero or below, those will always
 * get marked as an outlier.
 */
function getOutliersByZScore(data: RatioDataSeries, threshold = 3): RatioDataSeries {
  // Extract the numeric values
  const values = data.filter((point) => point.ratio > 0).map((point) => point.ratio)

  // Calculate the mean
  const mean = values.reduce((sum, value) => sum + value, 0) / values.length

  // Calculate the standard deviation
  const stdDev = Math.sqrt(
    values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length
  )

  // Calculate the Z-score for each value and filter the outliers
  return data.filter((point) => {
    if (point.ratio <= 0) {
      return true
    }

    const zScore = (point.ratio - mean) / stdDev
    return Math.abs(zScore) > threshold
  })
}

/**
 * getMaxYAxisValue returns max y-axis value when there are positive outlier
 * values. A sensible default when there are only outliers, and undefined when
 * there are no outliers (to allow echarts to determine the actual range).
 */
function getMaxYAxisValue(series: RatioDataSeries, outliers: RatioDataSeries) {
  // When there are only outliers, we'll plot an arbitrary y-scale of 0 to 0.4
  if (outliers.length === series.length) {
    return 0.4
  }

  const outlierValues = outliers.map((point) => point.ratio)
  const seriesValues = series.map((point) => point.ratio)
  const seriesWithoutOutliers = seriesValues.filter((value) => !outlierValues.includes(value))
  const hasPositiveOutliers = outlierValues.some((value) => value > 0)

  const max = Math.max(...seriesWithoutOutliers)

  if (!hasPositiveOutliers) {
    return undefined
  }

  // Max y-axis value equals the max non outlier value with a little padding to
  // ensure that plotted values comfortably fit in the chart
  const padding = max * BOUNDARY_GAP_Y
  return max + padding
}

/**
 * getChartLines returns an array of lines that is used to render lines between
 * non-outlier points on the chart. It also flags lines with outlier values in between.
 */
function getChartLines(series: RatioDataSeries, outliers: RatioDataSeries) {
  const lines = new Array<Line>()
  let lastPoint: RatioDataSeriesPoint | null = null
  let hasOutliers = false

  for (let i = 0; i < series.length; i++) {
    const currentPoint = series[i]

    // Keep track of outliers between to points
    if (isOutlier(currentPoint, outliers)) {
      // Skip outliers at the beginning
      if (lines.length === 0) {
        continue
      }

      hasOutliers = true
      continue
    }

    // Store last point and current point as a line
    if (lastPoint !== null) {
      lines.push({
        start: lastPoint,
        end: currentPoint,
        hasOutliers: hasOutliers,
      })
    }

    lastPoint = currentPoint
    hasOutliers = false
  }

  return lines
}

function isOutlier(point: RatioDataSeriesPoint, outliers: RatioDataSeries) {
  return outliers.some((outlier) => outlier.date === point.date)
}

interface OptionDateAxisWithYAxis extends Record<string, OptionDataValue> {
  yAxis: number
}

interface Line {
  start: RatioDataSeriesPoint
  end: RatioDataSeriesPoint
  hasOutliers: boolean
}

export default function useRatiosChartOption(key: RatioKey, valuations: ListedSecurityValuation[]) {
  const series = useActiveRatioSeries(key, valuations)
  const theme = useTheme()

  return useMemo((): ECOption => {
    const outliers = getOutliersByZScore(series)
    const seriesWithoutOutliers = series.filter((point) => !isOutlier(point, outliers))
    const lines = getChartLines(series, outliers)

    const min = 0 // Any ratio below or equal to 0 is considered not meaningful
    const max = getMaxYAxisValue(series, outliers)

    return {
      animationDuration: theme.transitions.duration.enteringScreen,

      grid: [
        {
          containLabel: true,
          left: 0, // Pin to the left side
          top: theme.spacing(1), // Make sure the labels are not cut off
          right: theme.spacing(4), // Make sure the labels are not cut off
          bottom: 0,
        },
      ],

      xAxis: {
        type: 'time',
        interval: YEAR,
        minInterval: YEAR,
        maxInterval: YEAR,

        axisTick: {
          show: true,
        },

        // Subtract 4% of the range from the min value to create a small amount
        // of spacing on the left side before the actual plotted values start
        min: (value) => {
          return value.min - (value.max - value.min) * BOUNDARY_GAP_X
        },

        // Add 4% of the range to the max value to create a small amount
        // of spacing on the right side
        max: (value) => {
          return value.max + (value.max - value.min) * BOUNDARY_GAP_X
        },
      },

      yAxis: {
        type: 'value',
        position: 'right', // Shows labels on the right side
        splitNumber: 5,

        // Min and/or max are capped if we detect outliers in the series
        min,
        max,

        animationDuration: 10,

        splitLine: {
          lineStyle: {
            color: theme.palette.grey[200],
          },
        },

        axisLabel: {
          formatter: (value) => formatRatio(value),
          // showMaxLabel: !isDefined(max),
        },
      },

      series: [
        // Create a new series for each line which allows us to change the line
        // style per line. A single series for all points doesn't allow this
        // type of customization
        ...lines.map(
          (line): LineSeriesOption => ({
            type: 'line',

            data: [
              {
                value: [line.start.date, line.start.ratio],
              },
              {
                value: [line.end.date, line.end.ratio],
              },
            ],

            // Lines that intersect with outliers are dashed grey and solild blue
            // when there are no outliers.
            lineStyle: {
              type: line.hasOutliers ? 'dashed' : 'solid',
              color: line.hasOutliers ? theme.palette.grey[300] : theme.palette.info.main,
            },

            // Symbols (dots) are plotted in another series. This ensures that
            // a dot is always rendered even when there is only 1 data point.
            showSymbol: false,

            // The cursor prop only works on the plotted point.
            // https://github.com/apache/echarts/issues/18024 (slightly related)
            // The cursor is forced to default in the containing div
            cursor: 'default',
          })
        ),

        // Series to render dots and outlier mark points.
        {
          type: 'line',

          symbol: 'circle',
          symbolSize: 8,
          color: theme.palette.info.main,
          emphasis: {
            disabled: true,
          },
          itemStyle: {
            borderColor: 'transparent',
            borderWidth: 4,
          },

          lineStyle: {
            color: 'transparent',
          },
          data: seriesWithoutOutliers.map((point) => ({ value: [point.date, point.ratio] })),

          // Outliers are rendered as mark points with a triangle symbol
          markPoint: {
            symbol: `image://${TRIANGLE_SYMBOL}`,
            symbolSize: 8.5,
            // Rotate the symbol when it's a positive outlier
            symbolRotate: (_, params) => {
              const data = params.data as OptionDateAxisWithYAxis
              return data.yAxis > 0 ? 180 : 0
            },
            // Offset symbol a bit above the 0 axis line or a bit underneath the
            // max y-axis value line
            symbolOffset: (_, params) => {
              const data = params.data as OptionDateAxisWithYAxis
              return data.yAxis > 0 ? [0, 12] : [0, -12]
            },
            symbolKeepAspect: true,
            label: {
              show: false,
            },
            itemStyle: {
              color: theme.palette.grey[300],
            },
            data: outliers.map((point) => ({
              name: point.date,
              // Plot the mark point at the minimum y-axis value (0) for negative
              // outliers and at the maximum y-axis value for positive outliers
              yAxis: point.ratio <= 0 ? min : max,
              xAxis: point.date,
              value: point.ratio,
            })),
          },
        },

        // Hidden series to make sure the entire date range is plotted on the
        // x-axis. This is needed to ensure that markPoints (outliers) are
        // always rendered, even when the date of the outlier falls outside
        // the date range of valid values
        {
          type: 'line',
          showSymbol: false,

          lineStyle: {
            color: 'transparent',
          },
          data: series.map((point) => ({ value: [point.date, point.ratio] })),
        },
      ],
    }
  }, [series, theme])
}
