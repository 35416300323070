import { nanoid } from '@reduxjs/toolkit'

export interface JsonRpcRequest<Params = any | undefined> {
  Jsonrpc: string
  id: string
  method: string
  params?: Params
}

export interface BaseJsonRpcResponse {
  id: string
  jsonrpc: string
}

export type JsonRpcError = {
  code: number
  message: string
  data?: any
}

export interface JsonRpcErrorResponse extends BaseJsonRpcResponse {
  error: JsonRpcError
}

export interface JsonRpcSuccessResponse<T = any> extends BaseJsonRpcResponse {
  result: T
}

export type JsonRpcResponse<T = any> = JsonRpcErrorResponse | JsonRpcSuccessResponse<T>

export const createMethod = <Params = any | undefined | never>(
  method: string,
  params?: Params
): JsonRpcRequest<Params> => ({
  Jsonrpc: '2.0',
  id: nanoid(),
  method,
  params,
})
