import { isDefined } from '@gain/utils/common'
import { useMemo } from 'react'

/**
 * Splits a list into two lists, where the first list contains the first `maxNrOfInitialItems`
 * items and the second list contains the rest of the items.
 *
 * When the list is split it returns 1 fewer rows than maxNrOfInitialItems to make room
 * for the "show more" row.
 */
export function useSplitList<Item>(
  items: Item[] | undefined | null,
  maxNrOfInitialItems: number
): Readonly<[Item[], Item[]]> {
  return useMemo(() => {
    if (!isDefined(items)) {
      return [new Array<Item>(), new Array<Item>()] as const
    }

    // Add one to check if it makes sense showing "More <X>"
    if (items.length > maxNrOfInitialItems + 1) {
      return [items.slice(0, maxNrOfInitialItems), items.slice(maxNrOfInitialItems)] as const
    }

    return [items, new Array<Item>()] as const
  }, [items, maxNrOfInitialItems])
}
