import { useCompetitorList } from '@gain/api/app/hooks'
import { AssetCompetitor, CompetitorListItem } from '@gain/rpc/app-model'
import { ListArgs } from '@gain/rpc/list-model'
import { listFilter, listSort } from '@gain/rpc/utils'
import { useCallback } from 'react'

export function useAssetCompetitors(competitors: AssetCompetitor[]) {
  return useCompetitorList(
    useCallback((): Partial<ListArgs<CompetitorListItem>> | null => {
      if (competitors.length === 0) {
        return null
      }

      return {
        filter: [
          listFilter(
            'id',
            '=',
            competitors.map((comp) => comp.competitorId)
          ),
        ],
        limit: competitors.length,
        sort: [listSort('revenue', 'desc')],
      }
    }, [competitors])
  )
}
