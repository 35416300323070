import { SettingsMenu } from '@app/layout/navigation/settings-menu'
import { useUserProfile } from '@gain/api/app/hooks'
import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'
import { capitalize } from '@mui/material/utils'
import React, { useMemo } from 'react'

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.primary.main,
  ...theme.typography.subtitle2,
  backgroundColor: '#E9EBFE',
  border: '0px solid transparent',
}))

export default function UserAvatar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { data: userProfile } = useUserProfile()
  const initials = useMemo(() => {
    if (!userProfile) {
      return ''
    }
    return `${capitalize(userProfile.firstName.charAt(0))}${capitalize(
      userProfile.lastName.charAt(0)
    )}`
  }, [userProfile])

  return (
    <>
      <SettingsMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      />
      <StyledAvatar onClick={(event) => setAnchorEl(event.currentTarget)}>{initials}</StyledAvatar>
    </>
  )
}
