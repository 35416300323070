import { useSimilarAssets } from '@gain/api/app/hooks'
import { Asset } from '@gain/rpc/app-model'
import { useElementWidth } from '@gain/utils/dom'
import { styled } from '@mui/material/styles'
import { useMemo, useRef } from 'react'

import ButtonMore from '../../../../common/button-more'
import Card, { CardHeader, CardProps } from '../../../../common/card/card'
import { ASSET_PAGE_MARKET_PATH, generateAssetPagePath, useAssetPageParams } from '../../../utils'
import { useAssetCompetitors } from '../../use-asset-competitors'
import AssetMarketSimilarCompanies from './asset-market-similar-companies'
import AssetMarketTable from './asset-market-table'

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
})

export type AssetMarketCardProps = CardProps & {
  asset: Asset
}

export default function AssetMarketCard({ asset, ...rest }: AssetMarketCardProps) {
  const params = useAssetPageParams()
  const ref = useRef<HTMLDivElement>(null)
  const width = useElementWidth<HTMLDivElement>(ref)
  const swrCompetitors = useAssetCompetitors(asset.competitors)
  const swrSimilarAssets = useSimilarAssets({ likeAssetId: asset.id, limit: 300 })

  let maxItems = swrCompetitors.data.items.length
  if (swrCompetitors.data.items.length > 5) {
    maxItems = 4
  }

  const visibleCompetitors = useMemo(
    () => swrCompetitors.data.items.slice(0, maxItems),
    [swrCompetitors.data.items, maxItems]
  )

  const nrOfAdditionalCompetitorItems = swrCompetitors.data.items.length - visibleCompetitors.length

  const href = generateAssetPagePath(
    {
      id: asset.id,
      name: asset.generalInfo?.name,
      preview: params.preview,
    },
    ASSET_PAGE_MARKET_PATH
  )

  return (
    <StyledCard
      {...rest}
      ref={ref}
      href={
        visibleCompetitors.length !== 0 || swrSimilarAssets.data?.items.length !== 0
          ? href
          : undefined
      }>
      <CardHeader title={'Market'} />
      {(visibleCompetitors.length > 0 || swrCompetitors.loading) && (
        <AssetMarketTable
          disableHqColumn={width < 296}
          rows={visibleCompetitors}
        />
      )}

      <ButtonMore
        amount={nrOfAdditionalCompetitorItems}
        href={href}
      />

      {!swrCompetitors.loading && swrCompetitors.empty && (
        <AssetMarketSimilarCompanies
          assetList={swrSimilarAssets.data?.items || []}
          loading={swrSimilarAssets.loading}
        />
      )}
    </StyledCard>
  )
}
