import { isDefined, valueToEnum } from '@gain/utils/common'

import { CEO_POSITIONS } from './manager.constants'
import { ManagerPosition } from './manager.model'

export const isCeo = (position: string | null | undefined) => {
  if (!isDefined(position)) {
    return false
  }

  const parsed = valueToEnum(ManagerPosition, position.toLowerCase())

  if (!isDefined(parsed)) {
    return false
  }

  return CEO_POSITIONS.includes(parsed)
}
