import { InfoIcon } from '@gain/components/icons'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledBetaChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'small',
})<{ small?: boolean }>(({ theme, small }) => ({
  // Normal chip has a transparency in its background color value which we don't want here.
  ...(small && { backgroundColor: `${theme.palette.warning.light} !important` }),
}))

interface BetaChipProps {
  showInfoIcon?: boolean
  small?: boolean
  className?: string
}

export default function BetaChip({
  showInfoIcon = false,
  small = false,
  className,
}: BetaChipProps) {
  return (
    <StyledBetaChip
      className={className}
      color={'warning'}
      icon={showInfoIcon ? <InfoIcon /> : undefined}
      label={'Beta'}
      size={small ? 'small' : 'medium'}
      small={small}
    />
  )
}
