import { useFindCurrencyCallback } from '@gain/api/app/hooks'
import { AssetList, Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import { sortByVintageDateDesc } from '@gain/utils/investor-fund'
import { useSplitList } from '@gain/utils/list'
import { isNotEmpty, isNotNull } from '@gain/utils/typescript'
import { median } from 'd3'
import React, { useMemo } from 'react'

import ButtonMore from '../../../../common/button-more'
import Card, { CardHeader } from '../../../../common/card/card'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_STRATEGIES_PATH,
  useInvestorPageParams,
} from '../../../utils'
import InvestorStrategiesTable from '../../route-strategies/investor-strategies-table'

export interface InvestorStrategiesCardProps {
  investor: Investor
  strategies: InvestorProfileStrategy[]
  assets: AssetList
}

export default function InvestorStrategiesCard({
  investor,
  strategies,
  assets,
}: InvestorStrategiesCardProps) {
  const params = useInvestorPageParams()
  const findCurrency = useFindCurrencyCallback()

  const checkedStrategies = useMemo(() => {
    if (strategies.length > 0) {
      return strategies
    }

    // get latest non-continuation fund not linked to a strategy
    const latestFund = investor.funds
      .filter((fund) => !fund.continuationFund && fund.investorStrategyId === null)
      .sort(sortByVintageDateDesc)
      .shift()

    // determine latest fund currency conversion
    let latestFundToEur = 1
    if (latestFund) {
      const latestFundCurrency = findCurrency(latestFund.currency)
      latestFundToEur = latestFundCurrency?.toEur || 1
    }

    // calculate dry powder in fund currency
    const getDryPowderToEur = (value: number | null | undefined) => {
      if (typeof value === 'number') {
        return value * latestFundToEur
      }
      return null
    }

    return new Array<InvestorProfileStrategy>({
      id: -1,
      name: 'Single strategy',
      latestFundName: latestFund?.name || null,
      latestFundSizeEur: latestFund?.fundSizeEur || null,
      latestFundVintageYear: latestFund?.vintageDate?.year || null,
      latestFundVintageMonth: latestFund?.vintageDate?.month || null,
      assetCount: assets.counts.filtered,
      assetIds: assets.items.map((asset) => asset.id),
      assetEbitdaEurMedian: median(assets.items.map((asset) => asset.ebitdaEur)) || 0,
      assetRegions: assets.items.map((asset) => asset.region).filter(isNotNull),
      assetSectors: assets.items.map((asset) => asset.sector).filter(isNotEmpty),
      latestFundDryPowderMinEur: getDryPowderToEur(latestFund?.dryPowderMin),
      latestFundDryPowderMaxEur: getDryPowderToEur(latestFund?.dryPowderMax),
    })
  }, [assets, investor.funds, strategies, findCurrency])

  const [initial, rest] = useSplitList(checkedStrategies, 4)
  const href = useMemo(() => {
    if (strategies.length === 0) {
      return undefined
    }

    return generateInvestorPagePath(params, INVESTOR_PAGE_STRATEGIES_PATH)
  }, [strategies.length, params])

  return (
    <Card
      href={href}
      fullHeight>
      <CardHeader title={'Strategies'} />
      <InvestorStrategiesTable
        investor={investor}
        rows={initial}
        dense
        hideDryPowderInfo
      />

      <ButtonMore
        amount={rest.length}
        href={generateInvestorPagePath(params, INVESTOR_PAGE_STRATEGIES_PATH)}
        variant={'plus'}
      />
    </Card>
  )
}
