import { InvestorFundFundraisingStatus } from './lib/rpc-shared-model'
import { Option } from './shared-model-interfaces'

export const INVESTOR_FUND_FUNDRAISING_STATUS_OPTIONS: ReadonlyArray<
  Option<InvestorFundFundraisingStatus>
> = [
  {
    label: 'Launched',
    value: InvestorFundFundraisingStatus.Launched,
  },
  {
    label: 'First close',
    value: InvestorFundFundraisingStatus.FirstClose,
  },
  {
    label: 'Final close',
    value: InvestorFundFundraisingStatus.FinalClose,
  },
  {
    label: 'Closed',
    value: InvestorFundFundraisingStatus.Closed,
  },
]
