import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import format from 'date-fns/format'

interface FormatWeekOptions {
  thisWeekLabel: string
}

export const formatWeek = (value: Date, options?: FormatWeekOptions) => {
  const thisWeekLabel = options?.thisWeekLabel || 'This week'
  const now = new Date()

  const weeks = differenceInCalendarWeeks(now, value)

  if (weeks === 0) {
    return thisWeekLabel
  } else if (weeks === 1) {
    return 'Last week'
  } else if (weeks > 1 && weeks < 4) {
    return `${weeks} weeks ago`
  }

  return format(value, 'MMM, yyyy')
}
