import { BriefcaseIcon, CompanyIcon, LeafIcon } from '@gain/components/icons'
import { SECTORS, SUBSECTORS } from '@gain/utils/sector'

import { investorListItemTableColumns } from '../investor/investor-list-item-table/investor-list-item-table-columns'
import { TopLevelColumnGroup } from './column-picker-model'
import { fromConfig, getRegionAndCountrySearchAliases } from './column-picker-util'

export const columnPickerConfigInvestor = new Array<TopLevelColumnGroup>(
  {
    name: 'Key facts',
    Icon: CompanyIcon,
    columns: [
      fromConfig(investorListItemTableColumns, 'name', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }),
      fromConfig(investorListItemTableColumns, 'operationalHqCountryCode', {
        visibleByDefault: true,
        searchAliases: getRegionAndCountrySearchAliases(),
      }), // Investor HQ
      fromConfig(investorListItemTableColumns, 'flagshipFundSizeEur', {
        visibleByDefault: true,
      }), // Flagship fund size
      fromConfig(investorListItemTableColumns, 'fundsRaisedLastFiveYearsEur', {
        visibleByDefault: true,
      }), // Fundraising (L5Y)
      fromConfig(investorListItemTableColumns, 'flagshipFundName', {
        visibleByDefault: true,
      }), // Flagship fund
    ],
  },
  {
    name: 'Portfolio',
    Icon: BriefcaseIcon,
    columns: [
      fromConfig(investorListItemTableColumns, 'assetsTotal', {
        visibleByDefault: true,
        canBeHiddenByUser: false,
      }), // Companies
      fromConfig(investorListItemTableColumns, 'assetEbitdasEur', {
        visibleByDefault: true,
      }), // Company EBITDA range
      fromConfig(investorListItemTableColumns, 'assetEbitdaMedianEur', {
        visibleByDefault: true,
      }), // Median EBITDA
      fromConfig(investorListItemTableColumns, 'assetRegions', {
        visibleByDefault: true,
        searchAliases: getRegionAndCountrySearchAliases(),
      }), // Active in region
      fromConfig(investorListItemTableColumns, 'assetSectors', {
        visibleByDefault: true,
        searchAliases: [
          ...SECTORS.map((value) => value.title),
          ...SUBSECTORS.map((value) => value.title),
        ],
      }), // Active in sector
    ],
  },
  {
    name: 'ESG',
    Icon: LeafIcon,
    columns: [
      fromConfig(investorListItemTableColumns, 'assetsMedianMarketSegmentRatingOverall'), // Overall ESG risk
      fromConfig(investorListItemTableColumns, 'assetsMedianMarketSegmentRatingEnvironmental'), // Environmental risk
      fromConfig(investorListItemTableColumns, 'assetsMedianMarketSegmentRatingSocial'), // Social risk
    ],
  }
)
