import { PlusIcon, ResetIcon } from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import { AssetListItem } from '@gain/rpc/app-model'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import Button from '@mui/material/Button'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import React, { FunctionComponent, useRef, useState } from 'react'

import Card, { CardHeader } from '../../common/card/card'
import ConditionalWrapper from '../../common/conditional-wrapper'
import InfoButton from '../../common/info-button'
import SearchInput from '../../common/search/search-input'
import VirtualTable, { VirtualTableProps } from '../../common/virtual-table/virtual-table'
import AddAssetsDialog from '../add-assets-dialog'
import useBenchmarkingColumns from './benchmarking-table-columns'

const columnVisibilityModel: ColumnVisibilityModel<AssetListItem> = {
  description: 1120,
  ebitdaPctRevenue: 870,
  revenueEur: 710,
}

const styledVirtualTableClasses = generateUtilityClasses('StyledVirtualTable', ['missingData'])

const StyledVirtualTable = styled(
  VirtualTable as FunctionComponent<VirtualTableProps<AssetListItem>>
)(({ theme }) => ({
  [`& .${styledVirtualTableClasses.missingData} .${tableCellClasses.root}`]: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[500],
    '*': {
      color: theme.palette.grey[500],
    },
  },
}))

const StyledRoot = styled(Card)(({ theme }) => ({
  maxHeight: 628,
  gap: theme.spacing(2),
}))

interface BenchmarkingTableProps extends Pick<VirtualTableProps<AssetListItem>, 'onSort' | 'sort'> {
  assets: AssetListItem[]
  selectedAssetIds: number[]
  onSelectAssetId: (assetId: number, isChecked: boolean) => void
  missingDataAssetIds: number[]
  onAddAssets: (newAssetIds: number[]) => Promise<void>
  onReset: () => void
  hasChanges: boolean
  onSearch: (query: string | null) => void
}

export default function BenchmarkingTable({
  assets,
  selectedAssetIds,
  onSelectAssetId,
  hasChanges,
  missingDataAssetIds,
  onAddAssets,
  onReset,
  sort,
  onSort,
  onSearch,
}: BenchmarkingTableProps) {
  const [openAddAssetsDialog, setOpenAddAssetsDialog] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const columns = useBenchmarkingColumns(selectedAssetIds, missingDataAssetIds, onSelectAssetId)
  const visibleColumns = useVisibleColumns(ref, columns, columnVisibilityModel)

  return (
    <StyledRoot ref={ref}>
      <CardHeader
        actions={
          <Stack
            direction={'row'}
            gap={0.5}>
            <Stack
              direction={'row'}
              gap={1}>
              <Button
                color={'primary'}
                onClick={() => setOpenAddAssetsDialog(true)}
                variant={'outlined'}
                autoFocus>
                <PlusIcon /> Add company
              </Button>

              <SearchInput
                initialValue={''}
                onChange={onSearch}
              />
            </Stack>

            <ConditionalWrapper
              condition={hasChanges}
              wrapper={(children) => (
                <Tooltip
                  placement={'top'}
                  title={'Reset toggles and added companies'}>
                  <span>{children}</span>
                </Tooltip>
              )}>
              <IconButton
                color={'primary'}
                disabled={!hasChanges}
                onClick={onReset}>
                <ResetIcon />
              </IconButton>
            </ConditionalWrapper>
          </Stack>
        }
        endAdornment={
          assets.length >= 500 ? (
            <InfoButton
              color={'warning'}
              dialogMessage={'Displaying the top 500 companies by FTE count.'}
              dialogTitle={'Limited results'}
              label={'Limited results'}
              sx={{ ml: 1 }}
            />
          ) : null
        }
        title={'Benchmarking input'}
      />

      <StyledVirtualTable
        columns={visibleColumns}
        onSort={onSort}
        RowComponentProps={({ row }) => {
          let className = ''
          if (missingDataAssetIds.includes(row.id)) {
            className = styledVirtualTableClasses.missingData
          }

          return {
            hover: false,
            className,
          }
        }}
        rows={assets}
        sort={sort}
        tableEmptyProps={{
          message: 'Please change your search query',
          title: 'No companies',
        }}
        variant={'inline'}
      />

      <AddAssetsDialog
        buttonText={'Add companies'}
        onClose={() => setOpenAddAssetsDialog(false)}
        onSave={onAddAssets}
        open={openAddAssetsDialog}
        title={'Add companies to the benchmark'}
      />
    </StyledRoot>
  )
}
