import { useLocalStorage } from '@gain/utils/storage'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createContext, ReactNode, useContext } from 'react'

type MenuState = ReturnType<typeof useLocalStorage<boolean>>
const MenuContext = createContext<MenuState | null>(null)

/**
 * useMenuContext is used to get and set if the main navigation menu is visible.
 */
export function useMenuContext() {
  const menu = useContext(MenuContext)

  if (menu === null) {
    throw new Error('MenuContext not provided')
  }

  return menu
}

interface MenuProviderProps {
  children: ReactNode
}

export default function MenuProvider({ children }: MenuProviderProps) {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))

  // Maintain menu visibility state for SM under a different key
  const key = ['show-menu', isSm ? 'sm' : null].filter(Boolean).join('-')

  // Menu state must persist over browser sessions
  const api = useLocalStorage(key, !isSm)

  return <MenuContext.Provider value={api}>{children}</MenuContext.Provider>
}
