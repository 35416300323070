import OverflowContainer from '@gain/components/overflow-container'
import { AssetListItem } from '@gain/rpc/app-model'
import React, { useMemo } from 'react'

import { Tag } from '../../../common/tag'
import { ASSET_LIST_PATH } from '../../../routes/utils'
import {
  autocompleteIncludeFilterValue,
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../filter/filter-bar'

export interface SimilarMatchingTagsProps {
  matchingTagIds: number[]
  tagIds: number[]
  tags: string[]
}

export function SimilarMatchingTags({ matchingTagIds, tagIds, tags }: SimilarMatchingTagsProps) {
  return useMemo(() => {
    if (matchingTagIds.length === 0) {
      return <>-</>
    }

    const matchingTags = matchingTagIds.reduce((acc, tagId) => {
      const index = tagIds.indexOf(tagId)
      if (index !== -1) {
        acc.push({ id: tagId, name: tags[index] })
      }
      return acc
    }, new Array<{ id: number; name: string }>())

    return (
      <OverflowContainer
        chipSize={'small'}
        lineHeight={16}
        maxLines={1}
        disableObserver>
        {matchingTags.map((tag) => (
          <Tag
            key={tag.id}
            color={'info'}
            href={[
              ASSET_LIST_PATH,
              filterModelToQueryString<AssetListItem, 'tagIds'>([
                filterValueGroup(
                  filterValueItem('tagIds', autocompleteIncludeFilterValue([tag.id]))
                ),
              ]),
            ].join('?')}
            label={tag.name}
          />
        ))}
      </OverflowContainer>
    )
  }, [matchingTagIds, tagIds, tags])
}
