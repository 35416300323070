import { AssetListItem } from '@gain/rpc/app-model'
import { MouseEvent, useCallback, useMemo } from 'react'

import Card, { CardContentEmpty, CardHeader } from '../../../../common/card/card'
import TableValueDistribution, {
  ASSET_VALUE_SHARE_REGION_CONFIG,
  useValueDistributionRows,
  ValueDistributionRow,
} from '../../../../common/table-value-distribution'
import { useOpenLink } from '../../../../common/use-open-link'
import {
  filterModelToQueryString,
  filterValueGroup,
  filterValueItem,
} from '../../../../features/filter/filter-bar'
import {
  generateInvestorPagePath,
  INVESTOR_PAGE_PORTFOLIO_PATH,
  useInvestorPageParams,
} from '../../../utils'

export interface InvestorPortfolioSectorCardProps {
  assets: AssetListItem[]
}

export default function InvestorPortfolioRegionCard({ assets }: InvestorPortfolioSectorCardProps) {
  const params = useInvestorPageParams()
  const openLink = useOpenLink()
  const rows = useValueDistributionRows(assets, ASSET_VALUE_SHARE_REGION_CONFIG)

  const formattedRows = useMemo(() => {
    return rows.reduce((acc, current) => {
      if (current.values?.length === 1) {
        const item = current.values[0]
        return acc.concat({
          ...current,
          label: item.label,
          type: item.type,
        })
      }

      return acc.concat(current)
    }, new Array<ValueDistributionRow>())
  }, [rows])

  const handleRowClick = useCallback(
    (row: ValueDistributionRow, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      const regions = row.values ? row.values.map((item) => item.type) : row.type

      const path = [
        generateInvestorPagePath(params, INVESTOR_PAGE_PORTFOLIO_PATH),
        filterModelToQueryString<AssetListItem, 'region'>([
          filterValueGroup(filterValueItem('region', regions)),
        ]),
      ].join('?')
      openLink(path, event)
    },
    [openLink, params]
  )

  return (
    <Card
      sx={{ minHeight: 304 }}
      fullHeight>
      <CardHeader title={'Portfolio region'} />
      {formattedRows.length === 0 ? (
        <CardContentEmpty message={'No data available'} />
      ) : (
        <TableValueDistribution
          itemLabel={'Region'}
          onRowClick={handleRowClick}
          rows={formattedRows}
          shareLabel={'Companies'}
          disableExpand
        />
      )}
    </Card>
  )
}
