import Typography from '@gain/components/typography'
import { formatInvestmentCriteria, RatingGroup } from '@gain/utils/investment-criteria'
import Collapse from '@mui/material/Collapse'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { Fragment, memo, useCallback, useState } from 'react'

import MenuExpandIcon from '../../../common/menu-expand-icon'
import Rating from '../../../common/rating'
import RatingLabel from '../../../common/rating/rating-label'
import { TableRatingColumn } from './table-rating.model'

const LABEL_CELL_WIDTH = 194

const ratingTableClasses = generateUtilityClasses('RatingTable', [
  'groupValueLabelCell',
  'expanded',
])

const StyledTableBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 0),
}))

const StyledTableRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
})

const StyledTableValueRow = styled(StyledTableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey['50'],
    [`& .${ratingTableClasses.groupValueLabelCell}`]: {
      backgroundColor: theme.palette.grey['50'],
    },
  },
}))

const StyledTableCell = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body2,
}))

const StyledLabelCell = styled(StyledTableCell)(({ theme }) => ({
  width: LABEL_CELL_WIDTH,
  minWidth: LABEL_CELL_WIDTH,
  maxWidth: LABEL_CELL_WIDTH,
  alignItems: 'center',
  position: 'sticky',
  left: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}))

const StyledGroupLabelCell = styled(StyledLabelCell)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: '5px 24px',
}))

const StyledGroupValueLabelCell = styled(StyledLabelCell)({
  padding: '5px 24px',
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledValueCell = styled(StyledTableCell)({})

const StyledValueRatingCell = styled(StyledValueCell)({
  padding: '5px 24px',
})

const StyledValueRatingExplanationCell = styled(StyledValueRatingCell)(({ theme }) => ({
  height: 32,
  display: 'flex',
  alignItems: 'center',
  padding: '0px 24px',
  color: theme.palette.text.secondary,
}))

const StyledGroupLabelExplanationCell = styled(StyledGroupValueLabelCell)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.grey['50'],
  color: theme.palette.text.secondary,
  zIndex: 1,
}))

const StyledValueExplanationSection = styled('div')(({ theme }) => ({
  position: 'relative',
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.grey['50'],
}))

interface TableRatingContentProps<D extends string> {
  ratingGroups: RatingGroup<D>[]
  columns: TableRatingColumn<D>[]
  valueCellWidth: number
}

function TableRatingContent<D extends string>({
  ratingGroups,
  columns,
  valueCellWidth,
}: TableRatingContentProps<D>) {
  const [expandedRating, setExpandedRating] = useState<null | D>(null)

  const handleRowClick = useCallback(
    (key: D) => () => {
      setExpandedRating((prev) => (key === prev ? null : key))
    },
    []
  )

  return (
    <>
      {ratingGroups.map((group, groupIndex) => (
        <StyledTableBody key={groupIndex}>
          <StyledTableRow>
            <StyledGroupLabelCell>{group.label}</StyledGroupLabelCell>
          </StyledTableRow>
          {group.ratings.map((rating) => (
            <Fragment key={rating.key}>
              <StyledTableValueRow
                className={clsx({
                  [ratingTableClasses.expanded]: expandedRating === rating.key,
                })}
                onClick={handleRowClick(rating.key)}>
                <StyledGroupValueLabelCell className={ratingTableClasses.groupValueLabelCell}>
                  <span>{rating.label}</span>
                  <MenuExpandIcon open={expandedRating === rating.key} />
                </StyledGroupValueLabelCell>
                {columns.map((column, columnIndex) => (
                  <StyledValueRatingCell
                    key={columnIndex}
                    sx={{ width: valueCellWidth, minWidth: valueCellWidth }}>
                    <Rating value={column.ratings[rating.key]} />
                  </StyledValueRatingCell>
                ))}
              </StyledTableValueRow>

              <Collapse
                in={expandedRating === rating.key}
                mountOnEnter
                unmountOnExit>
                <StyledValueExplanationSection>
                  <Stack direction={'row'}>
                    {rating.explainer && rating.explainer && (
                      <StyledGroupLabelExplanationCell>
                        {rating.explainer}
                      </StyledGroupLabelExplanationCell>
                    )}
                    <Stack>
                      {rating.options.map((option) => (
                        <StyledTableRow key={option.value}>
                          {columns.map((column, columnIndex) => (
                            <StyledValueRatingExplanationCell
                              key={columnIndex}
                              sx={{ width: valueCellWidth, minWidth: valueCellWidth }}>
                              {option.value === column.ratings[rating.key] ? (
                                <RatingLabel rating={option.value}>
                                  {formatInvestmentCriteria(option.value, rating, {
                                    withSuffix: true,
                                  })}
                                </RatingLabel>
                              ) : (
                                <Typography
                                  variant={'inherit'}
                                  noWrap>
                                  {formatInvestmentCriteria(option.value, rating)}
                                </Typography>
                              )}
                            </StyledValueRatingExplanationCell>
                          ))}
                        </StyledTableRow>
                      ))}
                    </Stack>
                  </Stack>
                </StyledValueExplanationSection>
              </Collapse>
            </Fragment>
          ))}
        </StyledTableBody>
      ))}
    </>
  )
}

export default memo(TableRatingContent)
