import { useAssetList } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Investor } from '@gain/rpc/app-model'
import { listFilter, listFilters, listSort } from '@gain/rpc/utils'
import React from 'react'

import Benchmarking from '../../../features/benchmarking'
import { INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL } from '../../../features/investor-strategy/investor-strategy-contants'
import { useTrackPageView } from '../../../features/planhat/use-track-activity'
import { useInvestorStrategyPageParams } from '../../utils'

export interface RouteBenchmarkingProps {
  investor: Investor
}

export default function RouteBenchmarking({ investor }: RouteBenchmarkingProps) {
  const { strategyId, strategyName } = useInvestorStrategyPageParams()
  useTrackPageView('investor_strategy', { id: strategyId, tab: 'benchmarking', requireTab: true })

  const swrAssets = useAssetList({
    filter: listFilters(listFilter('strategyIds', '=', strategyId)),
    sort: [listSort('revenueEur', 'desc')],
    limit: 500,
  })

  return (
    <>
      <Head>
        <title>
          {investor?.name || ''} - {strategyName || INVESTOR_STRATEGY_LIST_ITEM_NO_STRATEGY_LABEL} -
          Analysis
        </title>
      </Head>

      <Benchmarking
        initialAssets={swrAssets.data.items}
        loadingInitialAssets={swrAssets.loading}
        objectId={strategyId}
        objectType={'investorStrategy'}
      />
    </>
  )
}
