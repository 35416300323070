import { styled } from '@mui/material/styles'
import TableCell, { TableCellProps } from '@mui/material/TableCell'

export interface VirtualTableHeadCellProps {
  width: number
  left: number
  position: 'absolute' | 'sticky'
  shadow: boolean
}

export const VirtualTableHeadCell = styled(
  (props: TableCellProps) => (
    <TableCell
      component={'div'}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) => !['width', 'left', 'position', 'shadow'].includes(prop as string),
  }
)<VirtualTableHeadCellProps>(({ shadow, width, position, left, theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  width: width,
  minWidth: width,
  maxWidth: width,
  left,
  position,
  ...(position === 'sticky' && {
    zIndex: 3,
  }),
  ...(shadow && {
    borderRight: `1px solid ${theme.palette.grey['300']}`,
    boxShadow: '2px 0px 5px -2px rgba(0, 0, 0, 0.06)',
  }),
  ...(size === 'small' && {
    marginBottom: 0,
    ...theme.typography.body2,
  }),
}))
