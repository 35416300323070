import {
  CalendarIcon,
  GlobeIcon,
  IndustryIcon,
  LeafIcon,
  LinkedInOutlinedIcon,
  MapPinIcon,
  PieChartIcon,
  UsersIcon,
} from '@gain/components/icons'
import Tooltip from '@gain/components/tooltip'
import { Asset, AssetListItem } from '@gain/rpc/app-model'
import { AssetCustomerBaseType } from '@gain/rpc/shared-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { formatAssetCustomerBaseLabel } from '@gain/utils/asset'
import { formatUrlHostname, toUrl } from '@gain/utils/common'
import { formatCountryCity } from '@gain/utils/countries'
import { formatNumber } from '@gain/utils/number'
import { formatSubsector } from '@gain/utils/sector'
import List, { ListProps } from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useMemo } from 'react'

import ListItemUrl from '../../../../common/list-item-url'
import ButtonAlertEsgOutperformer from '../../../../features/esg/esg-outperformer-alert'

export interface AssetBusinessDetailsProps extends ListProps {
  asset: Asset | AssetListItem
  variant?: 'default' | 'minimal'
}

interface Details {
  subsector?: string | null
  url: URL | null
  headquartersCountry: string | null
  headquartersCity: string | null
  headquartersRegion: string | null
  customerBase: AssetCustomerBaseType[] | null
  fte: number | null
  esgOutperformer: boolean
  linkedinExternalId: string | null
  yearFounded?: number | null
}

function isAsset(asset: Asset | AssetListItem): asset is Asset {
  return asset['generalInfo'] !== undefined
}

function useAssetBusinessDetails(asset: Asset | AssetListItem): Details {
  return useMemo(() => {
    if (isAsset(asset)) {
      return {
        subsector: formatSubsector(asset.generalInfo?.subsector),
        url: toUrl(asset.generalInfo?.webUrl),
        headquartersCountry: asset.generalInfo?.headquarters || null,
        headquartersCity: asset.generalInfo?.headquartersAddress?.city || null,
        headquartersRegion: asset.generalInfo?.headquartersAddress?.region || null,
        customerBase: asset.generalInfo?.customerBase || null,
        fte:
          (asset?.profileType !== AssetProfileType.Automated &&
            (asset.financialLatests?.fte?.amount || asset?.generalInfo?.fte)) ||
          null,
        esgOutperformer: !!asset.generalInfo?.esgOutperformer,
        linkedinExternalId: asset?.generalInfo?.linkedinExternalId || null,
        yearFounded: asset?.generalInfo?.yearFounded || null,
      }
    } else {
      return {
        subsector: formatSubsector(asset.subsector),
        url: toUrl(asset.webUrl),
        headquartersCountry: asset.region,
        headquartersCity: asset.headquartersCity,
        headquartersRegion: asset.headquartersRegion,
        customerBase: asset.customerBase,
        fte: asset?.profileType !== AssetProfileType.Automated ? asset.fte : null,
        esgOutperformer: asset.esgOutperformer,
        linkedinExternalId: asset.linkedinExternalId,
      }
    }
  }, [asset])
}

export default function AssetBusinessDetails({
  asset,
  variant = 'default',
  ...props
}: AssetBusinessDetailsProps) {
  const details = useAssetBusinessDetails(asset)

  return (
    <List
      dense
      disablePadding
      {...props}>
      {details.headquartersCountry && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <MapPinIcon />
          </ListItemIcon>
          <ListItemText>
            <Tooltip
              title={'Headquarters'}
              disableInteractive>
              <span>
                {formatCountryCity(
                  details.headquartersCountry,
                  details.headquartersCity,
                  details.headquartersRegion
                )}
              </span>
            </Tooltip>
          </ListItemText>
        </ListItem>
      )}
      {details.subsector && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <IndustryIcon />
          </ListItemIcon>
          <ListItemText>
            <Tooltip
              title={'Sector'}
              disableInteractive>
              <span>{details.subsector}</span>
            </Tooltip>
          </ListItemText>
        </ListItem>
      )}
      {variant !== 'minimal' && details.yearFounded && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          <ListItemText>
            <Tooltip
              title={'Year founded'}
              disableInteractive>
              <span>{details.yearFounded}</span>
            </Tooltip>
          </ListItemText>
        </ListItem>
      )}
      {details.customerBase && details.customerBase.length > 0 && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <PieChartIcon />
          </ListItemIcon>
          <ListItemText>
            <Tooltip
              title={'Customer base'}
              disableInteractive>
              <span>{details.customerBase.map(formatAssetCustomerBaseLabel).join(', ')}</span>
            </Tooltip>
          </ListItemText>
        </ListItem>
      )}
      {details.fte && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          <ListItemText>
            <Tooltip
              title={'FTEs'}
              disableInteractive>
              <span>{formatNumber(details.fte)}</span>
            </Tooltip>
          </ListItemText>
        </ListItem>
      )}
      {variant !== 'minimal' && details.esgOutperformer && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <LeafIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Tooltip
              title={'ESG assessment'}
              disableInteractive>
              <span>ESG outperformer</span>
            </Tooltip>
            <ButtonAlertEsgOutperformer />
          </ListItemText>
        </ListItem>
      )}
      {details.url && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <GlobeIcon />
          </ListItemIcon>
          <ListItemUrl
            label={<span>{formatUrlHostname(details.url)}</span>}
            url={details.url.toString()}
          />
        </ListItem>
      )}
      {details.linkedinExternalId && (
        <ListItem
          disableGutters
          disablePadding>
          <ListItemIcon>
            <LinkedInOutlinedIcon />
          </ListItemIcon>
          <ListItemUrl
            label={'LinkedIn'}
            url={`https://linkedin.com/company/${details.linkedinExternalId}`}
          />
        </ListItem>
      )}
    </List>
  )
}
