import Typography from '@gain/components/typography'
import { isDefined } from '@gain/utils/common'
import { styled, useTheme } from '@mui/material/styles'
import { scaleLinear, scaleTime } from 'd3-scale'
import { line } from 'd3-shape'
import React, { useCallback, useState } from 'react'

import type { SparklineValue } from './sparkline-utils'

const StyledRoot = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  height: 40,
  maxHeight: 40,
})

const StyledChartContainer = styled('div')({
  flex: 1,
  height: 40,
  maxHeight: 40,
})

const StyledXAxisLabel = styled('text')(({ theme }) => ({
  fill: theme.palette.text.secondary,
  fontFamily: theme.typography.fontFamily,
  fontSize: 10,
  fontWeight: 600,
  textAnchor: 'middle',
  transform: 'translateY(16px)',
}))

const StyledLine = styled('path')({
  strokeWidth: 2,
  fill: 'none',
})

const StyledMissingDataLine = styled('path')({
  strokeWidth: 2,
  fill: 'none',
})

const StyledLabel = styled(Typography)({
  marginBottom: 20,
  maxWidth: 40,
})

export interface SparklineProps {
  values: SparklineValue[]
  label: string
}

export default function Sparkline({ values, label }: SparklineProps) {
  const [width, setWidth] = useState(0)
  const handleSetWidth = useCallback(
    (element: HTMLDivElement) => {
      if (element === null) {
        return
      }

      const box = element.getBoundingClientRect()
      if (box.width > 0) {
        setWidth(box.width)
      }
    },
    [setWidth]
  )

  const HEIGHT = 40
  const MARGIN = { top: 4, right: 12, bottom: 18, left: 12 }
  const INNER_WIDTH = width - MARGIN.left - MARGIN.right
  const INNER_HEIGHT = HEIGHT - MARGIN.top - MARGIN.bottom

  const theme = useTheme()
  const xValues = values.map((value) => value.year)
  const yValues = values
    .filter((value) => value.value !== null)
    .map((value) => value.value) as number[]

  const scaleX = scaleTime()
    .domain([xValues[0], xValues[xValues.length - 1]])
    .range([0, INNER_WIDTH])

  const color =
    yValues.length > 2 && yValues[0] > yValues[yValues.length - 1]
      ? theme.palette.error.main
      : theme.palette.success.main

  const hasOneDataPoint = values.filter((value) => isDefined(value.value)).length === 1
  const oneDataPoint = hasOneDataPoint ? values[values.length - 1].value : null

  const yDomain =
    oneDataPoint !== null
      ? [oneDataPoint - 5, oneDataPoint + 5]
      : [Math.min(...yValues), Math.max(...yValues)]

  const scaleY = scaleLinear().domain(yDomain).range([INNER_HEIGHT, 0]).nice(10)

  const ticks = xValues
    .filter((_, index) => index % 2 === 0)
    .map((value) => ({
      value,
      x: scaleX(value),
    }))

  const generator = line<SparklineValue>()
    .x((d) => scaleX(d.year))
    .y((d) => scaleY(d.value!))
    .defined((d) => isDefined(d.value))

  const filteredData = values.filter(generator.defined())
  const pathWithGaps = generator(values)
  const path = generator(filteredData)

  if (pathWithGaps === null || path === null) {
    return null
  }

  return (
    <StyledRoot>
      <StyledChartContainer ref={handleSetWidth}>
        {width > 0 && (
          <svg
            height={HEIGHT}
            width={width}>
            <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
              <g transform={`translate(0, ${INNER_HEIGHT})`}>
                {ticks.map((tick, index) => (
                  <g
                    key={index}
                    transform={`translate(${tick.x}, 0)`}>
                    <StyledXAxisLabel>
                      ‘{tick.value.getFullYear().toString(10).slice(2)}
                    </StyledXAxisLabel>
                  </g>
                ))}
              </g>
              <StyledMissingDataLine
                d={path}
                stroke={color}
                strokeDasharray={'3 3'}
              />
              <StyledLine
                d={pathWithGaps}
                stroke={color}
              />
              {hasOneDataPoint && (
                <circle
                  cx={scaleX(values[values.length - 1].year)}
                  cy={scaleY(values[values.length - 1].value!)}
                  fill={color}
                  r={2}
                />
              )}
            </g>
          </svg>
        )}
      </StyledChartContainer>
      {label && (
        <StyledLabel
          style={{ color: color }}
          variant={'caption'}
          noWrap>
          {label}
        </StyledLabel>
      )}
    </StyledRoot>
  )
}
