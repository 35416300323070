import { Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import { useFormatCurrencyRangeCallback } from '@gain/utils/currency'
import { useDialogState } from '@gain/utils/dialog'
import { ColumnVisibilityModel, useVisibleColumns } from '@gain/utils/table'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import { MouseEvent, useCallback, useMemo, useRef } from 'react'

import Table, { createTableColumns } from '../../../common/table'
import { useOpenLink } from '../../../common/use-open-link'
import { AssetRegionsTableCell } from '../../../features/asset/asset-regions-table-cell'
import { AssetSectorsTableCell } from '../../../features/asset/asset-sectors-table-cell'
import FinancialValue from '../../../features/financial/financial-value'
import { generateInvestorStrategyPagePathParams } from '../../utils'
import AlertDialogDryPowder from '../alert-dry-powder'

const investorStrategiesTableClasses = generateUtilityClasses('InvestorStrategiesTable', [
  'estimate',
])

const StyledTable = styled(Table)(({ theme }) => ({
  [`& .${investorStrategiesTableClasses.estimate}`]: {
    color: theme.palette.info.main,
  },
})) as typeof Table

function useColumns(onDryPowderInfoClick?: () => void) {
  const formatCurrencyRange = useFormatCurrencyRangeCallback()

  return useMemo(() => {
    return createTableColumns<InvestorProfileStrategy>(
      {
        field: 'name',
        headerName: 'Strategy',
        align: 'left',
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'assetCount',
        headerName: 'Companies',
        align: 'right',
        width: 112,
        valueFormatter: ({ value }) => {
          if (value === 0) {
            return '-'
          }
          return value
        },
      },
      {
        field: 'latestFundName',
        headerName: 'Latest fund',
        align: 'left',
        width: 192,
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'latestFundSizeEur',
        headerName: 'Latest fund size',
        align: 'right',
        width: 120,
        renderCell: ({ value }) => <FinancialValue amount={value} />,
      },
      {
        field: 'latestFundDryPowderMaxEur',
        headerName: 'Drypowder (est.)',
        align: 'right',
        width: 148,
        onInfoClick: onDryPowderInfoClick,
        cellClassName: ({ row }) => {
          if (row.latestFundDryPowderMinEur !== null && row.latestFundDryPowderMaxEur !== null) {
            return investorStrategiesTableClasses.estimate
          }

          return undefined
        },
        valueFormatter: ({ row }) => {
          const min = row.latestFundDryPowderMinEur
          const max = row.latestFundDryPowderMaxEur
          if (min !== null && max !== null) {
            return formatCurrencyRange(min, max, { round: 'estimate' })
          }

          return '-'
        },
      },
      {
        field: 'latestFundVintageYear',
        headerName: 'Latest fund vintage',
        align: 'right',
        width: 144,
      },
      {
        field: 'assetEbitdaEurMedian',
        headerName: 'Median EBITDA',
        align: 'right',
        width: 128,
        renderCell: ({ value }) => <FinancialValue amount={value === 0 ? null : value} />,
      },
      {
        field: 'assetRegions',
        headerName: 'Active in region',
        width: 160,
        renderCell: ({ row }) => (
          <AssetRegionsTableCell
            regionNames={row.assetRegions}
            dense
          />
        ),
      },
      {
        field: 'assetSectors',
        headerName: 'Active in sector',
        width: 164,
        renderCell: ({ row }) => (
          <AssetSectorsTableCell
            sectorNames={row.assetSectors}
            dense
          />
        ),
      }
    )
  }, [formatCurrencyRange, onDryPowderInfoClick])
}

interface InvestorStrategyTableProps {
  investor: Investor
  rows: InvestorProfileStrategy[]
  hideDryPowderInfo?: boolean
  dense?: boolean
}

export default function InvestorStrategiesTable({
  rows,
  investor,
  hideDryPowderInfo,
  dense,
}: InvestorStrategyTableProps) {
  const [isDryPowderDialogOpen, handleOpenDryPowderDialog, handleCloseDryPowderDialog] =
    useDialogState()
  const openLink = useOpenLink()
  const tableRef = useRef<HTMLDivElement>(null)

  const handleDryPowderInfoClick = useMemo(() => {
    if (!hideDryPowderInfo) {
      return handleOpenDryPowderDialog
    }

    return undefined
  }, [hideDryPowderInfo, handleOpenDryPowderDialog])

  const columns = useColumns(handleDryPowderInfoClick)

  const visibilityModel = useMemo((): ColumnVisibilityModel<InvestorProfileStrategy> => {
    return {
      latestFundVintageYear: 1280,
      latestFundName: 1220,
      assetEbitdaEurMedian: 1040,
      assetRegions: !dense && 880,
      latestFundSizeEur: 592,
      assetSectors: !dense && 740,
    }
  }, [dense])

  const visibleColumns = useVisibleColumns(tableRef, columns, visibilityModel)

  const handleRowClick = useCallback(
    (row: InvestorProfileStrategy, event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (row.id < 1) {
        return
      }

      const path = generateInvestorStrategyPagePathParams({
        investorId: investor.id,
        investorName: investor.name,
        strategyId: row.id,
        strategyName: row.name,
      })

      openLink(path, event)
    },
    [investor.id, investor.name, openLink]
  )

  const handleIsRowClickable = useCallback((row: InvestorProfileStrategy) => {
    return row.id > 0
  }, [])

  return (
    <>
      <AlertDialogDryPowder
        onClose={handleCloseDryPowderDialog}
        open={isDryPowderDialogOpen}
      />
      <StyledTable
        ref={tableRef}
        columns={visibleColumns}
        dense={dense}
        isRowClickable={handleIsRowClickable}
        onRowClick={handleRowClick}
        rows={rows}
        disablePaddingBottomOnLastRow
        disableStickyHeader
      />
    </>
  )
}
