import { styled } from '@mui/material/styles'
import TableCell, { TableCellProps } from '@mui/material/TableCell'

import { VirtualTableVariant } from './virtual-table'

export interface VirtualTableBodyCellProps {
  width: number
  left: number
  position: 'absolute' | 'sticky'
  shadow: boolean
  variant?: VirtualTableVariant
}

export const VirtualTableBodyCell = styled(
  (props: TableCellProps) => (
    <TableCell
      component={'div'}
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) =>
      !['width', 'left', 'position', 'shadow', 'variant'].includes(prop as string),
  }
)<VirtualTableBodyCellProps>(({ width, left, position, shadow, theme, size }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: width,
  minWidth: width,
  maxWidth: width,
  overflow: 'hidden',
  left,
  position,

  ...(position === 'sticky' && {
    zIndex: 2,
  }),

  ...(shadow && {
    borderRight: `1px solid ${theme.palette.grey['300']}`,
    boxShadow: '2px 0px 5px -2px rgba(0, 0, 0, 0.06)',
  }),

  ...(size === 'small' && {
    borderBottomWidth: 0,
  }),
}))
